// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu{
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(190, 191, 192, 0.32) 0px 2px 16px 0px;
    border: 1px solid #adadad !important;
}
.categoryDropdown a{
    width: 100%;
    white-space: normal; /* Allow wrapping */
    word-wrap: break-word; /* Break long words if necessary */
    overflow-wrap: break-word;
    border-bottom: 1px solid #a9a9a9;
}
/* .dropdownCategory{
    width: 100%;
    overflow: none;
} */`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/Categories/styles.css"],"names":[],"mappings":"AAAA;IACI,8FAA8F;IAC9F,oCAAoC;AACxC;AACA;IACI,WAAW;IACX,mBAAmB,EAAE,mBAAmB;IACxC,qBAAqB,EAAE,kCAAkC;IACzD,yBAAyB;IACzB,gCAAgC;AACpC;AACA;;;GAGG","sourcesContent":[".dropdown-menu{\n    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(190, 191, 192, 0.32) 0px 2px 16px 0px;\n    border: 1px solid #adadad !important;\n}\n.categoryDropdown a{\n    width: 100%;\n    white-space: normal; /* Allow wrapping */\n    word-wrap: break-word; /* Break long words if necessary */\n    overflow-wrap: break-word;\n    border-bottom: 1px solid #a9a9a9;\n}\n/* .dropdownCategory{\n    width: 100%;\n    overflow: none;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
