// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_head__mviGL{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.styles_head__mviGL button{
    width: 150px;
    height: 40px;
    margin-top: 10px;
    background-color: #EA3975;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}
.styles_leaderboard__e0YVD{
    width: 80%;
    margin: auto;
    border: 1px solid #999999;
    border-radius: 10px;
    margin-top: 20px;
    overflow: hidden;
}
.styles_header__qRhqx{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #7e7e7e;
}
.styles_detail__M8GD7{
    display: flex;
    width: 100%;
}
.styles_header__qRhqx>div{
    text-align: center;
    padding: 10px 10px;
}
.styles_detail__M8GD7>div{
    text-align: center;
    padding: 5px 10px;
}

.styles_rank__cQ6L-{
    width: 10%;
}
.styles_name__Esera{
    width: 50%;
    text-transform: capitalize;
}
.styles_score__voWPW{
    width: 20%;
}
.styles_reward__w\\+Ei-{
    width: 20%;
}
.styles_rank1__uSH8-{
    background-color: gold;
}
.styles_rank2__yGRjG{
    background-color: silver;
}
.styles_rank3__d1dVL{
    background-color: #ce8946;
}

@media (max-width:991px){
    .styles_leaderboard__e0YVD{
        width: 100%;
    }
    .styles_name__Esera{
        width: 40%;
    }
    .styles_reward__w\\+Ei-{
        width: 30%;
    }
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/QuizLeaderboard/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,WAAW;IACX,gCAAgC;AACpC;AACA;IACI,aAAa;IACb,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,0BAA0B;AAC9B;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".head{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.head button{\n    width: 150px;\n    height: 40px;\n    margin-top: 10px;\n    background-color: #EA3975;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    font-size: 16px;\n}\n.leaderboard{\n    width: 80%;\n    margin: auto;\n    border: 1px solid #999999;\n    border-radius: 10px;\n    margin-top: 20px;\n    overflow: hidden;\n}\n.header{\n    display: flex;\n    width: 100%;\n    border-bottom: 1px solid #7e7e7e;\n}\n.detail{\n    display: flex;\n    width: 100%;\n}\n.header>div{\n    text-align: center;\n    padding: 10px 10px;\n}\n.detail>div{\n    text-align: center;\n    padding: 5px 10px;\n}\n\n.rank{\n    width: 10%;\n}\n.name{\n    width: 50%;\n    text-transform: capitalize;\n}\n.score{\n    width: 20%;\n}\n.reward{\n    width: 20%;\n}\n.rank1{\n    background-color: gold;\n}\n.rank2{\n    background-color: silver;\n}\n.rank3{\n    background-color: #ce8946;\n}\n\n@media (max-width:991px){\n    .leaderboard{\n        width: 100%;\n    }\n    .name{\n        width: 40%;\n    }\n    .reward{\n        width: 30%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `styles_head__mviGL`,
	"leaderboard": `styles_leaderboard__e0YVD`,
	"header": `styles_header__qRhqx`,
	"detail": `styles_detail__M8GD7`,
	"rank": `styles_rank__cQ6L-`,
	"name": `styles_name__Esera`,
	"score": `styles_score__voWPW`,
	"reward": `styles_reward__w+Ei-`,
	"rank1": `styles_rank1__uSH8-`,
	"rank2": `styles_rank2__yGRjG`,
	"rank3": `styles_rank3__d1dVL`
};
export default ___CSS_LOADER_EXPORT___;
