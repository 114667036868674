import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from "./styles.module.css"
import Header from '../../CommonComponents/Header/Header';
import Footer from '../../CommonComponents/Footer/Footer';
import FilterPanel from '../../CommonComponents/FilterPanel/FilterPanel';
import QuestionTable from '../../CommonComponents/QuestionTable/QuestionTable';
import Categories from '../../CommonComponents/Categories/Categories';
import QuizCardAd from '../../CommonComponents/QuizCardAd/QuizCardAd';
import TestSeriesCardAd from '../../CommonComponents/TestSeriesCardAd/TestSeriesCardAd';
import MockComingSoon from '../../CommonComponents/MockComingSoon/MockComingSoon';
import { isMobile } from '../../../_constants/constant';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

const Practice = (props) => {
  const [topicSelected, setTopicSelected] = useState();
  const [subTopicSelected, setSubTopicSelected] = useState();
  const user = JSON.parse(useSelector((state) => state.auth.user));
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "ExamBolt - Practice",
    "description": "Boost your government exam preparation with ExamBolt's practice questions. Access topic-wise and full-length questions for SSC, UPSC, banking, railway, and state exams to enhance your performance.```",
    "keywords": ["ExamBolt practice questions","government exam questions","competitive exam preparation","online practice tests","mock questions","SSC practice questions","UPSC practice questions","banking exam questions","state exam preparation","topic-wise questions."]
};
  return (
    <>
    <Helmet>
        <title>ExamBolt - Practice</title>
        <meta name="description" content="Boost your government exam preparation with ExamBolt's practice questions. Access topic-wise and full-length questions for SSC, UPSC, banking, railway, and state exams to enhance your performance.```" />
        <meta name="keywords" content="ExamBolt practice questions, government exam questions, competitive exam preparation, online practice tests, mock questions, SSC practice questions, UPSC practice questions, banking exam questions, state exam preparation, topic-wise questions." />
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      </Helmet>
    <div>
      <Header />
      <div className={`${styles['practice']}`}>
        <Container fluid className="my-3">
          <Row>
            <Col lg={8} xs={12} className="mb-3 border pt-3 pb-3">
              <FilterPanel setTopicSelected={setTopicSelected} topicSelected={topicSelected} setSubTopicSelected={setSubTopicSelected} subTopicSelected={subTopicSelected}/>
              <QuestionTable topicSelected={topicSelected} subTopicSelected={subTopicSelected} />
            </Col>
            <Col lg={4} xs={12}>
              {!isMobile && user?.plan?.is_active &&
                <Categories topicSelected={topicSelected} setSubTopicSelected={setSubTopicSelected} subTopicSelected={subTopicSelected} />
              }
              <div className={`${styles['ads']}`}>
                <QuizCardAd />
                <TestSeriesCardAd />
                <MockComingSoon />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
    </>
  );
}

export default Practice;