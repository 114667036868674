import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import './TestSeriesCardAd.css';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TestSeriesCardAd = () => {
const navigate = useNavigate();
const handleButtonClick =()=>{
    navigate('/pricing');
}

  return (
    <div className="mt-3 ">
      <div
        className="text-white bg-primary pb-3 pt-3 border light-box-shadow cursor-pointer"
        style={{
          width: "280px",
          height:"100%",
          borderRadius: "15px",
          background: "linear-gradient(135deg,#f8f9fa, #fad1d1)",
          paddingLeft:"20px",
          paddingRight:"20px"
        }}
      >
        <div className="text-center d-flex align-items-center justify-content-between">
          <div
            style={{
              width: "60px",
              height: "60px",
              background: "#fa7268",
              borderRadius: "10px",
              marginRight:"10px",
              color:'#fa7268'
            }}
          >
            <span
              style={{
                fontSize: "40px",
                fontWeight: "bold",
                color: "#ffffff",
              }}
            >
              <i class="bi bi-infinity"></i>
            </span>
          </div>
          {/* Header */}
        <h3 className="fw-bold fs-18" style={{textAlign:"left",color:'#fa7268'}}>Access Unlimited Practice</h3>
        </div>

        {/* Icon (Placeholder for Cube Illustration) */}
        

        {/* Date and Time */}
        <div className="mt-4 mb-4" style={{ color: "#1d3557" }}>
            <p className="fw-bold">Get our Gold Bolt Pass and access the unlimited test series and all monthly quizzes for free</p>
          {/* <p className="text-center">Bronze Bolt Pass</p> */}
        </div>

        {/* Button */}
        <div className="text-center mt-2">
          <button className="btn btn-light text-primary fw-bold border" onClick={()=>{handleButtonClick()}}>
            Get Gold Bolt Pass
          </button>
        </div>

        {/* Footer */}
        {/* <p className="text-center mt-1" style={{ fontSize: "12px" }}>
          Play to win upto 400 Bolt Coins. Click above to register.
        </p> */}
      </div>
    </div>
  );
};

export default TestSeriesCardAd;
