import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Header from "../../CommonComponents/Header/Header";
import styles from "./styles.module.css"
import { elogo } from "../../../_constants/images";
import LoginForm from "../../CommonComponents/Forms/Login";
import Signup from "../../CommonComponents/Forms/Signup";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Login = (props) => {
  const location = useLocation();

  const [isLogin, setIsLogin] = useState(true);
  // Retrieve the query parameter
  const query = new URLSearchParams(location.search);
  const refCode = query?.get("refCode");
  useEffect(() => {
    if (refCode) {
      setIsLogin(false);
    }
  }, [])
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "ExamBolt - Login",
    "description": "Log in to your ExamBolt account to access personalized practice tests, test series, monthly quiz test, mock exams, and progress tracking for government exam preparation. Start your journey to success today!",
    "keywords": ["ExamBolt login","ExamBolt account access","government exam preparation login","exam preparation portal","online exam platform","mock test login","practice test account","exam preparation website login."]
};
  return (
    <>
      <Helmet>
        <title>ExamBolt - Login</title>
        <meta name="description" content="Log in to your ExamBolt account to access personalized practice tests, test series, monthly quiz test, mock exams, and progress tracking for government exam preparation. Start your journey to success today!" />
        <meta name="keywords" content="ExamBolt login, ExamBolt account access, government exam preparation login, exam preparation portal, online exam platform, mock test login, practice test account, exam preparation website login." />
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      </Helmet>
      <div className={`${styles['login']}`}>
        <Header />
        <Container fluid className={`d-flex justify-content-center align-items-center pt-5 ${styles['container']}`}>
          <Row className="w-100 justify-content-center">
            <Col className="col-md-6 col-lg-4 shadow p-4 rounded border light-box-shadow">
              <div className="text-center mb-4">
                {/* Logo */}
                <img src={elogo} alt="ExamBolt Logo" width="100" />
                <h5><i>EXAMBOLT</i></h5>
              </div>

              {/* Form */}
              {isLogin == true ? <LoginForm setIsLogin={setIsLogin} isLogin={isLogin} /> : <Signup setIsLogin={setIsLogin} isLogin={isLogin} refCode={refCode} />}
              {/* Divider and Social Login */}
              {/* <hr />
            <p className="text-center text-muted">or you can sign in with</p>
            <div className={`d-flex justify-content-center`}>
              <a href="#" className={`text-muted me-3 social-media-back`}>
                <img src="https://img.icons8.com/ios-glyphs/30/000000/google-logo.png" alt="Google" />
              </a>
              <a href="#" className={`text-muted me-3 social-media-back`}>
                <img src="https://img.icons8.com/ios-glyphs/30/000000/github.png" alt="GitHub" />
              </a>
              <a href="#" className={`text-muted me-3 social-media-back`}>
                <img src="https://img.icons8.com/ios-glyphs/30/000000/facebook.png" alt="Facebook" />
              </a>
              <a href="#" className={`text-muted social-media-back`}>
                <img src="https://img.icons8.com/ios-glyphs/30/000000/more.png" alt="More" />
              </a>
            </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default Login;