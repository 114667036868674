import React, { useEffect, useState } from 'react';

const FullScreenSecurity = ({setFullScrrenPermission}) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    // Enter full screen
    const enterFullScreen = () => {
        const elem = document.documentElement; // Full screen for the entire document

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, Opera
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
            elem.msRequestFullscreen();
        }
        setFullScrrenPermission(false);
        setIsFullScreen(true);
    };

    const preventExitFullScreen = () => {
        if (!document.fullscreenElement) {
            alert("You have exited full screen mode. Please re-enter full screen for the best experience.");
        }
    };
    
    useEffect(() => {
        document.addEventListener('fullscreenchange', preventExitFullScreen);
    
        return () => {
            document.removeEventListener('fullscreenchange', preventExitFullScreen);
        };
    }, []);
    

    // Block specific keyboard shortcuts (like Esc to exit full screen)
    useEffect(() => {
        const blockFullScreenExitKeys = (e) => {
            if (e.key === 'Escape' || (e.ctrlKey && e.key === 'F') || e.key === 'F11') {
                e.preventDefault();
                enterFullScreen(); // Re-enter full screen if they try to exit
            }
        };

        window.addEventListener('keydown', blockFullScreenExitKeys);

        return () => {
            window.removeEventListener('keydown', blockFullScreenExitKeys);
        };
    }, []);

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            {!isFullScreen && (
                <button onClick={enterFullScreen}>
                    Enter Full Screen
                </button>
            )}
            {isFullScreen && <p>You are in full-screen mode. Press Esc to exit, but it will be blocked.</p>}
        </div>
    );
};

export default FullScreenSecurity;
