// src/utils/analytics.js
import ReactGA from "react-ga4";

export const initGoogleAnalytics = () => {
    ReactGA.initialize("G-QP8PCBH4S9"); // Replace with your Measurement ID
};

export const trackPageView = (path) => {
    ReactGA.send({ hitType: "pageview", page: path });
};
