// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_practice__8Ql1r {
    width: 100%;
    height: 100%;
}

.styles_practice__8Ql1r>div {
    width: 80%;
    height: 100%;
}

@media(max-width:991px) {
    .styles_practice__8Ql1r>div {
        width: 95%;
    }
}
.styles_ads__dDl1I{
    display: flex;
    flex-direction: column;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/Practice/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI;QACI,UAAU;IACd;AACJ;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".practice {\n    width: 100%;\n    height: 100%;\n}\n\n.practice>div {\n    width: 80%;\n    height: 100%;\n}\n\n@media(max-width:991px) {\n    .practice>div {\n        width: 95%;\n    }\n}\n.ads{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"practice": `styles_practice__8Ql1r`,
	"ads": `styles_ads__dDl1I`
};
export default ___CSS_LOADER_EXPORT___;
