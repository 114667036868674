// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  width: 100%;
  height: 100%;
}
.App {
  width: 100%;
  height: 100%;
}
:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --text-muted-color: #212529bf;
  --dark-background-color:#424649;
  --dark-color:#ffffff;
  --light-box-shadow:rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  --border-top: 1px solid #777777;
  --highlighted-background-color:#222222;
}

[data-theme="dark"] {
  --background-color: #222222;
  --text-color: #ffffff;
  --text-muted-color: #ffffff;
  --dark-background-color:#ffffff;
  --dark-color:#000000;
  --light-box-shadow:rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(190, 191, 192, 0.32) 0px 2px 16px 0px;
  --border-top:1px solid #d4d4d4;
  --highlighted-background-color:#ffffff;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,6BAA6B;EAC7B,+BAA+B;EAC/B,oBAAoB;EACpB,kGAAkG;EAClG,+BAA+B;EAC/B,sCAAsC;AACxC;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,2BAA2B;EAC3B,+BAA+B;EAC/B,oBAAoB;EACpB,qGAAqG;EACrG,8BAA8B;EAC9B,sCAAsC;AACxC;AACA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["#root {\n  width: 100%;\n  height: 100%;\n}\n.App {\n  width: 100%;\n  height: 100%;\n}\n:root {\n  --background-color: #ffffff;\n  --text-color: #000000;\n  --text-muted-color: #212529bf;\n  --dark-background-color:#424649;\n  --dark-color:#ffffff;\n  --light-box-shadow:rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\n  --border-top: 1px solid #777777;\n  --highlighted-background-color:#222222;\n}\n\n[data-theme=\"dark\"] {\n  --background-color: #222222;\n  --text-color: #ffffff;\n  --text-muted-color: #ffffff;\n  --dark-background-color:#ffffff;\n  --dark-color:#000000;\n  --light-box-shadow:rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(190, 191, 192, 0.32) 0px 2px 16px 0px;\n  --border-top:1px solid #d4d4d4;\n  --highlighted-background-color:#ffffff;\n}\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
