// LandingSection.js
import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./styles.module.css"
import { hero, heroDark, rightArrow } from "../../../../_constants/images";
import { ThemeContext } from "../../../../_common/ThemeContext";
import { isMobile, TimeFormatter } from "../../../../_constants/constant";
import QuizCardAd from "../../../CommonComponents/QuizCardAd/QuizCardAd";
import TestSeriesCardAd from "../../../CommonComponents/TestSeriesCardAd/TestSeriesCardAd";
import MockComingSoon from "../../../CommonComponents/MockComingSoon/MockComingSoon";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../axios/axiosInstance";

const Hero = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  const [quiz, setQuiz] = useState();
  const [loading, setLoading] = useState(true);

  // Fetch data from API
  useEffect(async () => {
    await axiosInstance.get(`/quizzes`) // Replace with your API URL
      .then((data) => {
        setQuiz(data?.data?.data?.data[0]);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching tasks:", error));
  }, []);
  return (
    <Container fluid className={`py-5 ${!isMobile && styles['hero']}`}>
      <Row className="align-items-center">
        {/* Text Section */}
        <Col xs={12} md={6} className="text-center text-md-start px-4">
          <h2 className="display-5">
            One Platform, <br />
            <span className="text-primary fw-bold">Endless Practice for Every Exam</span>
          </h2>
          <p className="lead my-3">
            Practice &nbsp; <img className={`${styles['icon']}`} src={rightArrow} alt="" /> &nbsp; Improve &nbsp;  <img className={`${styles['icon']}`} src={rightArrow} alt="" /> &nbsp; Practice &nbsp;  <img className={`${styles['icon']}`} src={rightArrow} alt="" /> &nbsp; Succeed
          </p>
          <p>Prepare for SSC CGL, UPSC, IBPS PO, RRB NTPC, SBI Clerk and all Gov't exams with unlimited practice questions, test series, and quizzes. Unlock your potential and ace government exams!</p>
          <div className="my-3">
            <Button variant="success" className="me-2 mb-2" onClick={() => window.location.href = !isLogin ? '/accounts/login' : '/practice'}>{isLogin ? 'Practice Now' : 'Start Practicing'}</Button>
            {/* <Button variant="outline-dark" className="me-2 mb-2">
                <img src="https://img.icons8.com/color/48/000000/google-play.png" alt="Google Play" width="24" className="me-1" />
                Google Play
              </Button>
              <Button variant="outline-dark" className="mb-2">
                <img src="https://img.icons8.com/ios-filled/50/000000/mac-os--v1.png" alt="App Store" width="24" className="me-1" />
                App Store
              </Button> */}
          </div>
        </Col>

        {/* Image Section */}
        <Col xs={12} md={6} className="d-flex justify-content-center mt-4 mt-md-0">
          <img
            src={theme == "light" ? hero : heroDark}
            alt="Exam Preparation"
            className={`img-fluid ${styles['hero-img']}`}
            style={{ maxHeight: "400px" }}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-around flex-wrap">

        <Container fluid className={styles.container}>
          <Row className="d-flex justify-content-between align-items-center">
            {quiz && <Col xs={12} md={6} lg={4} className={`mb-4 pb-4 mb-md-0 ${styles.card} border light-box-shadow ${styles['card-dark-background']}`}>
              <div className="d-flex flex-column align-items-center text-white">
                <span
                  style={{
                    fontSize: "60px",
                    fontWeight: "bold",
                  }}
                >
                  ?
                </span>
                <h3 className="text-center mb-4">{quiz?.title}</h3>
                <p className={`text-center fw-bold mb-2 ${styles.dateTime}`}>{new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(quiz?.date))},&nbsp;{TimeFormatter(quiz?.time)}</p>
                <p className={`text-center fw-bold ${styles.entryPrice}`}>Entry Price: {quiz?.price}</p>
                <button className="btn btn-light text-primary fw-bold border" onClick={() => { window.location.href = "/quiz-listing" }}>
                  Get Entry
                </button>
              </div>
            </Col>}
            <Col xs={12} md={6} lg={4} className={`mb-4 pb-4 mb-md-0 ${styles.card} border light-box-shadow ${styles['card-light-background']}`}>
              <div className="d-flex flex-column align-items-center">
                <span
                  style={{
                    fontSize: "60px",
                    fontWeight: "bold",
                  }}
                >
                  <i class="bi bi-infinity"></i>
                </span>
                <h3 className="text-center mb-4">Access Unlimited Practice</h3>
                <p className={`${styles.description} text-center`}>Get the Gold Bolt Pass for free access to unlimited tests and monthly quizzes.</p>
                <button className="btn btn-light text-primary fw-bold border" onClick={() => { window.location.href = "/pricing" }}>
                  Get Gold Bolt Pass
                </button>
              </div>
            </Col>
            <Col xs={12} md={6} lg={4} className={`text-white ${styles.card} pb-4 border light-box-shadow ${styles['card-dark-background']}`}>
              <div className="d-flex flex-column align-items-center">
                <span
                  style={{
                    fontSize: "60px",
                    fontWeight: "bold",
                  }}
                >
                  <i class="bi bi-book"></i>
                </span>
                <h3 className="text-center mb-4">Exam Mock Tests</h3>
                <p className={`${styles.description} text-center`}>Experience government exam-style mock tests at home.</p>
                <button className="btn btn-light text-primary fw-bold border">
                  Coming Soon
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default Hero;
