import React, { useState, useEffect, useContext } from 'react';
import styles from './TestSeriesPage.module.css';
import { ThemeContext } from '../../../_common/ThemeContext';
import QuestionComponent from '../../CommonComponents/QuestionComponent/QuestionComponent';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../_actions/loaderActions';
import { jsPDF } from 'jspdf'; // Import jsPDF
import { elogo } from '../../../_constants/images';
import TestSecurity from '../../CommonComponents/TestSecurity/TestSecurity';
import { useLocation, useParams } from 'react-router-dom';
import { isMobile } from '../../../_constants/constant';
import axiosInstance from '../../../axios/axiosInstance';
import "../../../_assets/font/Hind-Regular-normal";
import FullScreenSecurity from '../../CommonComponents/TestSecurity/FullScreenSecurity';
import FullScreenRequest from '../../CommonComponents/TestSecurity/FullScreenRequest';

const TestSeriesPage = () => {
    const [questions, setQuestions] = useState([]);
    const { language, toggleLanguage } = useContext(ThemeContext);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [timeRemaining, setTimeRemaining] = useState(1800); // 5 minutes in seconds
    const [paginationData, setPaginationData] = useState();
    const [page, setPage] = useState(1);
    const [showResultsPopup, setShowResultsPopup] = useState(false); // New state for results popup
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [inCorrectAnswers, setInCorrectAnswers] = useState(0);
    const dispatch = useDispatch();

    const token = localStorage.getItem("token");
    const { id, topic } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const fetchTopics = async () => {
            dispatch(showLoader());
            try {
                const data = await axiosInstance.get(`/test-series?topic_id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const questionsWithAnswered = data?.data?.data?.questions?.map((question) => ({
                    id: question.id,
                    question: question.question,
                    options: [
                        { label: question.option_a, value: 'A' },
                        { label: question.option_b, value: 'B' },
                        { label: question.option_c, value: 'C' },
                        { label: question.option_d, value: 'D' },
                    ],
                    question_hindi: question?.question_hindi,
                    options_hindi: [
                        { label: question.option_a_hindi, value: 'A' },
                        { label: question.option_b_hindi, value: 'B' },
                        { label: question.option_c_hindi, value: 'C' },
                        { label: question.option_d_hindi, value: 'D' },
                    ],
                    correctAnswer: question.correct_answer,
                    answered: false,
                }));

                setQuestions(questionsWithAnswered);
                setAnswers(Array(questionsWithAnswered.length).fill(null));
                setPaginationData({
                    currentPage: data?.data?.current_page,
                    totalPages: data?.data?.last_page,
                    resultsPerPage: data?.data?.per_page,
                    totalResults: data?.data?.total,
                    pagesLink: data?.data?.links,
                });

                dispatch(hideLoader());
            } catch (error) {
                console.error('Failed to fetch topics:', error);
                dispatch(hideLoader());
            }
        };

        fetchTopics();
    }, [page]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const handleOptionSelect = (index) => {
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestion] = index;
        setAnswers(updatedAnswers);

        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestion].answered = true;
        setQuestions(updatedQuestions);
        if (updatedQuestions[currentQuestion].options[index].value == updatedQuestions[currentQuestion].correctAnswer) {
            setCorrectAnswers(correctAnswers + 1);
        } else {
            setInCorrectAnswers(inCorrectAnswers + 1);
        }
    };

    const handleNext = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleQuestionSelect = (index) => {
        setCurrentQuestion(index);
    };

    const handleFinishTest = () => {
        setShowResultsPopup(true);
    };

    const answeredCount = answers.filter((answer) => answer !== null).length;
    const unansweredCount = questions.length - answeredCount;

    const handleDownloadResults = (lang = 'en') => {
        const doc = new jsPDF(); // Create a new jsPDF document
        if (lang !== "en") {
            // Add the font from the imported file
            // doc.addFileToVFS("NotoSansDevanagari-Regular.ttf", hindiFont); // Make sure the font file name matches
            // doc.addFont("NotoSansDevanagari-Regular.ttf", "NotoSansDevanagari-Regular", "normal");
            doc.setFont('Hind-Regular', 'normal');
        }else{
            doc.setFont("helvetica", "bold");
        }

        const lineHeight = 10; // Line height for each text line
        const pageHeight = doc.internal.pageSize.height; // Get the page height
        let yPosition = 20; // Start below the title

        // Set some initial document formatting
        doc.setFontSize(18);
        doc.text(lang === "en" ? "Test Results" : "परीक्षा परिणाम", 10, yPosition);

        doc.setFontSize(12);
        yPosition += 10;
        // Add Summary
        doc.text(
            lang === "en"
                ? `Correct Answers: ${correctAnswers}`
                : `सही उत्तर: ${correctAnswers}`,
            10,
            yPosition
        );
        doc.text(
            lang === "en"
                ? `Wrong Answers: ${inCorrectAnswers}`
                : `गलत उत्तर: ${inCorrectAnswers}`,
            70,
            yPosition
        );
        doc.text(
            lang === "en"
                ? `Not Attempted: ${unansweredCount}`
                : `उत्तरित नहीं: ${unansweredCount}`,
            130,
            yPosition
        );
        if (lang !== "en") {
            // Add the font from the imported file
            // doc.addFileToVFS("NotoSansDevanagari-Regular.ttf", hindiFont); // Make sure the font file name matches
            // doc.addFont("NotoSansDevanagari-Regular.ttf", "NotoSansDevanagari-Regular", "normal");
            doc.setFont('Hind-Regular', 'normal');
        }else{
            doc.setFont("helvetica", "normal");
        }
        yPosition += lineHeight;
        // Prepare the content of the results
        questions.forEach((question, index) => {
            const yourAnswer = lang == 'en' ? question.options[answers[index]]?.label || 'Not answered' : question.options_hindi[answers[index]]?.label || "उत्तर नहीं दिया";
            const correctAnswer = lang == 'en' ? question.options.filter(option => option.value === question.correctAnswer)[0]?.label : question.options_hindi.filter(option => option.value === question.correctAnswer)[0]?.label;

            const questionText = `Q${index + 1}: ${lang === 'en' ? question.question : question.question_hindi}`;
            const yourAnswerText =
                lang === "en"
                    ? `Your Answer: ${yourAnswer}`
                    : `आपका उत्तर: ${yourAnswer}`;
            const correctAnswerText =
                lang === "en"
                    ? `Correct Answer: ${correctAnswer}`
                    : `सही उत्तर: ${correctAnswer}`;
            // Add each question, answer, and correct answer to the PDF
            doc.text(questionText, 10, yPosition);
            yPosition += lineHeight;
            doc.text(yourAnswerText, 10, yPosition);
            yPosition += lineHeight;
            doc.text(correctAnswerText, 10, yPosition);
            yPosition += lineHeight + 5; // Add extra space between questions

            // Check if we are reaching the bottom of the page
            if (yPosition + lineHeight > pageHeight - 10) {
                doc.addPage(); // Add new page if content is overflowing
                yPosition = 20; // Reset the y position for the new page
            }
        });

        // Save the generated PDF
        doc.save(`${topic}TestResult.pdf`);
    };
    return (<>
        {!isMobile ? <div className={styles.container}>
            {!showResultsPopup &&<TestSecurity finishTest={handleFinishTest} />}
            <header className={styles.header}>
                <h1 className={styles.headerTitle}><img src={elogo} width="60px" alt="" />Test - {topic}</h1>
                <div className={styles.headerStats}>
                    <div className={styles.timeBar}>
                        <p>Time: {formatTime(timeRemaining)} / 30:00</p>
                        <div className={styles.progressBar}>
                            <span style={{ width: `${(1 - timeRemaining / 1800) * 100}%` }}></span>
                        </div>
                    </div>
                    <p>Total Questions: {questions.length}</p>
                    <p>Answered: {answeredCount}</p>
                    <p>Unanswered: {unansweredCount}</p>
                    <button className={styles.finishButton} onClick={() => handleFinishTest()} disabled={timeRemaining === 0}>Finish Test</button>
                </div>
            </header>
            {questions?.length > 0 && (
                <main className={styles.mainContent}>
                    <section className={styles.questionSection}>
                        <select name="language" id="language" value={language} onChange={toggleLanguage}>
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                        </select>
                        <h2 className={styles.questionTitle}>Question {currentQuestion + 1}</h2>
                        <p className={styles.questionText}>{language == 'en' ? questions[currentQuestion].question : questions[currentQuestion].question_hindi}</p>
                        <div className={styles.options}>
                            {language == 'en' ? questions[currentQuestion].options.map((option, index) => (
                                <label key={index} className={styles.option}>
                                    <input
                                        type="radio"
                                        name={`question-${currentQuestion}`}
                                        checked={answers[currentQuestion] === index}
                                        onChange={() => handleOptionSelect(index)}
                                    />
                                    {option.label}
                                </label>
                            )) : questions[currentQuestion].options_hindi.map((option, index) => (
                                <label key={index} className={styles.option}>
                                    <input
                                        type="radio"
                                        name={`question-${currentQuestion}`}
                                        checked={answers[currentQuestion] === index}
                                        onChange={() => handleOptionSelect(index)}
                                    />
                                    {option.label}
                                </label>
                            ))}
                        </div>
                        <div className={styles.navigationButtons}>
                            <button
                                className={styles.navButton}
                                onClick={handlePrevious}
                                disabled={currentQuestion === 0}
                            >
                                Previous
                            </button>
                            <button
                                className={styles.navButton}
                                onClick={handleNext}
                                disabled={currentQuestion === questions.length - 1}
                            >
                                Next
                            </button>
                        </div>
                    </section>

                    <aside className={styles.navigator}>
                        <h3 className={styles.navigatorHeader}>
                            <span>☰</span> Questions Navigator
                        </h3>
                        <div className='d-flex justify-content-evenly'>
                            <div className='d-flex align-items-center'><div className={styles.reportUnanswered}></div><p>Unanswered</p></div>
                            <div className='d-flex align-items-center'><div className={styles.reportAnswered}></div><p>Answered</p></div>
                        </div>
                        <div className={styles.questionNumbers}>
                            {questions.map((_, index) => (
                                <button
                                    key={index}
                                    className={`${styles.questionButton} ${answers[index] !== null ? styles.answered : styles.unanswered
                                        } ${index === currentQuestion ? styles.active : ''}`}
                                    onClick={() => handleQuestionSelect(index)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </aside>
                </main>)}
            {showResultsPopup && (
                <div className={styles.resultsPopup}>
                    <div className={styles.popupContent}>
                        <div>
                            <div className='d-flex justify-content-between'>
                                <h2>Test Results</h2>
                                <div>
                                    <button onClick={() => handleDownloadResults('en')}>Download Results</button>
                                    <button onClick={() => handleDownloadResults('hi')}>Download Result in Hindi</button>
                                    <button onClick={() => window.location.href = "/test-series"}>Close</button>
                                </div>
                            </div>
                            <div className='d-flex gap-4 mb-2 fs-16 fw-700'>
                                <p>Correct Answers :- {correctAnswers}</p>
                                <p>Wrong Answers :- {inCorrectAnswers}</p>
                                <p>Not Attempted :- {unansweredCount}</p>
                            </div>
                        </div>
                        <ul>
                            {questions.map((question, index) => (
                                <li key={index}>
                                    <p>{language === 'en' ? question.question : question.question_hindi}</p>
                                    <p>Your Answer: {language == 'en' ? question.options[answers[index]]?.label : question.options_hindi[answers[index]]?.label || 'Not answered'}</p>
                                    <p>Correct Answer: {language == 'en' ? question?.options?.filter(option => option?.value === question?.correctAnswer)[0]?.label : question?.options_hindi?.filter(option => option?.value === question?.correctAnswer)[0]?.label}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
            :
            <div className={styles.container}>
                {!showResultsPopup &&<TestSecurity finishTest={handleFinishTest}/>}
                <div className={styles.header}>
                    <h1 className={styles.headerTitle}><img src={elogo} width="40px" alt="" />Test - {topic}</h1>
                    <div className={styles.hamburgerContainer}>
                        <div
                            className={`${styles.hamburger} ${isOpen ? styles.open : ''}`}
                            onClick={toggleMenu}
                        >
                            <div className={styles.line}></div>
                            <div className={styles.line}></div>
                            <div className={styles.line}></div>
                        </div>

                        <div className={`${styles.menu} ${isOpen ? styles.showMenu : ''}`}>
                            <aside className={styles.navigator}>
                                <h3 className={styles.navigatorHeader}>
                                    <span>☰</span> Questions Navigator
                                </h3>
                                <div className='d-flex justify-content-evenly'>
                                    <div className='d-flex align-items-center'><div className={styles.reportUnanswered}></div><p>Unanswered</p></div>
                                    <div className='d-flex align-items-center'><div className={styles.reportAnswered}></div><p>Answered</p></div>
                                </div>
                                <div className={styles.questionNumbers}>
                                    {questions.map((_, index) => (
                                        <button
                                            key={index}
                                            className={`${styles.questionButton} ${answers[index] !== null ? styles.answered : styles.unanswered
                                                } ${index === currentQuestion ? styles.active : ''}`}
                                            onClick={() => handleQuestionSelect(index)}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
                <div className={styles.headerStats}>
                    <div className={styles.timeBar}>
                        <p>Time: {formatTime(timeRemaining)} / 30:00</p>
                        <div className={styles.progressBar}>
                            <span style={{ width: `${(1 - timeRemaining / 1800) * 100}%` }}></span>
                        </div>
                    </div>
                    <div className={`d-flex text-center justify-content-between ${styles.mobilestat}`}>
                        <p>Total Questions<br />{questions.length}</p>
                        <p>Answered<br />{answeredCount}</p>
                        <p>Unanswered<br />{unansweredCount}</p>
                    </div>
                </div>
                {questions?.length > 0 && (
                    <main className={styles.mainContent}>
                        <section className={styles.questionSection}>
                            <select name="language" id="language" value={language} onChange={toggleLanguage}>
                                <option value="en">English</option>
                                <option value="hi">Hindi</option>
                            </select>
                            <h2 className={styles.questionTitle}>Question {currentQuestion + 1}</h2>
                            <p className={styles.questionText}>{questions[currentQuestion].question}</p>
                            <div className={styles.options}>
                                {language == 'en' ? questions[currentQuestion].options.map((option, index) => (
                                    <label key={index} className={styles.option}>
                                        <input
                                            type="radio"
                                            name={`question-${currentQuestion}`}
                                            checked={answers[currentQuestion] === index}
                                            onChange={() => handleOptionSelect(index)}
                                        />
                                        {option.label}
                                    </label>
                                )) : questions[currentQuestion].options_hindi.map((option, index) => (
                                    <label key={index} className={styles.option}>
                                        <input
                                            type="radio"
                                            name={`question-${currentQuestion}`}
                                            checked={answers[currentQuestion] === index}
                                            onChange={() => handleOptionSelect(index)}
                                        />
                                        {option.label}
                                    </label>
                                ))}
                            </div>
                            <div className={styles.navigationButtons}>
                                <button
                                    className={styles.navButton}
                                    onClick={handlePrevious}
                                    disabled={currentQuestion === 0}
                                >
                                    Previous
                                </button>
                                <button
                                    className={styles.navButton}
                                    onClick={handleNext}
                                    disabled={currentQuestion === questions.length - 1}
                                >
                                    Next
                                </button>
                            </div>
                        </section>
                    </main>
                )}
                <button className={styles.finishButton} onClick={() => handleFinishTest()} disabled={timeRemaining === 0}>Finish Test</button>
                {showResultsPopup && (
                    <div className={styles.resultsPopup}>
                        <div className={styles.popupContent}>
                            <div>
                                <div className='d-flex flex-column align-items-center'>
                                    <h2>Test Results</h2>
                                    <div>
                                        <button onClick={handleDownloadResults}>Download Results</button>
                                        <button onClick={() => window.location.href = "/test-series"}>Close</button>
                                    </div>
                                </div>
                                <div className='d-flex gap-4 mb-2 text-center fs-16 fw-700'>
                                    <p>Correct Answers <br /> {correctAnswers}</p>
                                    <p>Wrong Answers <br /> {inCorrectAnswers}</p>
                                    <p>Not Attempted <br /> {unansweredCount}</p>
                                </div>
                            </div>
                            <ul>
                                {questions.map((question, index) => (
                                    <li key={index}>
                                        <p>{language === 'en' ? question.question : question.question_hindi}</p>
                                        <p>Your Answer: {question.options[answers[index]]?.label || 'Not answered'}</p>
                                        <p>Correct Answer: {question?.options?.filter(option => option?.value === question?.correctAnswer)[0]?.label}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        }
    </>
    );
};

export default TestSeriesPage;
