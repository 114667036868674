// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_steps-card__lmIxM{
    padding: 30px 20px;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/ReferralSteps/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".steps-card{\n    padding: 30px 20px;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"steps-card": `styles_steps-card__lmIxM`
};
export default ___CSS_LOADER_EXPORT___;
