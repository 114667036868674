import { legacy_createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage as default
import loaderReducer from "../_reducers/loaderReducer";
import loginReducer from "../_reducers/loginReducer";
import alertReducer from "../_reducers/alertReducer";

const persistConfig = {
  key: "root", // Key to persist the entire Redux state
  storage,     // Storage method (localStorage)
  whitelist: ["auth"], // Only persist the `auth` reducer
};

const rootReducer = combineReducers({
  loader: loaderReducer,
  auth: loginReducer,
  alert: alertReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = legacy_createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store);

export { store, persistor };
