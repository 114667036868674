import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { bronzeRedeem, elogo, goldRedeem, silverRedeem } from "../../../_constants/images";
import Header from "../../CommonComponents/Header/Header";
import Footer from "../../CommonComponents/Footer/Footer";
import { redeemBoltCoin } from "../../../_constants/constant";
import Redeem from './Redeem';
import EarnBoltCoin from "./EarnBoltCoin";
import CoinHistory from "./CoinHistory";

const BoltCoinStore = () => {
  const [clicked, setClicked] = useState(0);
  const query = new URLSearchParams(window.location.search);
  const type = query?.get("type");
  useEffect(() => {
    if (type=='earn') {
      setClicked(1);
    }
}, [])
  return (
    <div>
      <Header />
      {/* Header Section */}
      <div className={`${styles.header} pt-4 pb-5`}>
        <img src={elogo} alt="Logo" />
        <h1 className={styles.title}>ExamBolt Store</h1>
        <p className={styles.subtitle}>
          Redeem our Passes & Quizzes for free by using BoltCoins.
        </p>
        <div className={styles["nav-buttons"]}>
          <button onClick={() => { setClicked(0) }} className={`border light-box-shadow ${!clicked ? styles['clicked'] : styles.not - clicked}`}>Redeem</button>
          <button onClick={() => { setClicked(1) }} className={`border light-box-shadow ${clicked === 1 ? styles['clicked'] : styles.not - clicked}`}>Earn BoltCoins</button>
          <button onClick={() => { setClicked(2) }} className={`border light-box-shadow ${clicked === 2 ? styles['clicked'] : styles.not - clicked}`}>History</button>
        </div>
      </div>
      <div>
        {clicked === 0 && <Redeem />}
        {clicked === 1 && <EarnBoltCoin />}
        {clicked === 2 && <CoinHistory />}
      </div>
      <Footer />
    </div>
  );
};

export default BoltCoinStore;
