import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const MockComingSoon = () => {
  return (
    <div className="mt-3 ">
      <div
        className="text-white bg-primary pb-3 pt-3 border light-box-shadow cursor-pointer"
        style={{
          width: "280px",
          height:"100%",
          borderRadius: "15px",
          background: "linear-gradient(135deg, #143c3e, #204d4f)",
          paddingLeft:"20px",
          paddingRight:"20px"
        }}
      >
        <div className="text-center d-flex align-items-center ">
          <div
            style={{
              width: "60px",
              height: "60px",
              background: "#D6F6E7",
              borderRadius: "10px",
              marginRight:"10px"
            }}
          >
            <span
              style={{
                fontSize: "40px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              <i class="bi bi-book"></i>
            </span>
          </div>
          {/* Header */}
        <h3 className="fw-bold fs-18" style={{textAlign:"left",color:"#fff"}}>Exam Mock Tests</h3>
        </div>

        {/* Icon (Placeholder for Cube Illustration) */}
        

        {/* Date and Time */}
        <div className="mt-2 mb-3">
          <p className=" fw-bold">Feel Your Government Exam at your home</p>
          <p className="mt-2">You have never given mock tests like this ever before</p>
        </div>

        {/* Button */}
        <div className="text-center">
          <button className="btn btn-light text-primary fw-bold">
            Coming Soon
          </button>
        </div>

        {/* Footer */}
        {/* <p className="text-center mt-1" style={{ fontSize: "12px" }}>
          Play to win upto 400 Bolt Coins. Click above to register.
        </p> */}
      </div>
    </div>
  );
};

export default MockComingSoon;
