import React, { useEffect, useState } from 'react';

const TestSecurity = ({ finishTest }) => {
    const [warningCount, setWarningCount] = useState(0);

    // Disable right-click
    useEffect(() => {
        const disableRightClick = (e) => e.preventDefault();
        window.addEventListener('contextmenu', disableRightClick);

        return () => window.removeEventListener('contextmenu', disableRightClick);
    }, []);

    // Disable copy-paste and selection
    useEffect(() => {
        const preventCopyPaste = (e) => e.preventDefault();

        document.addEventListener('copy', preventCopyPaste);
        document.addEventListener('cut', preventCopyPaste);
        document.addEventListener('paste', preventCopyPaste);
        document.addEventListener('selectstart', preventCopyPaste);

        return () => {
            document.removeEventListener('copy', preventCopyPaste);
            document.removeEventListener('cut', preventCopyPaste);
            document.removeEventListener('paste', preventCopyPaste);
            document.removeEventListener('selectstart', preventCopyPaste);
        };
    }, []);

    // Warn on tab switching
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                setWarningCount((prevCount) => {
                    const newCount = prevCount + 1;

                    // Show warning alert

                    // Call finishTest after 10 warnings
                    if (newCount > 3) {
                        finishTest();
                    }else{
                        alert(`You cannot switch tabs during the test. This is warning ${newCount} of 3.`);
                    }

                    return newCount;
                });
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [finishTest]);

    // Block keyboard shortcuts (Ctrl+C, F12, etc.)
    useEffect(() => {
        const blockShortcuts = (e) => {
            if (
                (e.ctrlKey && e.shiftKey && e.key === 'I') || // Ctrl+Shift+I
                e.key === 'F12' || // F12 Developer Tools
                (e.ctrlKey && e.key === 'U') // Ctrl+U View Source
            ) {
                e.preventDefault();
            }
        };

        window.addEventListener('keydown', blockShortcuts);
        return () => window.removeEventListener('keydown', blockShortcuts);
    }, []);

    // Prevent page reload and navigation
    useEffect(() => {
        // Block reload (F5) and Ctrl+R
        const blockReloadAndNavigation = (e) => {
            if (e.key === 'F5' || (e.ctrlKey && e.key === 'r')) {
                e.preventDefault();
            }
        };

        // Prevent back navigation by overriding the popstate event
        const preventBackNavigation = (e) => {
            e.preventDefault();
            window.history.pushState(null, '', window.location.href);
        };

        // Prevent page unload (like refresh or closing the tab) with a warning
        const warnOnPageUnload = (e) => {
            e.preventDefault();
            e.returnValue = ''; // This triggers the default "Are you sure you want to leave?" dialog in browsers
        };

        // Add event listeners
        window.addEventListener('keydown', blockReloadAndNavigation);
        window.addEventListener('popstate', preventBackNavigation);
        window.history.pushState(null, '', window.location.href);
        window.addEventListener('beforeunload', warnOnPageUnload); // Warn user when trying to reload or leave the page

        return () => {
            // Clean up the event listeners
            window.removeEventListener('keydown', blockReloadAndNavigation);
            window.removeEventListener('popstate', preventBackNavigation);
            window.removeEventListener('beforeunload', warnOnPageUnload);
        };
    }, []);

    return null; // No visual output, only security mechanisms
};

export default TestSecurity;
