import React, { useState } from "react";
import styles from "./styles.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const faqs = [
    {
      question: "Which plan is best for someone preparing for multiple exams?",
      answer:
        "The Bolt Pass Gold plan is ideal for comprehensive preparation as it includes all features: practice unlimited questions, unlimited test series and monthly quizzes, making it perfect for multi-exam preparation.",
    },
    {
      question: "Can I change my plan later?",
      answer: "Yes, you can change your plan anytime from pricing section",
    },
    {
      question: "What is your cancellation policy?",
      answer: "At ExamBolt, we offer a one-time purchase model for specific time periods, such as monthly or 6-month plans. Since payments are made upfront for the selected duration, cancellations or refunds are not applicable once the purchase is complete. However, you will retain full access to all the features included in your chosen plan until the end of the purchased period. If you have any concerns or issues, feel free to reach out to our support team for assistance.",
    },
    {
      question: "Are there any discounts for longer subscription durations?",
      answer: "Yes, all plans offer a discounted rate for 6-month subscriptions compared to monthly payments. For example, the 6-month Bolt Pass Bronze is ₹449 instead of ₹594 (₹99 x 6).",
    },
  ];

  // Filter FAQs based on search term
  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setOpenIndex(null); // Close all FAQs when a search is performed
  };

  return (
    <div className={`${styles.faqContainer} container`}>
      <h1 className={styles.faqTitle}>Frequently Asked Questions</h1>
      <p className={styles.faqSubtitle}>Have questions? We're here to help.</p>

      <div className={`input-group mb-4 ${styles.searchBox}`}>
        <input
          type="text"
          className="form-control border"
          placeholder="Search"
          aria-label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className={styles.faqList}>
        {filteredFaqs.length > 0 ? (
          filteredFaqs.map((faq, index) => (
            <div
              key={index}
              className={`card ${styles.faqCard} ${
                openIndex === index ? styles.active : ""
              }`}
            >
              <div
                className={`card-header ${styles.faqHeader}`}
                onClick={() => toggleFAQ(index)}
              >
                <h5 className={styles.faqQuestion}>{faq.question}</h5>
                <span className={styles.faqToggle}>
                  {openIndex === index ? "−" : "+"}
                </span>
              </div>
              {openIndex === index && (
                <div className={`card-body ${styles.faqAnswer}`}>
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className={styles.noResults}>No FAQs match your search.</p>
        )}
      </div>

      <div className={styles.contactSection}>
        <p>Still have questions?</p>
        <p>Can't find the answer you're looking for? Please <a href="/contact-us" style={{cursor:"pointer"}}>Contact Us</a> to our friendly team.</p>
      </div>
    </div>
  );
};

export default Faq;
