import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../_actions/loaderActions';
import { getRandomColor } from '../../../../_constants/constant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TwoLineComponent from '../../../CommonComponents/TwoLineComponent/TwoLineComponent';
import axiosInstance from '../../../../axios/axiosInstance';
import Pagination from '../../../CommonComponents/Pagination/Pagination';

const PaymentHistory = () => {
    const [paymentHistories, setPaymentHistories] = useState([]);
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const [paginationData, setPaginationData] = useState();
    const [page, setPage] = useState(1);
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };
    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "short",
            day: "numeric",
        });
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        });
        return { formattedDate, formattedTime };
    };
    useEffect(() => {
        // Fetch plan data from the API
        const fetchPlans = async () => {
            dispatch(showLoader());
            try {
                const data = await axiosInstance.get(`/payment-history?per_page=10&page=${page}`);
                setPaymentHistories(data?.data?.data?.data);
                setPaginationData({
                    currentPage: data?.data?.data?.current_page,
                    totalPages: data?.data?.data?.last_page,
                    resultsPerPage: data?.data?.data?.per_page,
                    totalResults: data?.data?.data?.total,
                    pagesLink: data?.data?.data?.links,
                });
                dispatch(hideLoader());
            } catch (error) {
                console.error("Failed to fetch plans:", error);
                dispatch(hideLoader());
            }
        };
        fetchPlans();
    }, [page]);

    const handlePageChange = (p) => {
        setPage(p);
    }
    return (
        <div className='payment-history-section-mobile'>
            <h2>Payment History</h2>
            {paymentHistories && paymentHistories?.map((payment, index) => {

                const { formattedDate, formattedTime } = formatTimestamp(payment.created_at);
                return (
                    <div className='d-flex light-box-shadow'>
                        <TwoLineComponent title={'Order ID'} value={payment.order_id} />
                        <TwoLineComponent title={'Amount'} value={payment.amount} />
                        <TwoLineComponent title={'Description'} value={payment?.plan?.title ? `${payment?.plan.title} Bolt Pass` : ""} />
                        <TwoLineComponent title={'Date'} value={formattedDate} />
                        <TwoLineComponent title={'Time'} value={formattedTime} />
                        <TwoLineComponent title={'Status'} value={<span
                            className={`fw-bold text-capitalize ${payment.status === "success" ? "text-success" : payment.status === "pending" ? "text-warning" : "text-danger"
                                }`}
                        >
                            {payment.status}
                        </span>} />
                    </div>
                )
            })}
            <Pagination
                currentPage={paginationData?.currentPage}
                totalPages={paginationData?.totalPages}
                onPageChange={handlePageChange}
                resultsPerPage={paginationData?.resultsPerPage}
                totalResults={paginationData?.totalResults}
            />
        </div>
    )
}

export default PaymentHistory;