import logo from "../_assets/_images/logo2-vertical.jpg";
import elogo from "../_assets/_images/elogo.png";
import hero from "../_assets/_images/hero.png"
import heroDark from "../_assets/_images/hero2.jpg";
import rightArrow from "../_assets/_images/right-arrow.png";
import facebookIcon from "../_assets/_images/icon/facebook.svg";
import linkedinIcon from "../_assets/_images/icon/linkedin.svg";
import instagramIcon from "../_assets/_images/icon/instagram.svg";
import bronzeRedeem from "../_assets/_images/redeem/bronze-bolt-pass.jpg";
import silverRedeem from "../_assets/_images/redeem/silver-bolt-pass.jpg";
import goldRedeem from "../_assets/_images/redeem/gold-bolt-pass.jpg";
import referEarn from "../_assets/_images/refer-n-earn.png";
import quiz from "../_assets/_images/quiz.png";
import prizeIcon from "../_assets/_images/icon/prizeIcon.png";
import greenCheck from "../_assets/_images/icon/greenCheck.png";
import supportIcon from "../_assets/_images/icon/support.png";
import unlimitedPractice from "../_assets/_images/promotion/pass.png";
import practicing from "../_assets/_images/promotion/practice.png";

export { logo, elogo, hero, heroDark, rightArrow, facebookIcon, linkedinIcon, instagramIcon, bronzeRedeem, silverRedeem, goldRedeem, referEarn, quiz, prizeIcon, greenCheck, supportIcon, unlimitedPractice, practicing}