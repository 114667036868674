import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/Header/Header";
import Footer from "../../CommonComponents/Footer/Footer";
import styles from './styles.module.css';
import { addMinutes, compareDateTimeWithCurrent, compareDateWithCurrent, compareWithCurrentTime, DateFormatter, getDate, getMonth, isMobile, TimeFormatter } from "../../../_constants/constant";
import "./style.css";
import { hideLoader, showLoader } from "../../../_actions/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../axios/axiosInstance";
import useFetchAndUpdateUserDetails from "../../../_common/fetchAndUpdateUserDetails";
import { prizeIcon } from "../../../_constants/images";
import Pagination from "../../CommonComponents/Pagination/Pagination";
import { Helmet } from "react-helmet-async";

const QuizListing = () => {
    const [quizzes, setQuizzes] = useState();
    const [loading, setLoading] = useState(true);
    const [quizPurchasePopup, setQuizPurchasePopup] = useState(false);
    const [prizePopup, setPrizePopup] = useState(false);
    const [quiz, setQuiz] = useState();
    const fetchAndUpdateUserDetails = useFetchAndUpdateUserDetails();
    const [paginationData, setPaginationData] = useState();
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const user = JSON.parse(useSelector((state) => state.auth.user));
    const isLogin = JSON.parse(useSelector((state) => state.auth.isAuthenticated));

    // Fetch data from API
    useEffect(() => {
        dispatch(showLoader());
        axiosInstance.get(`/quizzes?per_page=10&page=${page}`) // Replace with your API URL
            .then((data) => {
                setQuizzes(data?.data?.data?.data);
                setPaginationData({
                    currentPage: data?.data?.data?.current_page,
                    totalPages: data?.data?.data?.last_page,
                    resultsPerPage: data?.data?.data?.per_page,
                    totalResults: data?.data?.data?.total,
                    pagesLink: data?.data?.data?.links,
                });
                setLoading(false);
                dispatch(hideLoader());
            })
            .catch((error) => {
                console.error("Error fetching tasks:", error);
                dispatch(hideLoader());
            });
    }, [page]);

    const handlePageChange = (p) => {
        setPage(p);
    }
    const verifyQuizEntry = async (quiz) => {
        if (!isLogin) {
            window.location.href = "/accounts/login";
        }
        if (quiz?.isPermission == true) {
            window.location.href = `/quiz-detail/${quiz?.id}`;
        }
        const data = await axiosInstance.get(`/quiz-permission-check?quiz_id=${quiz?.id}`);
        if (data?.data?.error) {
            setQuiz(quiz);
            setQuizPurchasePopup(true);
        } else {
            window.location.href = `/quiz-detail/${quiz?.id}`;
        }
    }

    const handleRazorpayPayment = async () => {
        dispatch(showLoader());
        let data = await axiosInstance.post(`/quiz/create-order`, {
            quiz_id: quiz?.id
        });
        data = data?.data;
        if (!data) {
            dispatch(hideLoader());
            // alert('Server error. Please try again.');
            return;
        }
        const options = {
            key: 'rzp_live_Ujb8ImX99Ndqd4',
            amount: (data.data.amount) / 100,
            currency: data.data.currency,
            order_id: data?.data.id,
            handler: async function (response) {
                const paymentData = {
                    quiz_id: quiz?.id, // Include plan_id
                    order_id: response.razorpay_order_id,
                    payment_id: response.razorpay_payment_id,
                    signature: response.razorpay_signature,
                    amount: (data?.data.amount) / 100
                };
                const result = await axiosInstance.post(`/quiz/verify-payment`, paymentData);
                if (result) {
                    dispatch(hideLoader());
                    await fetchAndUpdateUserDetails();
                    window.location.reload();
                } else {
                    dispatch(hideLoader());
                    // alert('Payment verification failed');
                }
            },
            prefill: {
                name: user?.name,
                email: user?.email,
                contact: user?.mobile,
            },
            theme: {
                color: '#F37254',
            },
            method: {
                upi: true,
                card: false,
                netbanking: false,
                wallet: false,
                paylater: false

            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "ExamBolt - Quiz Listing",
        "description": "Explore a wide range of monthly quizzes on ExamBolt for SSC, UPSC, banking, railway, and state exams. Practice test series and improve your preparation with full-length quizzes.```",
        "keywords": ["ExamBolt quizzes", "quiz listing", "government exam quizzes", "online quiz platform", "practice quizzes", "mock tests", "exam preparation quizzes", "SSC quizzes", "UPSC quizzes", "banking quizzes", "state exam quizzes", "competitive exam quizzes."]
    };
    return (
        <>
            <Helmet>
                <title>ExamBolt - Quiz Listing</title>
                <meta name="description" content="Explore a wide range of monthly quizzes on ExamBolt for SSC, UPSC, banking, railway, and state exams. Practice test series and improve your preparation with full-length quizzes.```" />
                <meta name="keywords" content="ExamBolt quizzes, quiz listing, government exam quizzes, online quiz platform, practice quizzes, mock tests, exam preparation quizzes, SSC quizzes, UPSC quizzes, banking quizzes, state exam quizzes, competitive exam quizzes." />
                <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
            </Helmet>
            <div>
                <Header />
                <div className={`container ${styles['quizzes']}`}>
                    <h1 className="text-center">Quizzes</h1>
                    <div className="row gap-4">
                        {quizzes && quizzes?.map(quiz => {
                            return (
                                <div className={`col-8 ${styles['quiz']}`}>
                                    <div className="d-flex align-items-center">
                                        <div className="calendar-container text-center">
                                            <div className="calendar-hangers">
                                                <div className="hanger-line"></div>
                                                <div className="hanger-line"></div>
                                            </div>
                                            <div className="calendar-header bg-danger text-white">
                                                {getMonth(quiz?.date)}
                                            </div>
                                            <div className="calendar-body bg-white text-primary">
                                                <span className="calendar-day">{getDate(quiz?.date)}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <h5>{quiz?.title}  {!isMobile && <>•</>} <span>60 minutes</span></h5>
                                            <p>{DateFormatter(quiz?.date)} • {TimeFormatter(quiz?.time)}</p>
                                            <p>{quiz?.description}</p>
                                            {/* <p>{quiz?.number_of_questions}</p> */}
                                            {isMobile &&
                                                <div className="d-flex align-items-center">
                                                    <button className={styles.prize} onClick={() => { setPrizePopup(true) }}><img src={prizeIcon} alt="" /></button>
                                                    {compareDateTimeWithCurrent(quiz?.date, addMinutes(quiz?.time, 60)) ? (user?.plan !== null && user?.plan?.is_quiz) || quiz?.isPermission ?
                                                        <button onClick={() => { verifyQuizEntry(quiz) }}>Join</button>
                                                        :
                                                        <div>
                                                            <p className="text-center mb-2 fw-bold">₹ {quiz?.price}</p>
                                                            <button onClick={() => { verifyQuizEntry(quiz) }}>Get Entry</button>
                                                        </div>
                                                        : quiz?.status == 'results_declared' && quiz?.is_submitted &&
                                                        <button style={{ backgroundColor: "#6A5ACD", borderColor: "#6A5ACD" }} onClick={() => { window.location.href = `/quiz-leaderboard/${quiz?.id}` }}>Leaderboard</button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {!isMobile &&
                                        <div className="d-flex align-items-center">
                                            <button className={styles.prize} onClick={() => { setPrizePopup(true) }}><img src={prizeIcon} alt="" /></button>
                                            {compareDateTimeWithCurrent(quiz?.date, addMinutes(quiz?.time, 60)) ? (user?.plan !== null && user?.plan?.is_quiz) || quiz?.isPermission ?
                                                <button onClick={() => { verifyQuizEntry(quiz) }}>Join</button>
                                                :
                                                <div>
                                                    <p className="text-center mb-2 fw-bold">₹ {quiz?.price}</p>
                                                    <button onClick={() => { verifyQuizEntry(quiz) }}>Get Entry</button>
                                                </div>
                                                : quiz?.is_submitted &&
                                                <button style={{ backgroundColor: "#6A5ACD", borderColor: "#6A5ACD" }} onClick={() => { window.location.href = `/quiz-leaderboard/${quiz?.id}` }}>Leaderboard</button>
                                            }
                                        </div>}
                                </div>
                            )
                        })}
                    </div>
                    <Pagination
                        currentPage={paginationData?.currentPage}
                        totalPages={paginationData?.totalPages}
                        onPageChange={handlePageChange}
                        resultsPerPage={paginationData?.resultsPerPage}
                        totalResults={paginationData?.totalResults}
                    />
                </div>
                <Footer />

                {quizPurchasePopup && <div className="quiz-purchase-popup">
                    <div>
                        <p>You don't have access to the quiz : {quiz?.title}</p>
                        <p>Purchase now to get the entry to {quiz?.title} ?</p>
                        <p className='pt-3'>Total Payment Amount :- ₹ {quiz?.price}</p>
                        <button type='button' onClick={() => { handleRazorpayPayment() }} className='proceed-payment border light-box-shadow cursor-pointer'>Proceed to Payment</button>
                        <a onClick={() => { setQuizPurchasePopup(false) }} className="cross">⛌</a>
                    </div>
                </div>
                }
                {
                    prizePopup && <div className="quiz-prize-popup">
                        <div>
                            <h3>Quiz Rewards</h3>
                            <div className="d-flex align-items-center medal"><span>🥇</span><p>400 Bolt ⚡ Coins</p></div>
                            <div className="d-flex align-items-center medal"><span>🥈</span><p>300 Bolt ⚡ Coins</p></div>
                            <div className="d-flex align-items-center medal"><span>🥉</span><p>200 Bolt ⚡ Coins</p></div>
                            <div className="mt-2"><p>Participation Bonus 50 Bolt ⚡ Coins</p></div>
                            <a onClick={() => { setPrizePopup(false) }} className="cross">⛌</a>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default QuizListing;