import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../_actions/loaderActions';
import { getRandomColor } from '../../../../_constants/constant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axiosInstance from '../../../../axios/axiosInstance';
import Pagination from '../../../CommonComponents/Pagination/Pagination';

const PaymentHistory = () => {
    const [paymentHistories, setPaymentHistories] = useState([]);
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const [paginationData, setPaginationData] = useState();
    const [page, setPage] = useState(1);
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };
    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "short",
            day: "numeric",
        });
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        });
        return { formattedDate, formattedTime };
    };
    useEffect(() => {
        // Fetch plan data from the API
        const fetchPlans = async () => {
            dispatch(showLoader());
            try {
                const data = await axiosInstance.get(`/payment-history?per_page=10&page=${page}`);
                setPaymentHistories(data?.data?.data?.data);
                setPaginationData({
                    currentPage: data?.data?.data?.current_page,
                    totalPages: data?.data?.data?.last_page,
                    resultsPerPage: data?.data?.data?.per_page,
                    totalResults: data?.data?.data?.total,
                    pagesLink: data?.data?.data?.links,
                });
                dispatch(hideLoader());
            } catch (error) {
                console.error("Failed to fetch plans:", error);
            }
        };
        fetchPlans();
    }, [page]);

    const handlePageChange = (p) => {
        setPage(p);
    }

    return (
        <div className='payment-history-section'>
            <h2>Payment History</h2>
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead className="table-light">
                        <tr>
                            <th>Order ID</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentHistories && paymentHistories?.map((payment, index) => {
                            const { formattedDate, formattedTime } = formatTimestamp(payment.created_at);
                            return (
                                <tr key={index}>
                                    <td><OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>{payment.order_id}</Tooltip>}
                                    >
                                        <span>{truncateText(payment.order_id, 15)}</span>
                                    </OverlayTrigger></td>
                                    <td>{payment.amount}</td>
                                    <td>{payment?.plan?.title ? `${payment?.plan.title} Bolt Pass` : ""}</td>
                                    <td>{formattedDate}</td>
                                    <td>{formattedTime}</td>
                                    <td>
                                        <span
                                            className={`badge text-capitalize ${payment.status === "success" ? "bg-success" : payment.status === "pending" ? "bg-warning text-dark" : "bg-danger"
                                                }`}
                                        >
                                            {payment.status}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <Pagination
                currentPage={paginationData?.currentPage}
                totalPages={paginationData?.totalPages}
                onPageChange={handlePageChange}
                resultsPerPage={paginationData?.resultsPerPage}
                totalResults={paginationData?.totalResults}
            />
        </div>
    )
}

export default PaymentHistory;