import React from 'react';
import { useSelector } from 'react-redux';

const Overview = () => {
    const user = JSON.parse(useSelector((state) => state.auth.user));
    return (
        <div className="overview">
            <h4>Plan Activated : {user?.plan?.title || 'none'}</h4>
        </div>
    );
}
export default Overview;