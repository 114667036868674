import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../_actions/loaderActions';
import { DateFormatter, getRandomColor, TimeFormatter } from '../../../../_constants/constant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axiosInstance from '../../../../axios/axiosInstance';

const QuizHistory = () => {
    const [quizzes, setQuizzes] = useState([]);
    const dispatch = useDispatch();
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };
    useEffect(() => {
        // Fetch plan data from the API
        const fetchPlans = async () => {
            dispatch(showLoader());
            try {
                const data = await axiosInstance.get(`/user-quiz-history`);
                setQuizzes(data?.data?.data);
                dispatch(hideLoader());
            } catch (error) {
                console.error("Failed to fetch plans:", error);
            }
        };
        fetchPlans();
    }, []);

    return (
        <div className='payment-history-section'>
            <h2>Quiz History</h2>
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead className="table-light">
                        <tr>
                            <th>Quiz</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Questions</th>
                            <th>Your Score</th>
                            <th>Your Rank</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quizzes.map((quiz, index) => {
                            return (
                                <tr key={index}>
                                    <td>{quiz?.title}</td>
                                    <td>{DateFormatter(quiz?.date)}</td>
                                    <td>{TimeFormatter(quiz?.time)}</td>
                                    <td>{quiz?.number_of_questions}</td>
                                    <td>{quiz?.leaderboard?.total_score}</td>
                                    <td>{quiz?.leaderboard?.rank}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default QuizHistory;