import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from './styles.module.css';
import { useSelector } from "react-redux";
import { boltCoinReward } from "../../../_constants/constant";

const LoginReferal = () => {
    const [referCode, setReferCode] = useState('KJ75SD');
    const [notification, setNotification] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const user =  JSON.parse(useSelector((state) => state.auth.user));

    const handleCopy = (text) => {
        navigator.clipboard
      .writeText(text)
      .then(() => {
        setNotification("Copied to clipboard!"); // Set the notification text
        setShowNotification(true); // Show the notification
        setTimeout(() => {
          setShowNotification(false); // Auto-remove notification after 2 seconds
        }, 1000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
      };
  return (
    <div className="container mt-4">
      {/* Popup Notification */}
      {showNotification && (
        <div
          className={`position-fixed start-50 translate-middle-x p-2 rounded shadow fs-16 ${styles['notification']}`}
          style={{
            zIndex: 1050,
            animation: "slideIn 0.5s ease-in-out",
            maxWidth: "300px",
          }}
        >
          {notification}
          {/* <button
            type="button"
            className="btn-close btn-close-white position-absolute top-0 end-0 m-2"
            aria-label="Close"
            onClick={() => setShowNotification(false)}
          ></button> */}
        </div>
      )}
      <div className="row">
        {/* Referral ID */}
        <div className="col-md-6 mb-3">
          <div className="shadow-sm">
            <h6 className="mb-2">Referral ID</h6>
            <div className={`d-flex align-items-center ${styles['light-back']}`}>
              <span className="fw-bold me-3">{user.refer_code}</span>
              <button className="btn btn-outline-success btn-sm" onClick={()=>{handleCopy(referCode)}}>Copy</button>
            </div>
          </div>
        </div>

        {/* Referral Link */}
        <div className="col-md-6 mb-3">
          <div className="shadow-sm">
            <h6 className="mb-2">Referral Link</h6>
            <div className={`d-flex align-items-center ${styles['light-back']}`}>
              <span className="text-truncate me-3" style={{ maxWidth: "70%" }} title={`https://www.exambolt.in/accounts/login?refCode=${user.refer_code}`}>
                https://www.exambolt.in/accounts/login?refCode={user.refer_code}
              </span>
              <button className="btn btn-outline-success btn-sm" onClick={()=>{handleCopy(`https://www.exambolt.in/accounts/login?refCode=${user.refer_code}`)}}>Copy</button>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        {/* You Receive */}
        <div className="col-md-6 mb-3">
          <div className="shadow-sm">
            <h6 className="mb-2">You Receive</h6>
            <h4 className={`fw-bold mb-0 ${styles['light-back']}`}>{boltCoinReward.referBonus} <span className="fs-18">⚡</span><small className="text-muted">Coins</small></h4>
          </div>
        </div>

        {/* Friends Receive */}
        <div className="col-md-6 mb-3">
          <div className="shadow-sm">
            <h6 className="mb-2">Friends Receive</h6>
            <h4 className={`fw-bold mb-0 ${styles['light-back']}`}>{boltCoinReward.referBonus} <span className="fs-18">⚡</span><small className="text-muted">Coins</small></h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginReferal;
