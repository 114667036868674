// src/components/SubscriptionPlans.js

import React, { useEffect, useState } from 'react';
import './SubscriptionPlans.css'; // Import custom styles
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../../_actions/loaderActions';
import PlanPurchaseInfo from './PlanPurchaseInfo';
import LoginPopup from '../LoginPopup/LoginPopup';
import axiosInstance from '../../../axios/axiosInstance';

const SubscriptionPlans = () => {
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);
  const [isYearly, setIsYearly] = useState(false); // State for monthly/yearly toggle
  const dispatch = useDispatch();
  const [purchasePopup, setPurchasePopup] = useState(false);
  const [purchasePlan, setPurchasePlan] = useState({});
  const isLogin = JSON.parse(useSelector((state) => state.auth.isAuthenticated));
  
  const [showLogin, setShowLogin] = useState(false);
  const handleOpenLogin = () => setShowLogin(true);
  const handleCloseLogin = () => setShowLogin(false);
  const user = JSON.parse(useSelector((state) => state.auth.user));
  useEffect(() => {
    // Fetch plan data from the API
    const fetchPlans = async () => {
      dispatch(showLoader());
      try {
        let data = await axiosInstance.get(`/get-plans`); // Replace with your API endpoint
        data = data?.data;
        setMonthlyPlans(data?.data.filter(d => d.months == 1));
        setYearlyPlans(data?.data.filter(d => d.months == 12));
        dispatch(hideLoader());
      } catch (error) {
      }
    };
    fetchPlans();
  }, []);

  const handlePurchase = (plan) => {
    if (isLogin) {
      setPurchasePlan(plan);
      setPurchasePopup(true);
    }else{
      handleOpenLogin();
    }
  }



  return (
    <div className={`container text-center my-5 subscription-plans`}>
      <LoginPopup show={showLogin} handleClose={handleCloseLogin} />
      <h2 className="mb-4">Pick a Bolt ⚡ Pass that's right for you</h2>
      {/* <p>Pricing plans for businesses at every stage of growth.</p> */}

      <div className="btn-group mb-4">
        <button
          className={`btn border light-box-shadow ${!isYearly ? "btn-dark" : "btn-outline-dark"}`}
          onClick={() => setIsYearly(false)}
        >
          Monthly
        </button>
        <button
          className={`btn border light-box-shadow ${isYearly ? "btn-dark" : "btn-outline-dark"}`}
          onClick={() => setIsYearly(true)}
        >
          Yearly <span className="badge bg-warning">Upto 75% Off</span>
        </button>
      </div>
      <div className="row">
        {!isYearly && monthlyPlans?.length > 0 && monthlyPlans?.map(plan => (
          <div key={plan?.id} className="col-md-4 mb-4">
            <div className={`card border light-box-shadow ${plan?.title === "Silver" ? "highlighted-card" : ""}`}>
              <div className="card-body">
                <h5 className="card-title">{plan?.title.toUpperCase()}</h5>
                <h6 className="card-price">
                  ₹ <span className={'price-cut'}>{plan?.amount}</span>/month
                  <h4>₹ {plan?.discount_amount}/month</h4>
                </h6>
                <h6></h6>
                <ul className="list-unstyled mt-3 mb-4">
                  {plan?.description?.map((feature, i) => (
                    <li key={i} className="feature-item">{feature}</li>
                  ))}
                </ul>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => handlePurchase(plan)}
                  disabled={user && user?.plan?.is_active && plan?.id==user?.plan?.id && true}
                >
                  {user?.plan?.is_active && plan?.title.toLowerCase()==user?.plan?.title.toLowerCase()?'Subscribed':'Get Your Bolt ⚡ Pass'}
                </button>
              </div>
            </div>
          </div>
        ))}
        {console.log("hello")}
        {isYearly && yearlyPlans?.length > 0 && yearlyPlans?.map(plan => (
          <div key={plan?.id} className="col-md-4 mb-4">
            <div className={`card h-100 border light-box-shadow ${plan?.title === "Silver" ? "highlighted-card" : ""}`}>
              <div className="card-body">
                <h5 className="card-title">{plan?.title.toUpperCase()}</h5>
                <h6 className="card-price">
                  ₹ <span className={'price-cut'}>{plan?.amount}</span>/year
                  <h4>₹ {plan?.discount_amount}/year</h4>
                </h6>
                <ul className="list-unstyled mt-3 mb-4">
                  {plan?.description?.map((feature, i) => (
                    <li key={i} className="feature-item">{feature}</li>
                  ))}
                </ul>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => handlePurchase(plan)}
                  disabled={user && user?.plan?.is_active && plan.title.id==user?.plan?.id && true}
                >
                  {user?.plan?.is_active && plan.id==user?.plan?.id?'Subscribed':'Get Your Bolt ⚡ Pass'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {purchasePopup && <PlanPurchaseInfo purchasePlan={purchasePlan} setPurchasePopup={setPurchasePopup} />}
    </div>
  );
};

export default SubscriptionPlans;
