// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.styles_hero__kvSQf{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.styles_hero__kvSQf>div{
    width: 80%;
}
.styles_icon__X0iTo{
    width: 20px;
}
.styles_hero-img__t70EN{
    border-radius: 10px;
}
.styles_info__XsK99{
    height: 120px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/RefernEarn/styles.module.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,UAAU;AACd;AACA;IACI,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,wBAAwB;IACxB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["\n.hero{\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n.hero>div{\n    width: 80%;\n}\n.icon{\n    width: 20px;\n}\n.hero-img{\n    border-radius: 10px;\n}\n.info{\n    height: 120px !important;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `styles_hero__kvSQf`,
	"icon": `styles_icon__X0iTo`,
	"hero-img": `styles_hero-img__t70EN`,
	"info": `styles_info__XsK99`
};
export default ___CSS_LOADER_EXPORT___;
