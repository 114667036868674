// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    border-top: var(--border-top);
}
.footer a {
    color: var(--text-color);
  }
  
  .footer a:hover {
    color: var(--text-color);
  }
  
  .footer .badge {
    font-size: 0.8rem;
    color: var(--text-color);
    background-color: var(--background-color);
  }
  .footer a img{
    color: #fff;
  }
.footer-subscribe-form>div{
  width: 70%;
}
.footer-subscribe-form>div imput{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.footer-subscribe-form>button{
  width: 30% !important;
  border: 1px solid #000;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA;IACI,wBAAwB;EAC1B;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,iBAAiB;IACjB,wBAAwB;IACxB,yCAAyC;EAC3C;EACA;IACE,WAAW;EACb;AACF;EACE,UAAU;AACZ;AACA;EACE,uCAAuC;EACvC,0CAA0C;AAC5C;AACA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,2BAA2B;EAC3B,8BAA8B;AAChC","sourcesContent":[".footer{\n    border-top: var(--border-top);\n}\n.footer a {\n    color: var(--text-color);\n  }\n  \n  .footer a:hover {\n    color: var(--text-color);\n  }\n  \n  .footer .badge {\n    font-size: 0.8rem;\n    color: var(--text-color);\n    background-color: var(--background-color);\n  }\n  .footer a img{\n    color: #fff;\n  }\n.footer-subscribe-form>div{\n  width: 70%;\n}\n.footer-subscribe-form>div imput{\n  border-top-right-radius: 0px !important;\n  border-bottom-right-radius: 0px !important;\n}\n.footer-subscribe-form>button{\n  width: 30% !important;\n  border: 1px solid #000;\n  border-top-left-radius: 0px;\n  border-bottom-left-radius: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
