import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { apiUrl } from '../_constants/config';
import { Navigate } from 'react-router-dom'; // For redirecting
import { logout } from '../_actions/loginActions';
import { store } from '../_store/store';
import { hideLoader } from '../_actions/loaderActions';
import { useDispatch } from 'react-redux';
import { showAlert } from '../_actions/alertActions';

const axiosInstance = axios.create({
  baseURL: apiUrl, // Your API base URL
});

// Request interceptor to add the token to the request headers
axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const user = JSON.parse(state.auth?.user);
    const token = user?.token;
    if (token) {
      // Decode the token to check if it's expired
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // If token is expired, log out user and redirect
        store.dispatch(logout());
        window.location.href = '/accounts/login'; // Redirect to login
      } else {
        // Set the Authorization header with the token
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    const dispatch = useDispatch();
    dispatch(hideLoader());
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.error) {
      const dispatch = store.dispatch;
      // Handle other API errors and show alert
      const errorMessage = response?.data?.message || 'Something went wrong.';
      dispatch(showAlert({ type: 'error', message: errorMessage }));
    }
    return response;
  },
  (error) => {
    const dispatch = store.dispatch;

    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        const errorMessage = error?.response?.data?.message || 'Something went wrong.';
        dispatch(showAlert({ type: 'error', message: errorMessage }));
        store.dispatch(hideLoader());
        return error?.response?.data;
      } if (status === 422) {
        if (typeof error?.response?.data?.message ==='object') {
          Object.entries(error?.response?.data?.message).forEach(([key, value]) => {
            dispatch(showAlert({ type: 'error', message: value[0] || 'Something went wrong.' }));
        });
        } else {
          const errorMessage = error?.response?.data?.message || 'Something went wrong.';
          dispatch(showAlert({ type: 'error', message: errorMessage }));
        }
        store.dispatch(hideLoader());
        return error?.response?.data;
      }
    } else {
      // Handle network or unknown errors
      dispatch(showAlert({ type: 'error', message: 'Network error. Please try again later.' }));
    }

    store.dispatch(hideLoader());
    return Promise.reject(error);
  }
);

export default axiosInstance;
