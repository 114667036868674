// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_light-back__JMn3E{
    padding: 5px 20px 5px 10px;

    border-radius: 8px;
    background-color: #C5F5DD;
    color: #000;
}
.styles_light-back__JMn3E>small{
    color: #272727 !important;
}
.styles_notification__Fbok8{
    top: 10%;
    background-color: #5BE4A9;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/LoginReferal/styles.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;;IAE1B,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,QAAQ;IACR,yBAAyB;AAC7B","sourcesContent":[".light-back{\n    padding: 5px 20px 5px 10px;\n\n    border-radius: 8px;\n    background-color: #C5F5DD;\n    color: #000;\n}\n.light-back>small{\n    color: #272727 !important;\n}\n.notification{\n    top: 10%;\n    background-color: #5BE4A9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light-back": `styles_light-back__JMn3E`,
	"notification": `styles_notification__Fbok8`
};
export default ___CSS_LOADER_EXPORT___;
