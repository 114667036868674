import React, { useContext, useEffect, useState } from "react";
import Header from "../../CommonComponents/Header/Header";
import Footer from "../../CommonComponents/Footer/Footer";
import styles from './styles.module.css';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../_actions/loaderActions";
import axiosInstance from "../../../axios/axiosInstance";
import { compareDateTimeWithCurrent, compareDateTimeWithCurrentToStart, DateFormatter, TimeFormatter } from "../../../_constants/constant";
import { ThemeContext } from "../../../_common/ThemeContext";
import FullScreenSecurity from "../../CommonComponents/TestSecurity/FullScreenSecurity";
const QuizDetail = () => {

    const [quiz, setQuiz] = useState();
    const { language, toggleLanguage } = useContext(ThemeContext);
    const [terms, setTerms] = useState(false);
    const [start, setStart] = useState(false);

    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(showLoader());
        axiosInstance.get(`/quiz-details?quiz_id=${id}`)
            .then((data) => {
                setQuiz(data?.data?.data);
                dispatch(hideLoader());
            })
            .catch((error) => {
                console.error("Error fetching tasks:", error);
                dispatch(hideLoader());
            });
    }, []);
    useEffect(() => {
        const updateStart = () => {
            setStart(compareDateTimeWithCurrentToStart(quiz?.date, quiz?.time));
        };
        updateStart();
        if (!start) {
            const now = new Date();
            const millisecondsUntilNextMinute = (60 - now.getSeconds()) * 1000;

            // Run the function at the next minute change
            const timeout = setTimeout(() => {
                updateStart();

                // Start an interval to update every minute after the first execution
                const interval = setInterval(updateStart, 60 * 1000);

                // Cleanup interval when component unmounts or `quiz` changes
                return () => clearInterval(interval);
            }, millisecondsUntilNextMinute);

            // Cleanup timeout when component unmounts or `quiz` changes
            return () => clearTimeout(timeout);
        }
    }, [quiz]);
    // const openQuizInNewWindow = () => {
    //     const quizWindow = window.open(
    //         `/quiz/${id}`, // Path to the quiz page
    //         'QuizWindow',
    //         'width=' + window.screen.width + ',height=' + window.screen.height
    //     );

    //     // Maximize the new window as much as possible
    //     if (quizWindow) {
    //         quizWindow.moveTo(0, 0);
    //         quizWindow.resizeTo(window.screen.width, window.screen.height);
    //     }
    // };


    return (
        <>
            <Header />
            <div className="container mt-5 mb-5">
                {quiz &&
                    <div className={styles.head}>
                        <h2 className={styles.title}>{quiz?.title}</h2>
                        <div className="d-flex"><p className="text-muted">{DateFormatter(quiz?.date)}</p>&nbsp;•&nbsp;<p className="text-muted">{TimeFormatter(quiz?.time)}</p></div>
                        <div className="d-flex"><p className="text-muted">60 minutes</p>&nbsp;•&nbsp;<p className="text-muted">{quiz?.number_of_questions} Questions</p></div>
                    </div>
                }
                <div className="mt-4">
                    <h4>Read the following instructions carefully:</h4>
                    <ul>
                        <li>This test comprises of multiple-choice questions</li>
                        <li>Each questions will have only one of the available options as the correct answer</li>
                        <li>You are advised not to close the browser window before submitting the test.</li>
                        <li>In case, if the test does not load completely or becomes unresponsive, click on browser's refresh button to reload.</li>
                    </ul>
                    <h4 className="mt-3">Marking Scheme:</h4>
                    <ul>
                        <li>1 mark will be awarded for each correct answer.</li>
                        <li>No marks will be deducted for un-attempted questions or wrong answers.</li>
                    </ul>
                    <div className="d-flex">
                        <p>Choose your default Language&nbsp;
                            <select name="language" id="language" value={language} onChange={toggleLanguage}>
                                <option value="en">English</option>
                                <option value="hi">Hindi</option>
                            </select>
                            &nbsp;Please note that all question will appear in your default language. This language can be change after-words.
                        </p>
                    </div>
                    <div className="mt-2">
                        <p><input type="checkbox" id="terms" name="terms" value={terms} onClick={() => { setTerms(!terms) }} />&nbsp;I have read and understood all the instructions. I Understand that using unfair means of any sort for any advantage will lead to immediate disqualification. The decision of exambolt.com will be final in these matters.</p>
                    </div>
                    {/* <FullScreenSecurity/> */}
                    <div className="mt-3">
                        <button disabled={!terms || !start} className={styles.start} onClick={() => { window.location.href = `/quiz/${id}` }}>Start Test</button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default QuizDetail;