// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_subscription__lzwii>div{
    width: 80%;
}

@media(max-width:991px){
    .styles_subscription__lzwii>div{
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/Subscription/styles.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".subscription>div{\n    width: 80%;\n}\n\n@media(max-width:991px){\n    .subscription>div{\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscription": `styles_subscription__lzwii`
};
export default ___CSS_LOADER_EXPORT___;
