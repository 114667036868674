import React, { useEffect, useState } from "react";
import styles from './styles.module.css';
import { bronzeRedeem, goldRedeem, greenCheck, quiz, silverRedeem } from "../../../_constants/images";
import { redeemBoltCoin } from "../../../_constants/constant";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../axios/axiosInstance";
import useFetchAndUpdateUserDetails from "../../../_common/fetchAndUpdateUserDetails";
import { hideLoader, showLoader } from "../../../_actions/loaderActions";

const Redeem = () => {
  const user = JSON.parse(useSelector((state) => state.auth.user));
  const [feedback, setFeedback] = useState({ status: false, message: "", plan: "", error: false });
  const fetchAndUpdateUserDetails = useFetchAndUpdateUserDetails();
  const dispatch = useDispatch();

  const redeemPass = async (type = '') => {
    if (type !== '') {
      try {
        dispatch(showLoader());
        const response = await axiosInstance.post(`/redeem-coins`, { type: type });
        const data = response?.data;
        fetchAndUpdateUserDetails();
        if (!data?.error) {
          setFeedback({ status: true, message: data?.message, plan: type, error: false });
        } else {
          setFeedback({ status: false, message: data?.message, plan: type, error: true });
        }
        dispatch(hideLoader());
      } catch (error) {
        setFeedback({ status: false, message: error?.message, plan: type, error: true });
        dispatch(hideLoader());
      }
    }
  }
  const handlePopupClose = () => {
    setFeedback({ status: false, message: "", plan: "", error: false });
  }

  return (
    <>
      <div className="container mt-4 pt-5 pb-5">
        <div className="d-flex flex-wrap justify-content-between">

          {/* Card 4 */}
          <div className={`${styles.card} border light-box-shadow`}>
            <div className={`d-flex align-items-center justofy-content-center ${styles['redeem-image']}`}>
              <img src={quiz} alt="" />
            </div>
            <div className="d-flex p-2 align-items-center justify-content-between">
              <div className={`${styles['card-body']} d-flex flex-column justify-content-between`}>
                <div>
                  <h5 className={`${styles["card-title"]} ${styles['earn-title']}`}>Quiz Entry</h5>
                  <p className={`${styles["card-text"]} mt-1`}>
                    Get upcoming quiz entry for free
                  </p>
                </div>
              </div>
              <Button className={`${styles["card-footer"]} cursor-pointer`} onClick={() => { redeemPass('Quiz') }} disabled={user?.bolt_coins < redeemBoltCoin.quizReddem && true}>{redeemBoltCoin.quizReddem} ⚡</Button>
            </div>
          </div>

          {/* Card 1 */}
          <div className={`${styles.card} border light-box-shadow`}>
            <div className={`d-flex align-items-center justify-content-center ${styles['redeem-image']}`} style={{ backgroundColor: "#A36528" }}>
              <h1>⚡</h1>
            </div>
            <div className="d-flex p-2 align-items-center justify-content-between">
              <div className={`${styles['card-body']} d-flex flex-column justify-content-between`}>
                <div>
                  <h5 className={`${styles["card-title"]} ${styles['earn-title']}`}>Bronze Bolt Pass</h5>
                  <p className={`${styles["card-text"]} mt-1`}>
                    Get 1-month Bronze Bolt Pass for free
                  </p>
                </div>
              </div>
              <Button className={`${styles["card-footer"]} cursor-pointer`} onClick={() => { redeemPass('Bronze') }} disabled={user?.bolt_coins < redeemBoltCoin.bronzeBoltPass && true} >{redeemBoltCoin.bronzeBoltPass} ⚡</Button>
            </div>
          </div>

          {/* Card 2 */}
          <div className={`${styles.card} border light-box-shadow`}>
            <div className={`d-flex align-items-center justify-content-center ${styles['redeem-image']}`} style={{ backgroundColor: "#C0C0C0" }}>
              <h1>⚡</h1>
            </div>
            <div className="d-flex p-2 align-items-center justify-content-between">
              <div className={`${styles['card-body']} d-flex flex-column justify-content-between`}>
                <div>
                  <h5 className={`${styles["card-title"]} ${styles['earn-title']}`}>Silver Bolt Pass</h5>
                  <p className={`${styles["card-text"]} mt-1`}>
                    Get 1-month Silver Bolt Pass for free
                  </p>
                </div>
              </div>
              <Button className={`${styles["card-footer"]} cursor-pointer`} onClick={() => { redeemPass('Silver') }} disabled={user?.bolt_coins < redeemBoltCoin.silverBoltPass && true}>{redeemBoltCoin.silverBoltPass} ⚡</Button>
            </div>
          </div>

          {/* Card 3 */}
          <div className={`${styles.card} border light-box-shadow`}>
            <div className={`d-flex align-items-center justify-content-center ${styles['redeem-image']}`} style={{ backgroundColor: "#FFD700" }}>
              <h1>⚡</h1>
            </div>
            <div className="d-flex p-2 align-items-center justify-content-between">
              <div className={`${styles['card-body']} d-flex flex-column justify-content-between`}>
                <div>
                  <h5 className={`${styles["card-title"]} ${styles['earn-title']}`}>Gold Bolt Pass</h5>
                  <p className={`${styles["card-text"]} mt-1`}>
                    Get 1-month Gold Bolt Pass for free
                  </p>
                </div>
              </div>
              <Button className={`${styles["card-footer"]} cursor-pointer`} onClick={() => { redeemPass('Gold') }} disabled={user?.bolt_coins < redeemBoltCoin.goldBoltPass && true}>{redeemBoltCoin.goldBoltPass} ⚡</Button>
            </div>
          </div>

        </div>
      </div>
      <div
        className={`modal fade d-flex align-items-center ${feedback?.status ? "show d-block" : "d-none"}`}
        tabIndex="-1"
        role="dialog"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document" style={{ width: '40%' }}>
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title">Status</h5> */}
              <button type="button" className="btn-close" onClick={() => { handlePopupClose() }}></button>
            </div>
            <div className="modal-body d-flex flex-column justify-content-center align-items-center">
              <div className="icon-container">
                <div className="success-icon">
                  <img src={greenCheck} alt="" />
                </div>
              </div>
              <h2>Success!</h2>
              <p className='fs-18'>
                {feedback?.plan}&nbsp;{feedback?.message}
              </p>
              <button className={`modal-button ${styles['ok-btn']}`} onClick={() => { handlePopupClose() }}>
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Redeem;