import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./QuestionComponent.css"; // Optional CSS for additional styles
import { useDispatch, useSelector } from "react-redux";
import LoginPopup from "../LoginPopup/LoginPopup";
import axiosInstance from "../../../axios/axiosInstance";
import useFetchAndUpdateUserDetails from "../../../_common/fetchAndUpdateUserDetails";
import { isMobile } from "../../../_constants/constant";

const QuestionComponent = ({ question, language, handleOptionSelect, testSeries = false, currentQuestion,topicSelected, subTopicSelected,page=1 }) => {
    const [feedback, setFeedback] = useState({ correct: null, message: "" });
    const [selectedOption, setOptionSelected] = useState();
    const [showLogin, setShowLogin] = useState(false);
    const handleOpenLogin = () => setShowLogin(true);
    const handleCloseLogin = () => setShowLogin(false);
    const isLogin = useSelector((state) => state.auth.isAuthenticated);
    const dispatch = useDispatch();
    const fetchAndUpdateUserDetails = useFetchAndUpdateUserDetails();
    useEffect(()=>{
        setOptionSelected();
        setFeedback({ correct: null, message: "" });
    },[page,topicSelected,subTopicSelected]);

    const handleOptionClick = async (selected) => {
        if (!isLogin) {
            handleOpenLogin();
        } else {
            if (testSeries) {
                handleOptionSelect(currentQuestion);
            } else {
                setOptionSelected(selected);
                if (selected == question?.correct_answer) {
                    setFeedback({ correct: true, message: "Correct answer!" });
                    try {
                        const data = await axiosInstance.post(`/user-questions-attempt`, { question_id: question.id });
                        if (!data) {
                            throw new Error('Network response was not ok');
                        }
                        fetchAndUpdateUserDetails();
                    } catch (error) {
                        console.error("Failed to fetch plans:", error);
                    }
                } else {
                    setFeedback({ correct: false, message: "The answer is wrong. Try again!" });
                }
            }
        }
    };
    return (
        <div className="mt-3">
            <LoginPopup show={showLogin} handleClose={handleCloseLogin} />
            {language == 'en' ?
                <div className={`card ${isMobile?`p-2`:`p-4`} border light-box-shadow question-component-card`}>
                    {/* Question Text */}
                    <div className="question-text mb-3 d-flex">
                        Q.
                        <p style={{ marginLeft: "10px" }}>
                            {question?.question}
                        </p>
                    </div>

                    {/* Options */}
                    <div className="options-grid">
                        <button className={`option-btn ${feedback.correct === true && selectedOption == 'A' && 'correct-answer'} ${feedback.correct === false && selectedOption == 'A' && 'wrong-answer'}`} onClick={() => { handleOptionClick('A') }}>A. {question?.option_a}</button>
                        <button className={`option-btn ${feedback.correct === true && selectedOption == 'B' && 'correct-answer'} ${feedback.correct === false && selectedOption == 'B' && 'wrong-answer'}`} onClick={() => { handleOptionClick('B') }}>B. {question?.option_b}</button>
                        <button className={`option-btn ${feedback.correct === true && selectedOption == 'C' && 'correct-answer'} ${feedback.correct === false && selectedOption == 'C' && 'wrong-answer'}`} onClick={() => { handleOptionClick('C') }}>C. {question?.option_c}</button>
                        <button className={`option-btn ${feedback.correct === true && selectedOption == 'D' && 'correct-answer'} ${feedback.correct === false && selectedOption == 'D' && 'wrong-answer'}`} onClick={() => { handleOptionClick('D') }}>D. {question?.option_d}</button>
                    </div>
                    {feedback.message && (
                        <div className={`mt-3 text-${feedback.correct ? "success" : "danger"}`}>
                            {feedback.message}
                        </div>
                    )}
                </div> :
                <div className={`card border light-box-shadow question-component-card  ${isMobile?`p-2`:`p-4`}`}>
                    {/* Question Text */}
                    <div className="question-text mb-3 d-flex">
                        Q.
                        <p style={{ marginLeft: "10px" }}>
                            {question?.question_hindi}
                        </p>
                    </div>

                    {/* Options */}
                    <div className="options-grid">
                        <button className={`option-btn ${feedback.correct === true && selectedOption == 'A' && 'correct-answer'} ${feedback.correct === false && selectedOption == 'A' && 'wrong-answer'}`} onClick={() => { handleOptionClick('A') }}>A. {question?.option_a_hindi}</button>
                        <button className={`option-btn ${feedback.correct === true && selectedOption == 'B' && 'correct-answer'} ${feedback.correct === false && selectedOption == 'B' && 'wrong-answer'}`} onClick={() => { handleOptionClick('B') }}>B. {question?.option_b_hindi}</button>
                        <button className={`option-btn ${feedback.correct === true && selectedOption == 'C' && 'correct-answer'} ${feedback.correct === false && selectedOption == 'C' && 'wrong-answer'}`} onClick={() => { handleOptionClick('C') }}>C. {question?.option_c_hindi}</button>
                        <button className={`option-btn ${feedback.correct === true && selectedOption == 'D' && 'correct-answer'} ${feedback.correct === false && selectedOption == 'D' && 'wrong-answer'}`} onClick={() => { handleOptionClick('D') }}>D. {question?.option_d_hindi}</button>
                    </div>
                    {feedback.message && (
                        <div className={`mt-3 text-${feedback.correct ? "success" : "danger"}`}>
                            {feedback.message}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default QuestionComponent;
