import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "../Forms/Login";
import Signup from "../Forms/Signup";
import { elogo } from "../../../_constants/images";
import styles from "./styles.module.css";

const LoginPopup = ({ show, handleClose }) => {
    const [isLogin,setIsLogin] = useState(true);
  return (
    <div
      className={`modal fade d-flex align-items-center ${show ? "show d-block" : "d-none"}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className={`modal-dialog ${styles['popup']}`} role="document">
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5 className="modal-title">Login</h5>
          </div> */}
          <div className="modal-body position-relative">
          <button type="button" className={`btn-close ${styles['close-btn']}`} onClick={handleClose}></button>
          <img src={elogo} alt="" width="90px" className={`${styles['elogo']}`}/>
          {isLogin==true ? <Login setIsLogin={setIsLogin} isLogin={isLogin} handleClose={handleClose}/>:<Signup setIsLogin={setIsLogin} isLogin={isLogin} handleClose={handleClose}/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
