import React, { useState } from 'react';
import './SubscriptionPlans.css';
import { isMobile } from '../../../_constants/constant';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../../_actions/loaderActions';
import axiosInstance from '../../../axios/axiosInstance';
import useFetchAndUpdateUserDetails from '../../../_common/fetchAndUpdateUserDetails';

const PlanPurchaseInfo = ({ purchasePlan, setPurchasePopup }) => {
    const [couponCode, setCouponCode] = useState('');
    const dispatch = useDispatch();
    const user = JSON.parse(useSelector((state) => state.auth.user));
    const token = localStorage.getItem("token");
    const fetchAndUpdateUserDetails = useFetchAndUpdateUserDetails();
    // Razorpay payment function
    const handleInputChange = (event) => {
        setCouponCode(event.target.value); // Update state on input change
    };
    const handleRazorpayPayment = async () => {
        dispatch(showLoader());
        let data = await axiosInstance.post(`/create-order`, {
            user_id: user?.id, // Example user ID, replace with actual data
            plan_id: purchasePlan?.id, // Example plan ID, replace with actual data
            coupon_code: couponCode
        });
        data = data?.data;
        if (!data) {
            // alert('Server error. Please try again.');
            return;
        }
        const key = 'rzp_live_Ujb8ImX99Ndqd4'
        const options = {
            key: key,
            amount: (data.data.amount) / 100,
            currency: data.data.currency,
            order_id: data?.data.id,
            handler: async function (response) {
                const paymentData = {
                    plan_id: purchasePlan?.id, // Include plan_id
                    order_id: response.razorpay_order_id,
                    payment_id: response.razorpay_payment_id,
                    signature: response.razorpay_signature,
                    amount: (data?.data.amount) / 100,
                    coupon_code: couponCode
                };
                const result = await axiosInstance.post(`/verify-payment`, paymentData);
                if (result) {
                    dispatch(hideLoader());
                    await fetchAndUpdateUserDetails();
                    window.location.reload();
                } else {
                    dispatch(hideLoader());
                    // alert('Payment verification failed');
                }
            },
            prefill: {
                name: user?.name,
                email: user?.email,
                contact: user?.mobile,
            },
            theme: {
                color: '#F37254',
            },
            method: {
                upi: true,
                card: false,
                netbanking: false,
                wallet: false,
                paylater:false
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    return (
        <div className='purchase-main'>
            <div className={`purchase-container light-box-shadow col-md-4 mb-4 pt-3 pb-3`}>
                {/* <div className='w-100 h-100'> */}
                {/* <div className={`h-100`}> */}
                <div className="h-100 card-body d-flex flex-column justify-content-center align-items-center">
                    <h3>⚡</h3>
                    <h5 className="card-title text-capitalize">{purchasePlan?.title.toLowerCase()} Bolt Pass</h5>
                    <h6 className="card-price">
                        ₹ {purchasePlan?.discount_amount}/{purchasePlan?.months == 12 ? 'year' : 'month'}
                    </h6>
                    <ul className="list-unstyled mt-3 mb-4">
                        {purchasePlan?.description?.map((feature, i) => (
                            <li key={i} className="feature-item">{feature}</li>
                        ))}
                    </ul>
                    <input type="text"
                        name="couponCode"
                        id="couponCode"
                        placeholder='Coupon Code'
                        value={couponCode}
                        onChange={handleInputChange} />
                    <p className='pt-3'>Total Payment Amount :- ₹ {purchasePlan?.discount_amount}</p>
                    <button type='button' onClick={() => { handleRazorpayPayment() }} className='proceed-payment border light-box-shadow cursor-pointer'>Proceed to Payment</button>
                    {user?.plan?.title && <p className='fs-14 text-muted mt-3' style={{ width: '73%' }}>Note :- You are Subscribed to {user.plan.title}, purchasing this Bolt Pass will start from today and will replace older Bolt Pass</p>}
                </div>
                {/* </div> */}
                {/* </div> */}
                <button type="button" class="close" aria-label="Close" onClick={() => { setPurchasePopup(false) }}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    )
}

export default PlanPurchaseInfo;