// LandingSection.js
import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./styles.module.css"
import { hero, heroDark, referEarn, rightArrow } from "../../../_constants/images";
import { ThemeContext } from "../../../_common/ThemeContext";
import { boltCoinReward, isMobile } from "../../../_constants/constant";
import Header from "../../CommonComponents/Header/Header";
import Footer from "../../CommonComponents/Footer/Footer";
import LoginReferal from "../../CommonComponents/LoginReferal/LoginReferal";
import ReferralSteps from "../../CommonComponents/ReferralSteps/ReferralSteps";
import { useSelector } from "react-redux";
import LoginPopup from "../../CommonComponents/LoginPopup/LoginPopup";
import { Helmet } from "react-helmet-async";

const RefernEarn = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const isLogin = JSON.parse(useSelector((state) => state.auth.isAuthenticated));
  const [showLogin, setShowLogin] = useState(false);
  const handleOpenLogin = () => setShowLogin(true);
  const handleCloseLogin = () => setShowLogin(false);
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "ExamBolt - ",
    "description": "Earn rewards by referring friends, practicing questions and winning quizzes to ExamBolt! Join our Refer & Earn program to share test series and quizzes for government exam preparation, and unlock exciting benefits with every successful referral.",
    "keywords": ["ExamBolt refer and earn", "referral program", "earn by referring friends", "government exam referral rewards", "ExamBolt rewards", "refer and earn program", "invite friends to ExamBolt", "exam preparation referral", "earn rewards by sharing test series", "government exam referral benefits"]
  };
  return (
    <>
      <Helmet>
        <title>ExamBolt - Refer & Earn</title>
        <meta name="description" content="Earn rewards by referring friends, practicing questions and winning quizzes to ExamBolt! Join our Refer & Earn program to share test series and quizzes for government exam preparation, and unlock exciting benefits with every successful referral." />
        <meta name="keywords" content="ExamBolt refer and earn, referral program, earn by referring friends, government exam referral rewards, ExamBolt rewards, refer and earn program, invite friends to ExamBolt, exam preparation referral, earn rewards by sharing test series, government exam referral benefits" />
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      </Helmet>
      <div className={`${styles['refer']}`}>
        <LoginPopup show={showLogin} handleClose={handleCloseLogin} />
        <Header />
        <Container fluid className={`py-5 ${!isMobile && styles['hero']}`}>
          <Row className="align-items-center">
            {/* Text Section */}
            <Col xs={12} md={6} className="text-center text-md-start px-4">
              <h2 className="display-5">
                <span className="text-primary fw-bold">Invite & Earn</span>
              </h2>
              {/* <p className="lead my-3">
            Learn &nbsp; <img className={`${styles['icon']}`} src={rightArrow} alt="" /> &nbsp; Practice &nbsp;  <img className={`${styles['icon']}`} src={rightArrow} alt="" /> &nbsp; Improve &nbsp;  <img className={`${styles['icon']}`} src={rightArrow} alt="" /> &nbsp; Succeed
            </p> */}
              <p>Invite your friends & earn {boltCoinReward.referBonus} bolt coins per refer</p>
              {isLogin ? <LoginReferal /> :
                <div className="my-3">
                  <Button variant="success" className="me-2 mb-2" onClick={() => { handleOpenLogin() }}>Login / Register</Button>
                  {/* <Button variant="outline-dark" className="me-2 mb-2">
                <img src="https://img.icons8.com/color/48/000000/google-play.png" alt="Google Play" width="24" className="me-1" />
                Google Play
              </Button>
              <Button variant="outline-dark" className="mb-2">
                <img src="https://img.icons8.com/ios-filled/50/000000/mac-os--v1.png" alt="App Store" width="24" className="me-1" />
                App Store
              </Button> */}
                </div>}
            </Col>

            {/* Image Section */}
            <Col xs={12} md={6} className="d-flex justify-content-center mt-4 mt-md-0">
              <img
                src={theme == "light" ? referEarn : heroDark}
                alt="Exam Preparation"
                className={`img-fluid ${styles['hero-img']}`}
                style={{ maxHeight: "400px" }}
              />
            </Col>
          </Row>
          <ReferralSteps />
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default RefernEarn;
