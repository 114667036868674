import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.css';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../_actions/loaderActions';
import { useLocation, useParams } from 'react-router-dom';
import { DateFormatter, isMobile, TimeFormatter } from '../../../_constants/constant';
import axiosInstance from '../../../axios/axiosInstance';
import Header from '../../CommonComponents/Header/Header';
import Footer from '../../CommonComponents/Footer/Footer';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const QuizAnswerKey = () => {
    const dispatch = useDispatch();
    const [quiz, setQuiz] = useState();

    const { id } = useParams();

    useEffect(() => {
        // Fetch quiz details and calculate remaining time
        dispatch(showLoader());
        axiosInstance.get(`/user-quiz-history/${id}`)
            .then((data) => {
                const leaderboardData = data?.data?.data[0];
                setQuiz(leaderboardData);
                dispatch(hideLoader());
            })
            .catch((error) => {
                console.error("Error fetching quiz details:", error);
                dispatch(hideLoader());
            });
    }, []);

    const downloadPDF = (lang = 'en') => {
        const doc = new jsPDF(); // Enable compression
        const lineHeight = 10;
        const pageHeight = doc.internal.pageSize.height;
        let yPosition = 20;

        if (lang !== "en") {
            // Add the font from the imported file
            // doc.addFileToVFS("NotoSansDevanagari-Regular.ttf", hindiFont); // Make sure the font file name matches
            // doc.addFont("NotoSansDevanagari-Regular.ttf", "NotoSansDevanagari-Regular", "normal");
            doc.setFont('Hind-Regular', 'normal');
        } else {
            doc.setFont("helvetica", "bold");
        }
        // Add the title
        doc.setFontSize(18);
        doc.text(lang === "en" ? "Quiz Answer Key" : "परीक्षा उत्तर कुंजी", 10, yPosition);
        yPosition += 10;

        if (!quiz || !quiz.questions) {
            doc.setFontSize(12);
            doc.text('No quiz data available!', 10, yPosition);
            doc.save('QuizAnswerKey.pdf');
            return;
        }

        // Add quiz details
        doc.setFontSize(12);
        doc.text(`Quiz: ${quiz.title}`, 10, yPosition);
        yPosition += lineHeight;
        doc.text(`Date: ${DateFormatter(quiz.date)} | Time: ${TimeFormatter(quiz.time)}`, 10, yPosition);
        yPosition += lineHeight;

        // Add questions and answers
        quiz.questions.forEach((question, index) => {
            const questionText = `Q${index + 1}: ${lang == 'en' ? question.question.question : question.question.question_hindi}`;
            const options = [
                `A. ${lang == 'en' ? question.question.option_a : question.question.option_a_hindi}`,
                `B. ${lang == 'en' ? question.question.option_b : question.question.option_b_hindi}`,
                `C. ${lang == 'en' ? question.question.option_c : question.question.option_c_hindi}`,
                `D. ${lang == 'en' ? question.question.option_d : question.question.option_d_hindi}`,
            ];
            const yourAnswerText = `${lang == 'en' ? 'Your Answer' : 'आपका उत्तर'} :- ${question.selected_answer == 'E' ? `${lang == 'en' ? 'Not Attempted' : 'उत्तर नहीं दिया'}` : question.selected_answer}`;
            const correctAnswerText = `${lang == 'en' ? 'Correct Answer' : 'सही उत्तर'} :- ${question.correct_answer}`;
            // Add question text
            doc.text(questionText, 10, yPosition);
            yPosition += lineHeight;

            // Add options
            options.forEach((option) => {
                doc.text(option, 15, yPosition);
                yPosition += lineHeight;
                if (yPosition + lineHeight > pageHeight - 10) {
                    doc.addPage();
                    yPosition = 20;
                }
            });

            doc.text(yourAnswerText, 10, yPosition);
            yPosition += lineHeight;

            doc.text(correctAnswerText, 10, yPosition);
            yPosition += lineHeight;

            yPosition += 5;

            // Check for page overflow
            if (yPosition + lineHeight > pageHeight - 10) {
                doc.addPage();
                yPosition = 20;
            }
        });

        // Save the PDF
        doc.save('QuizAnswerKey.pdf');
    };


    return (<>
        <div>
            <Header />
            <div className='container pt-4'>
                {quiz &&
                    <div className={styles.head}>
                        <h2 className={styles.title}>{quiz?.title}</h2>
                        <div className="d-flex"><p className="text-muted">{DateFormatter(quiz?.date)}</p>&nbsp;•&nbsp;<p className="text-muted">{TimeFormatter(quiz?.time)}</p></div>
                        <div className="d-flex"><p className="text-muted">60 minutes</p>&nbsp;•&nbsp;<p className="text-muted">{quiz?.number_of_questions} Questions</p></div>
                        <div><button onClick={() => { downloadPDF() }}>Download</button></div>
                        <div><button onClick={() => { downloadPDF('hi') }}>Download in Hindi</button></div>
                    </div>
                }
                <div className={`${styles['answerKey']} answerKey`}>
                    {quiz?.questions?.map((question, index) => {
                        return <div className={styles.question}>
                            <p>Q{index + 1}. {question.question?.question}</p>
                            <div className={styles.options}>
                                <p>A. {question?.question?.option_a} {question?.selected_answer == "A" ? question?.is_correct == 1 ? <span>✔</span> : <span>❌</span> : question?.correct_answer == "A" && <span className={styles.correctAnswer}>✓</span>}</p>
                                <p>B. {question?.question?.option_b} {question?.selected_answer == "B" ? question?.is_correct == 1 ? <span>✔</span> : <span>❌</span> : question?.correct_answer == "B" && <span className={styles.correctAnswer}>✓</span>}</p>
                                <p>C. {question?.question?.option_c} {question?.selected_answer == "C" ? question?.is_correct == 1 ? <span>✔</span> : <span>❌</span> : question?.correct_answer == "C" && <span className={styles.correctAnswer}>✓</span>}</p>
                                <p>D. {question?.question?.option_d} {question?.selected_answer == "D" ? question?.is_correct == 1 ? <span>✔</span> : <span>❌</span> : question?.correct_answer == "D" && <span className={styles.correctAnswer}>✓</span>}</p>
                            </div>
                        </div>;
                    })}
                </div>
            </div>
            <Footer />
        </div>
    </>
    );
};

export default QuizAnswerKey;
