import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../_actions/loaderActions';
import { DateFormatter, getRandomColor, TimeFormatter } from '../../../../_constants/constant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TwoLineComponent from '../../../CommonComponents/TwoLineComponent/TwoLineComponent';
import axiosInstance from '../../../../axios/axiosInstance';

const QuizHistory = () => {
    const [quizzes, setQuizzes] = useState([]);
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    
    
    useEffect(() => {
        // Fetch plan data from the API
        const fetchPlans = async () => {
            dispatch(showLoader());
            try {
                const data = await axiosInstance.get(`/user-quiz-history`);
                setQuizzes(data?.data?.data);
                dispatch(hideLoader());
            } catch (error) {
                console.error("Failed to fetch plans:", error);
            }
        };
        fetchPlans();
    }, []);
    return (
        <div className='payment-history-section-mobile'>
            <h2>Quiz History</h2>
            {quizzes?.map((quiz, index) => {

                return (
                    <div className='d-flex light-box-shadow'>
                        <TwoLineComponent title={'Quiz'} value={quiz?.title} />
                        <TwoLineComponent title={'Date'} value={DateFormatter(quiz?.date)} truncate={false}/>
                        <TwoLineComponent title={'Time'} value={TimeFormatter(quiz?.time)} truncate={false}/>
                        <TwoLineComponent title={'Questions'} value={quiz?.number_of_questions} truncate={false}/>
                        <TwoLineComponent title={'Your Score'} value={quiz?.leaderboard?.total_score} truncate={false}/>
                        <TwoLineComponent title={'Your Rank'} value={<span
                            className={`fw-bold text-capitalize text-success`}
                        >{quiz?.leaderboard?.rank}</span>} />
                    </div>
                )
            })}
        </div>
    )
}

export default QuizHistory;