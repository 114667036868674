import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {jwtDecode} from "jwt-decode";
import axios from "axios";
import { logout } from "../_actions/loginActions";

const AuthProvider = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // Function to handle logout and redirect
        const handleLogout = () => {
            dispatch(logout()); // Clear token and user data from redux
            navigate("/accounts/login", { replace: true }); // Redirect to login page
        };

        // Decode JWT token to get expiration time
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Current time in seconds

            // If the token has already expired, log out the user immediately
            if (decodedToken.exp < currentTime) {
                handleLogout();
            } else {
                // Set a timer to log out the user when the token expires
                const timeLeft = decodedToken.exp - currentTime;
                const logoutTimer = setTimeout(() => {
                    handleLogout();
                }, timeLeft * 1000); // Convert to milliseconds

                // Clear the timer when component unmounts or token changes
                return () => clearTimeout(logoutTimer);
            }
        }
    }, [token, dispatch, navigate]);

    // Axios interceptor to catch 401 responses (unauthorized)
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout()); // Log out on 401 response
                    navigate("/accounts/login", { replace: true }); // Redirect to login page
                }
                return Promise.reject(error);
            }
        );

        // Eject the interceptor when component unmounts
        return () => axios.interceptors.response.eject(interceptor);
    }, [dispatch, navigate]);

    // Check if the user is authenticated
    if (!isAuthenticated) {
        return <Navigate to="/accounts/login" replace />;
    }

    // Render the nested routes if authenticated
    return <Outlet />;
};

export default AuthProvider;
