// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FilterPanel.css */
.scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    width: 100%;
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Webkit browsers */
  }
  
  .arrow-button {
    padding: 0 10px;
  }
  .fa-angles-right{
    width: 10px;
    height: 10px;
  }

  .all-topics{
    width: 140px;
    color: var(--dark-color) !important;
  }
  .topics-dropdown button{
    width: 100%;
  }
  .topics-dropdown div{
    width: 100%;
  }
  .topics button{
    color: var(--text-color);
  }
  .topic-button:hover{
    background-color: bisque;
    color: #000;
    transform: scale(1.01);
  }
  .topic-button-selected{
    background-color: bisque;
    color: #000;
    transform: scale(1.01);
  }`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/FilterPanel/FilterPanel.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;EACb;;EAEA;IACE,aAAa,EAAE,sCAAsC;EACvD;;EAEA;IACE,eAAe;EACjB;EACA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,mCAAmC;EACrC;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;IACxB,WAAW;IACX,sBAAsB;EACxB;EACA;IACE,wBAAwB;IACxB,WAAW;IACX,sBAAsB;EACxB","sourcesContent":["/* FilterPanel.css */\n.scroll-container {\n    display: flex;\n    overflow-x: auto;\n    white-space: nowrap;\n    scroll-behavior: smooth;\n    width: 100%;\n  }\n  \n  .scroll-container::-webkit-scrollbar {\n    display: none; /* Hide scrollbar on Webkit browsers */\n  }\n  \n  .arrow-button {\n    padding: 0 10px;\n  }\n  .fa-angles-right{\n    width: 10px;\n    height: 10px;\n  }\n\n  .all-topics{\n    width: 140px;\n    color: var(--dark-color) !important;\n  }\n  .topics-dropdown button{\n    width: 100%;\n  }\n  .topics-dropdown div{\n    width: 100%;\n  }\n  .topics button{\n    color: var(--text-color);\n  }\n  .topic-button:hover{\n    background-color: bisque;\n    color: #000;\n    transform: scale(1.01);\n  }\n  .topic-button-selected{\n    background-color: bisque;\n    color: #000;\n    transform: scale(1.01);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
