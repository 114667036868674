import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/Header/Header";
import Hero from "./Hero/Hero";
import styles from "./styles.module.css"
import Practice from "./Practice/Practice";
import Footer from "../../CommonComponents/Footer/Footer";
import StatsInfo from "../../CommonComponents/StatsInfo/StatsInfo";
import WhyExamBolt from "./WhyExamBolt/WhyExamBolt";
import ReferEarn from "./ReferEarn/ReferEarn";
import { Helmet } from "react-helmet-async";

const Home = (props) => {
    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "ExamBolt - Government Exam Preparation Partner",
        "description": "ExamBolt is your ultimate partner for government exam preparation. Access online practice questions, test series, monthly quiz, and mock tests to ace SSC, UPSC, banking, railway, and state exams. Start your journey to success with ExamBolt!",
        "keywords": ["ExamBolt","government exam preparation","competitive exams","online practice tests","exam preparation partner","mock tests","practice papers","Indian government exams","SSC","UPSC","railway exams","banking exams","state exams","free study materials","exam preparation website"]
    };
    
    return (
        <>
            <Helmet>
                <title>ExamBolt - Government Exam Preparation Partner</title>
                <meta name="description" content="ExamBolt is your ultimate partner for government exam preparation. Access online practice questions, test series, monthly quiz, and mock tests to ace SSC, UPSC, banking, railway, and state exams. Start your journey to success with ExamBolt!" />
                <meta name="keywords" content="ExamBolt, government exam preparation, competitive exams, online practice tests, exam preparation partner, mock tests, practice papers, Indian government exams, SSC, UPSC, railway exams, banking exams, state exams, free study materials, exam preparation website." />
                <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
            </Helmet>
            <div className={`${styles['home']}`}>
                <Header />
                <Hero />
                <Practice />
                <StatsInfo />
                <WhyExamBolt />
                <ReferEarn />
                <Footer />
            </div>
        </>
    );
}
export default Home;