import React from 'react';
import './userProfile.css';
import { useSelector } from 'react-redux';

const Overview = () => {
    const user = JSON.parse(useSelector((state) => state.auth.user));
    return (
        <div className="stats-container">
            <div className="stats-card border ">
                <div className="stat-item">
                    <div className="stat-icon">
                        <i className="bi bi-question-circle"></i>
                    </div>
                    <div className="stat-info">
                        <h5>{user?.attempt_questions}</h5>
                        <p>Questions Solved</p>
                    </div>
                </div>
            </div>
            <div className="stats-card border">
                <div className="stat-item">
                    <div className="stat-icon">
                        <i className="bi bi-journal-check"></i>
                    </div>
                    <div className="stat-info">
                        <h5>{user?.attempt_quiz}</h5>
                        <p>Quiz Taken</p>
                    </div>
                </div>
            </div>
            <div className="stats-card border">
                <div className="stat-item">
                    <div className="stat-icon fs-18 bolt-icon">
                        ⚡
                    </div>
                    <div className="stat-info">
                        <h5>{Math.floor(user?.bolt_coins)}</h5>
                        <p>Bolt Coins</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Overview;