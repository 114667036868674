import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './QuizCardAd.css';
import { Button } from "react-bootstrap";
import { TimeFormatter } from "../../../_constants/constant";
import axiosInstance from "../../../axios/axiosInstance";

const QuizCardAd = () => {
  const [quiz, setQuiz] = useState();
  const [loading, setLoading] = useState(true);

  // Fetch data from API
  useEffect(() => {
    axiosInstance.get(`/quizzes`) // Replace with your API URL
      .then((data) => {
        setQuiz(data?.data?.data?.data[0]);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching tasks:", error));
  }, []);

  if (loading) {
    return <div className="text-white bg-primary pb-3 pt-3 border light-box-shadow cursor-pointer"
    style={{
      width: "280px",
      height:"100%",
      borderRadius: "15px",
      background: "linear-gradient(135deg, #143c3e, #204d4f)",
      paddingLeft:"20px",
      paddingRight:"20px"
    }}></div>;
  }

  return (
    <div className="mt-3 ">
      <div
        className="text-white bg-primary pb-3 pt-3 border light-box-shadow cursor-pointer"
        style={{
          width: "280px",
          height:"100%",
          borderRadius: "15px",
          background: "linear-gradient(135deg, #143c3e, #204d4f)",
          paddingLeft:"20px",
          paddingRight:"20px"
        }}
      >
        <div className="text-center d-flex align-items-center">
          <div
            style={{
              width: "60px",
              height: "60px",
              background: "#D6F6E7",
              borderRadius: "10px",
              marginRight:"10px"
            }}
          >
            <span
              style={{
                fontSize: "40px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              ?
            </span>
          </div>
          {/* Header */}
        <h3 className="fw-bold fs-18" style={{textAlign:"left",color:"#fff"}}>{quiz.title}</h3>
        </div>

        {/* Icon (Placeholder for Cube Illustration) */}
        

        {/* Date and Time */}
        <div className="mt-3">
          <p className=" fw-bold">{new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(quiz.date))},&nbsp;{TimeFormatter(quiz?.time)}</p>
          <p className="mb-1">Entry Price: ₹{quiz.price}</p>
        </div>

        {/* Button */}
        <div className="text-center mt-3">
          <button className="btn btn-light text-primary fw-bold border" onClick={()=>{window.location.href="/quiz-listing"}}>
            Get Entry
          </button>
        </div>

        {/* Footer */}
        <p className="text-center mt-1" style={{ fontSize: "12px" }}>
          Play to win upto 400 Bolt Coins. Click above to register.
        </p>
      </div>
    </div>
  );
};

export default QuizCardAd;
