import React from 'react';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Loader.css';
import { elogo } from '../../_constants/images';
import { isMobile } from '../../_constants/constant';

const DefLoader = () => {
  const isVisible = useSelector((state) => state.loader.isVisible);

  if (!isVisible) return null;

  return (
    <div className='loader-main'>
    <div className="d-flex justify-content-center align-items-center loader-container">
      <div className="loader">
        <span className="loader-text"><img src={elogo} width={isMobile?"40px":"50px"} height={isMobile?"40px":"50px"}/></span>
        <div className="loader-circle"></div>
      </div>
    </div>
    </div>
  );
};

export default DefLoader;
