import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./styles.module.css";
import { isMobile } from "../../../_constants/constant";

const Pagination = ({ currentPage, totalPages, onPageChange, resultsPerPage, totalResults }) => {
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxButtons = isMobile ? 2 : 5; // Maximum visible page numbers
    const halfRange = Math.floor(maxButtons / 2);

    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, currentPage + halfRange);

    // Adjust start and end pages when at beginning or end of range
    if (currentPage <= halfRange) {
      endPage = Math.min(maxButtons, totalPages);
    }
    if (currentPage + halfRange >= totalPages) {
      startPage = Math.max(totalPages - maxButtons + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const handlePageChange = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center rounded-pill mt-3 px-3 py-2 shadow-sm"
      style={{
        color: "#000",
      }}
    >
      <div className="d-flex align-items-center gap-2">
        <button
          className="btn btn-link fw-bold text-decoration-none"
          style={{ color: "#D93780" }}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt; Previous
        </button>
        {generatePageNumbers().map((page) => (
          <button
            key={page}
            className={`btn rounded-circle ${page === currentPage ? "text-white" : "text-dark"
              }`}
            style={{
              backgroundColor: page === currentPage ? "#6A5ACD" : "#fff",
              color: page === currentPage ? "#fff" : "#000",
              width: "40px",
              height: "40px",
              fontWeight: "bold",
              border: "none",
            }}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
        {currentPage + 2 < totalPages && (
          <>
            <span className="">...</span>
            <button
              className="btn rounded-circle text-dark"
              style={{
                backgroundColor: "#fff",
                width: "40px",
                height: "40px",
                fontWeight: "bold",
                border: "none",
              }}
              onClick={() => handlePageChange(totalPages)}
            >
              {totalPages}
            </button>
          </>
        )}
        <button
          className={`btn btn-link text-decoration-none fw-bold ${styles['paginationNext']}`}
          style={{ color: '#D93780' }}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {isMobile ? <>&gt; Next</> : <>Next &gt;</>}
        </button>
      </div>
      {/* <div className="text-white">
        Showing {Math.min(currentPage * resultsPerPage, totalResults)} of {totalResults} results
      </div> */}
    </div>
  );
};

export default Pagination;
