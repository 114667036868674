import React from "react";
import Card from "./Card";

const WhyExamBolt = () => {
    return (
        <>
            <h1 className="text-center mb-1">Why ExamBolt ?</h1>
            <p className="text-center text-muted mb-5 fs-18">ExamBolt is your ultimate partner in achieving success in government exams.</p>
            <Card />
        </>
    )
}

export default WhyExamBolt;