import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Header from "../../CommonComponents/Header/Header";
import "./ContactUs.css";
import Footer from "../../CommonComponents/Footer/Footer";
import axiosInstance from "../../../axios/axiosInstance";
import { Helmet } from "react-helmet-async";

const ContactUs = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateName = (name) => {
    const nameRegex = /^[A-Za-z ]{2,}$/; // Regex to validate name with only alphabets, at least 3 characters
    return nameRegex.test(name);
  };

  const validateMobile = (mobile) => {
    const mobileRegex = /^\d{10}$/; // Regex to validate exactly 10 digits
    return mobileRegex.test(mobile);
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!validateName(formData.name)) {
      setErrorMessage("Name should only contain letters and atleast 3");
      setSuccessMessage("");
      setIsSubmitting(false);
      return;
    }
    if (!validateEmail(formData.email)) {
      setErrorMessage("Please enter a valid email address.");
      setSuccessMessage("");
      setIsSubmitting(false);
      return;
    }
    if (!validateMobile(formData.mobile)) {
      setErrorMessage("Mobile Number should only contain 10 digits.");
      setSuccessMessage("");
      setIsSubmitting(false);
      return;
    }
    try {
      const response = await axiosInstance.get(`/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setErrorMessage("");
        setSuccessMessage("Thank you! Your message has been sent.");
        setFormData({ name: "", mobile: "", email: "", message: "" });
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Error connecting to the server. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
const schemaMarkup = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "ExamBolt - Contact Us",
  "description": "Get in touch with ExamBolt for queries, support, or feedback. Our team is here to assist you with all your government exam preparation needs. Contact us today!```",
  "keywords": ["ExamBolt contact","ExamBolt support","government exam preparation help","contact ExamBolt","exam preparation assistance","customer support","query resolution","online exam platform support","contact form."]
};
  return(
    <>
    <Helmet>
        <title>ExamBolt - Contact Us</title>
        <meta name="description" content="Get in touch with ExamBolt for queries, support, or feedback. Our team is here to assist you with all your government exam preparation needs. Contact us today!```" />
        <meta name="keywords" content="ExamBolt contact, ExamBolt support, government exam preparation help, contact ExamBolt, exam preparation assistance, customer support, query resolution, online exam platform support, contact form." />
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      </Helmet>
    <div>
        <Header/>
        <div className="container py-5">
      <div className="row justify-content-between align-items-center">
        {/* Left Section */}
        <div className="col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
          <h2 className="fw-bold">Contact us</h2>
          <p className="text-muted">
            Need to get in touch with us? Either fill out the form with your
            inquiry or find the{" "}
            <a href="mailto:info@exambolt.in" className="text-decoration-underline">
              department email
            </a>{" "}
            you'd like to contact below.
          </p>
        </div>
        {/* Right Section (Form) */}
        <div className="col-lg-5">
          <div className="card shadow-sm border">
            <div className="card-body p-4">
            <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName" className="mb-3">
        <Form.Control
          type="text"
          className="border border-radius2"
          placeholder="Enter your Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="formEmail" className="mb-3">
        <Form.Control
          type="email"
          className="border border-radius2"
          placeholder="Enter your E-mail"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="formMobile" className="mb-3">
        <Form.Control
          type="text"
          className="border border-radius2"
          placeholder="mobile"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
          required
        />
      </Form.Group>
                <div className="mb-3">
                  <label className="form-label">What can we help you with?</label>
                  <textarea
                    className="form-control no-resize border"
                    rows="3"
                    placeholder="Type your message here"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
    </>
    )
}

export default ContactUs;