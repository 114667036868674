import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Categories from '../../../CommonComponents/Categories/Categories';
import FilterPanel from '../../../CommonComponents/FilterPanel/FilterPanel';
import QuestionTable from '../../../CommonComponents/QuestionTable/QuestionTable';
import styles from "./styles.module.css"
import { isMobile } from '../../../../_constants/constant';
import { useSelector } from 'react-redux';
import { practicing, unlimitedPractice } from '../../../../_constants/images';

const Practice = (props) => {
  const [topicSelected, setTopicSelected] = useState(0);
  const [subTopicSelected, setSubTopicSelected] = useState();
  const user = JSON.parse(useSelector((state) => state.auth.user));
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  return (
    <div className={`${styles['practice']}`}>
      <Container fluid className="my-3">
        <Row>
          <Col lg={8} xs={12} className="mb-3 border pt-3">
            <FilterPanel setTopicSelected={setTopicSelected} topicSelected={topicSelected} setSubTopicSelected={setSubTopicSelected} subTopicSelected={subTopicSelected} />
            <QuestionTable topicSelected={topicSelected} home={true} />
          </Col>
          <Col lg={4} xs={12} className={styles.practiceRight}>
            {!isMobile && user?.plan?.is_active &&
              <Categories topicSelected={topicSelected} setSubTopicSelected={setSubTopicSelected} subTopicSelected={subTopicSelected} />
            }
            {!isLogin ? <div className={styles.login} onClick={() => { window.location.href = "/accounts/login" }}>
              <h5>Don't Miss Out!!!</h5>
              <p>Login to Stay Ahead in Your Preparation</p>
            </div> :
              <div className={styles.login} onClick={() => { window.location.href = '/refer-n-earn' }}>
                <h5>Share success with your friends!</h5>
                <p>Refer them to ExamBolt and together, let's ace every government exam!</p>
              </div>
            }
            {!user?.plan?.is_active ?
              <img src={unlimitedPractice} width="80%" alt="" className='border-radius4 cursor-pointer' onClick={() => { window.location.href = '/pricing' }} />
              :
              <img src={practicing} width="80%" alt="" className='border-radius4 cursor-pointer' onClick={() => { window.location.href = '/practice' }} />
            }
            {isLogin && user?.bolt_coins > 400 ?
              <div className={styles.boltCoins} onClick={() => { window.location.href = '/store' }}><p>Spend Your Bolt Coins ⚡ Now!!!</p></div>
              :
              <div className={styles.boltCoins} onClick={() => { isLogin ? window.location.href = "/store?type=earn" : window.location.href = '/refer-n-earn' }}><p>Earn More Bolt Coins ⚡ Now!!!</p></div>
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Practice;