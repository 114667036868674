import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../_actions/loginActions";

const Logout = ({component}) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("user");
    // Optionally, redirect the user to the login page
    // window.location.href = "/accounts/login";
  };

  return <div onClick={()=>{handleLogout()}}>{component}</div>;
};

export default Logout;
