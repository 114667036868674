import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../CommonComponents/Header/Header';
import Footer from '../../CommonComponents/Footer/Footer';
import SubscriptionPlans from '../../CommonComponents/SubscriptionPlans/SubscriptionPlans';
import styles from "./styles.module.css"
import Faq from '../../CommonComponents/Faq/Faq';
import { Helmet } from 'react-helmet-async';

const Subscription = (props) => {
  
const schemaMarkup = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "ExamBolt - ",
  "description": "Discover ExamBolt's flexible and affordable pricing plans for test series and quizzes. Select the perfect plan to help you prepare for SSC, UPSC, IBPS, and other Indian government exams with personalized practice.",
  "keywords": ["ExamBolt pricing","test series pricing","affordable exam plans","government exam pricing","competitive exam subscription","SSC exam plan","UPSC pricing","IBPS pricing","online exam preparation pricing","ExamBolt subscription","best pricing for exam practice","government exam plans"]
};
  return (
    <>
    <Helmet>
        <title>ExamBolt - Pricing</title>
        <meta name="description" content="Discover ExamBolt's flexible and affordable pricing plans for test series and quizzes. Select the perfect plan to help you prepare for SSC, UPSC, IBPS, and other Indian government exams with personalized practice." />
        <meta name="keywords" content="ExamBolt pricing, test series pricing, affordable exam plans, government exam pricing, competitive exam subscription, SSC exam plan, UPSC pricing, IBPS pricing, online exam preparation pricing, ExamBolt subscription, best pricing for exam practice, government exam plans" />
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      </Helmet>
    <div>
        <Header/>
        <div className={`${styles['subscription']}`}>
            <SubscriptionPlans/>
        </div>
        <Faq/>
        <Footer/>
    </div>
    </>
  );
}

export default Subscription;