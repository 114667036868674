import React, { useContext, useEffect, useState } from 'react';
import { Badge, Dropdown, DropdownButton, ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../_actions/loaderActions';
import styles from "./styles.module.css";
import { getLightRandomColor, getRandomColor, isMobile } from '../../../_constants/constant';
import { ThemeContext } from '../../../_common/ThemeContext';
import axiosInstance from '../../../axios/axiosInstance';
import "./styles.css";


const Categories = ({ topicSelected, subTopicSelected, setSubTopicSelected }) => {
  const [categories, setCategories] = useState([]);
  const { language, toggleLanguage } = useContext(ThemeContext);
  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch topics from API using fetch
    const fetchTopics = async () => {
      dispatch(showLoader());
      try {
        const data = await axiosInstance.get(`/sub-topics?topic_names[]=${topicSelected}`); // Replace with your API endpoint
        setCategories(data?.data?.data?.data); // Assume response data is an array of topics
        dispatch(hideLoader());
      } catch (error) {
        console.error("Failed to fetch topics:", error);
      }
    };
    fetchTopics();
  }, [topicSelected]);
  return (
    <div className="mb-3">
      {!isMobile ? categories && categories.length > 0 && <>
        <h5>Categories</h5>
        <ListGroup variant="flush" className={`${styles['categories']}`}>
          {categories?.map((category, index) => (
            <ListGroup.Item key={index} className={`d-flex justify-content-between align-items-center cursor-pointer`} style={{ '--dynamic-bg': getLightRandomColor() }} onClick={() => { setSubTopicSelected(category.name) }}>
              {language === 'en' ? category.name : category.name_hindi}
            </ListGroup.Item>
          ))}
        </ListGroup></>
        :
        <DropdownButton title="Sub-Topics" variant="outline-secondary" className={`topics-dropdown categoryDropdown`}>
          {categories?.map((category, index) => (
            <Dropdown.Item key={index} className={`dropdownCategory`} onClick={() => { setSubTopicSelected(category.name) }}>{language === 'en' ? category.name : category.name_hindi}</Dropdown.Item>
          ))}
        </DropdownButton>}
    </div>
  );
}

export default Categories;
