import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.css';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../_actions/loaderActions';
import { useLocation, useParams } from 'react-router-dom';
import { DateFormatter, isMobile, TimeFormatter } from '../../../_constants/constant';
import axiosInstance from '../../../axios/axiosInstance';
import Header from '../../CommonComponents/Header/Header';
import Footer from '../../CommonComponents/Footer/Footer';

const QuizLeaderboard = () => {
    const dispatch = useDispatch();
    const [quiz, setQuiz] = useState();

    const { id } = useParams();

    useEffect(() => {
        // Fetch quiz details and calculate remaining time
        dispatch(showLoader());
        axiosInstance.get(`/quiz-leaderboard/${id}`)
            .then((data) => {
                const leaderboardData = data?.data?.data;
                setQuiz(leaderboardData);
                dispatch(hideLoader());
            })
            .catch((error) => {
                console.error("Error fetching quiz details:", error);
                dispatch(hideLoader());
            });
    }, []);
    return (<>
        <div>
            <Header />
            <div className='container pt-4'>
                {quiz &&
                    <div className={styles.head}>
                        <h2 className={styles.title}>{quiz?.title}</h2>
                        <div className="d-flex"><p className="text-muted">{DateFormatter(quiz?.date)}</p>&nbsp;•&nbsp;<p className="text-muted">{TimeFormatter(quiz?.time)}</p></div>
                        <div className="d-flex"><p className="text-muted">60 minutes</p>&nbsp;•&nbsp;<p className="text-muted">{quiz?.number_of_questions} Questions</p></div>
                        <div><button onClick={()=>{window.location.href=`/quiz-answer-key/${quiz?.id}`}}>Answer Key</button></div>
                    </div>
                }
                <div className={styles.leaderboard}>
                    <div className={styles.header}>
                        <div className={styles.rank}>Rank</div>
                        <div className={styles.name}>Name</div>
                        <div className={styles.score}>Score</div>
                        <div className={styles.reward}>Reward</div>
                    </div>
                    {quiz?.leaderboard?.map((user) => {
                        return <div className={`${styles.detail} ${user?.rank==1&&styles.rank1} ${user?.rank==2&&styles.rank2} ${user?.rank==3&&styles.rank3}`}>
                            <div className={styles.rank}>{user?.rank}</div>
                            <div className={styles.name}>{user?.user?.name.toLowerCase()}</div>
                            <div className={styles.score}>{user?.total_score}</div>
                            <div className={styles.reward}>{user?.coins} ⚡</div>
                        </div>
                    })}
                </div>
            </div>
            <Footer />
        </div>
    </>
    );
};

export default QuizLeaderboard;
