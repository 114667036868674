import React from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Breadcrumbs.css";

const Breadcrumbs = ({className}) => {
  const navigate = useNavigate();
  const pathName = window.location.pathname; // Get the current URL path
  const pathParts = pathName.split("/").filter((part) => part); // Split path into parts

  // Filter out IDs and query parameters
  const breadcrumbTrail = pathParts
    .filter((part) => isNaN(part) && !part.includes("=")) // Exclude numeric IDs or query params
    .map((part, index) => {
      const path = "/" + pathParts.slice(0, index + 1).join("/"); // Build path for each part
      const name = decodeURIComponent(part).replace(/-/g, " "); // Decode and format the name
      return { name: name.charAt(0).toUpperCase() + name.slice(1), path }; // Capitalize the name
    });

  // Add "Home" as the first breadcrumb
  breadcrumbTrail.unshift({ name: "Home", path: "/" });

  // Handle breadcrumb click
  const handleBreadcrumbClick = (path) => {
    navigate(path); // Navigate to the specified path
  };

  return (
    <nav aria-label="breadcrumb" className={`${className} mt-4`}>
      <ol className="breadcrumb d-flex align-items-center">
        {breadcrumbTrail.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            <li
              className={`breadcrumb-item ${
                index === breadcrumbTrail.length - 1 ? "active" : ""
              }`}
              aria-current={index === breadcrumbTrail.length - 1 ? "page" : undefined}
            >
              {index === breadcrumbTrail.length - 1 ? (
                breadcrumb.name
              ) : (
                <button
                  className="btn btn-link p-0 text-decoration-none"
                  onClick={() => handleBreadcrumbClick(breadcrumb.path)}
                >
                  {breadcrumb.name}
                </button>
              )}
            </li>
            {/* Add '>' as a separator, but exclude it for the last item */}
            {index < breadcrumbTrail.length - 1 && (
              <span className="mx-2 text-muted">{">"}</span>
            )}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
