// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_refer-earn-main__XcFVl{
    width: 100%;
    min-height: auto;
}
.styles_content__rgSEh{
    width: 60%;
    padding-left: 10%;
}
.styles_content__rgSEh h1{
    font-size: 50px;
    font-weight: bold;
    color: #EE7C79;
}
.styles_image__H4NzM{
    width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/Home/ReferEarn/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,UAAU;AACd","sourcesContent":[".refer-earn-main{\n    width: 100%;\n    min-height: auto;\n}\n.content{\n    width: 60%;\n    padding-left: 10%;\n}\n.content h1{\n    font-size: 50px;\n    font-weight: bold;\n    color: #EE7C79;\n}\n.image{\n    width: 40%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refer-earn-main": `styles_refer-earn-main__XcFVl`,
	"content": `styles_content__rgSEh`,
	"image": `styles_image__H4NzM`
};
export default ___CSS_LOADER_EXPORT___;
