import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from './styles.module.css';
import { boltCoinReward } from "../../../_constants/constant";

const ReferralSteps = () => {
  return (
    <Container className="my-5">
      {/* Title and Description */}
      <div className="text-center mb-5">
        <h2 className="fw-bold">Referrals</h2>
        <p className="text-muted">
          Invite your friends to ExamBolt. If they sign up and get Bolt Pass, <br /> you and your friend will get 250 Bolt Coins
        </p>
      </div>

      {/* Steps Section */}
      <Row className="text-center d-flex justify-content-between flex-wrap">
        {/* Step 1: Send Invitation */}
        <Col className={`mb-4 col-12 col-md-4 mb-4`}>
          <div className={`d-flex flex-column align-items-center border border-radius8 light-box-shadow ${styles['steps-card']}`}>
            <div
              className={`d-flex justify-content-center align-items-center rounded-circle `}
              style={{ width: "80px", height: "80px", backgroundColor: '#41A57A' }}
            >
              <i className="bi bi-chat-dots" style={{ fontSize: "30px", color: "#fff" }}></i>
            </div>
            <h5 className="mt-3 fw-bold">Send Invitation</h5>
            <p className="text-muted">
              Send your referral link to friends and tell to Practice on ExamBolt!
            </p>
          </div>
        </Col>

        {/* Step 2: Registration */}
        <Col className={`mb-4 col-12 col-md-4 mb-4`}>
          <div className={`d-flex flex-column align-items-center border border-radius8 light-box-shadow ${styles['steps-card']}`}>
            <div
              className="d-flex justify-content-center align-items-center rounded-circle"
              style={{ width: "80px", height: "80px", backgroundColor: '#EE7C79' }}
            >
              <i className="bi bi-card-list" style={{ fontSize: "30px", color: "#fff" }}></i>
            </div>
            <h5 className="mt-3 fw-bold">Registration</h5>
            <p className="text-muted">
              Let them register with your referral code/referral link.
            </p>
          </div>
        </Col>

        {/* Step 3: Drive for Free */}
        <Col className={`mb-4 col-12 col-md-4 mb-4`}>
          <div className={`d-flex flex-column align-items-center border border-radius8 light-box-shadow ${styles['steps-card']}`}>
            <div
              className="d-flex justify-content-center align-items-center rounded-circle"
              style={{ width: "80px", height: "80px", backgroundColor: '#D83881' }}
            >
              <i className="bi bi-gift" style={{ fontSize: "30px", color: "#fff" }}></i>
            </div>
            <h5 className="mt-3 fw-bold">Drive for Free!</h5>
            <p className="text-muted">
              As soon as your friend get Bolt Pass you and your friend gets {boltCoinReward.referBonus} Bolt Coins!
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferralSteps;
