import React, { useState } from "react";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import "./userProfileMobile.css";
import PaymentHistory from "./PaymentHistory";
import QuizHistory from "./QuizHistory";
import Overview from "./Overview";
import { supportIcon } from "../../../../_constants/images";

const UserProfileMobile = ({ user, pass }) => {
    const [activeMenu, setActiveMenu] = useState("Quiz History");
    // Function to handle menu click
    const handleMenuClick = (menu) => {
        setActiveMenu(menu);
    };
    // Dynamic content for the right bar based on the selected menu
    const renderContent = () => {
        switch (activeMenu) {
            case "Overview":
                return (<Overview/>);
            case "Quiz History":
                return (
                    <QuizHistory/>
                );
            case "Payment History":
                return (
                    // "Hello2"
                    <PaymentHistory/>
                );
            case "Support":
                return (
                    <div>
                        <h4>Support</h4>
                        <p>Need help? Reach out to our support team for assistance. <a href="/contact-us">Contact</a></p>
                    </div>
                );
            default:
                return <div>Select a menu option to view content.</div>;
        }
    };


    return (
        <Container fluid className="user-profile p-0">
            {/* Green Profile Header */}
            <div className="profile-header border">
                <div className="profile-info d-flex mb-4 align-items-center">
                    {/* Profile Picture with Badge */}
                    <div className="profile-picture-container">
                        {/* Profile Picture */}
                        <div
                            className={`profile-picture-ring ${pass === "Bronze" && "bronze-pass-ring"} ${pass === "Silver" && "silver-pass-ring"} ${pass === "Gold" && "gold-pass-ring"}`}
                        >
                            <div
                                className="profile-picture d-flex justify-content-center align-items-center"
                                style={{
                                    borderRadius: "50%",
                                    backgroundColor: "#6a5acd", // Purple background
                                    color: "white",
                                    cursor: "pointer",
                                }}
                            >
                                {user?.name.charAt(0)} {/* Display user's initials */}
                            </div>
                        </div>
                        {/* <div className="profile-badge">3</div> */}
                    </div>
                    <div className="">
                        <h4 className="profile-name text-capitalize">{user?.name.toLowerCase()}</h4>
                        {user?.is_verify &&
                            <div>
                                <i className="bi bi-patch-check-fill verify"></i>{" "}
                                <span className="text-muted">Verified</span>
                            </div>}
                        <p>{user?.email}</p>
                    </div>
                    {/* <p className="profile-location">📍 Pardubický Kraj</p> */}
                    {/* <Button variant="outline-light" className="follow-btn">
            + Follow
          </Button> */}
                </div>

                {/* Statistics */}
                <Row className="stats-section">
                    <Col className="text-center">
                        <h5 className="stats-value">{user?.attempt_questions}</h5>
                        <p className="stats-label">Questions Solved</p>
                    </Col>
                    <Col className="text-center">
                        <h5 className="stats-value">{user?.attempt_quiz}</h5>
                        <p className="stats-label">Quiz Taken</p>
                    </Col>
                    <Col className="text-center">
                        <h5 className="stats-value">348</h5>
                        <p className="stats-label">Bolt Coins</p>
                    </Col>
                </Row>
            </div>

            {/* Quick Action Icons */}
            <Row className="quick-actions text-center py-3">
                {[ {title:"Quiz History",icon:"bi bi-question-circle"}, {title:"Payment History",icon:"bi bi-receipt"}, {title:"Support",icon:"bi bi-call" ,image:supportIcon}].map((menu) => (
                    <Col key={menu} className={`menu-item action-icon ${activeMenu === menu.title ? "active" : ""
                        }`}
                        onClick={() => handleMenuClick(menu.title)}>
                        {menu?.image ? <img src={menu?.image} width="48px" alt="" />: <i class={`${menu.icon}`}></i>}
                        <p>{menu.title}</p>
                    </Col>
                ))}

            </Row>

            {/* Activity Cards */}
            <div className="activities">
                {/* First Activity */}
                {renderContent()}
            </div>
        </Container>
    );
};

export default UserProfileMobile;
