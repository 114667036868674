import { SHOW_LOADER, HIDE_LOADER } from '../_actions/loaderActions';

// Initial State
const initialState = {
  isVisible: false,
};

// Loader Reducer
const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, isVisible: true };
    case HIDE_LOADER:
      return { ...state, isVisible: false };
    default:
      return state;
  }
};

export default loaderReducer;
