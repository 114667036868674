// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_head__RjrZP{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.styles_head__RjrZP button{
    width: 150px;
    height: 40px;
    margin-top: 10px;
    background-color: #EA3975;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}
.styles_answerKey__oonEk{
    width: 80%;
    margin: auto;
    border: 1px solid #999999;
    border-radius: 10px;
    margin-top: 20px;
    overflow: hidden;
    padding: 20px 30px;
}
.styles_question__2\\+r\\+8{
    margin-top: 10px;
}
.styles_options__bbiCf{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.styles_options__bbiCf>p{
    width: 50%;
}
.styles_correctAnswer__81ZV6{
    font-size: 20px;
    font-weight: 700;
}

@media(max-width:991px){
    .styles_answerKey__oonEk{
        width: 95%;
        padding: 20px 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/QuizAnswerKey/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,WAAW;IACX,eAAe;AACnB;AACA;IACI,UAAU;AACd;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,UAAU;QACV,kBAAkB;IACtB;AACJ","sourcesContent":[".head{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.head button{\n    width: 150px;\n    height: 40px;\n    margin-top: 10px;\n    background-color: #EA3975;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    font-size: 16px;\n}\n.answerKey{\n    width: 80%;\n    margin: auto;\n    border: 1px solid #999999;\n    border-radius: 10px;\n    margin-top: 20px;\n    overflow: hidden;\n    padding: 20px 30px;\n}\n.question{\n    margin-top: 10px;\n}\n.options{\n    display: flex;\n    width: 100%;\n    flex-wrap: wrap;\n}\n.options>p{\n    width: 50%;\n}\n.correctAnswer{\n    font-size: 20px;\n    font-weight: 700;\n}\n\n@media(max-width:991px){\n    .answerKey{\n        width: 95%;\n        padding: 20px 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `styles_head__RjrZP`,
	"answerKey": `styles_answerKey__oonEk`,
	"question": `styles_question__2+r+8`,
	"options": `styles_options__bbiCf`,
	"correctAnswer": `styles_correctAnswer__81ZV6`
};
export default ___CSS_LOADER_EXPORT___;
