import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Cssloader from "./_common/cssloader";
import Home from "./_webapp/_Pages/Home/Home";
import { ThemeProvider } from "./_common/ThemeContext";
import { Provider } from 'react-redux';
import Login from "./_webapp/_Pages/Login/Login";
import ContactUs from "./_webapp/_Pages/ContactUs/ContactUs"
import Terms from "./_webapp/_Pages/Terms/Terms";
import Privacy from "./_webapp/_Pages/Privacy/Privacy";
import Practice from "./_webapp/_Pages/Practice/Practice";
import Subscription from "./_webapp/_Pages/Subscription/Subscription";
import { store, persistor } from "./_store/store";
import RefernEarn from "./_webapp/_Pages/RefernEarn/RefernEarn";
import Profile from "./_webapp/_Pages/Profile/Profile";
import QuestionDetail from "./_webapp/_Pages/QuestionDetail.jsx/QuestionDetail";
import DefLoader from "./_common/Loader/DefLoader";
import AuthProvider from "./_common/AuthProvider";
import { PersistGate } from "redux-persist/integration/react";
import BoltCoinStore from "./_webapp/_Pages/BoltCoinStore/BoltCoinStore";
import QuizListing from "./_webapp/_Pages/QuizListing/QuizListing";
import TestSeries from "./_webapp/_Pages/TestSeries/TestSeries";
import TestSeriesPage from "./_webapp/_Pages/TestSeriesPage/TestSeriesPage";
import Logout from "./_webapp/CommonComponents/Logout/Logout";
import QuizDetail from "./_webapp/_Pages/QuizDetail/QuizDetail";
import QuizPage from "./_webapp/_Pages/QuizPage/QuizPage";
import QuizLeaderboard from "./_webapp/_Pages/QuizLeaderboard/QuizLeaderboard";
import QuizAnswerKey from "./_webapp/_Pages/QuizAnswerKey/QuizAnswerKey";
import AlertMessage from "./_webapp/CommonComponents/AlertMessage/AlertMessage";
import { initGoogleAnalytics, trackPageView } from "./_utils/analytics";
const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return null;
};
function App() {
  useEffect(() => {
    initGoogleAnalytics();
  }, []);
  return (
    <ThemeProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <DefLoader />
            <AlertMessage />
            <Cssloader />
            <BrowserRouter>
              <Analytics />
              <Routes>
                <Route path={"/"} element={<Home />} exact />
                <Route path={"/accounts/login"} element={<Login />} exact />
                <Route path={"/contact-us"} element={<ContactUs />} exact />
                <Route path={"/quiz-listing"} element={<QuizListing />} exact />
                <Route path={"/terms-and-conditions"} element={<Terms />} exact />
                <Route path={"/privacy-policy"} element={<Privacy />} exact />
                <Route path={"/practice"} element={<Practice />} exact />
                <Route path={"/test-series"} element={<TestSeries />} exact />
                <Route path={"/pricing"} element={<Subscription />} exact />
                <Route path={"/refer-n-earn"} element={<RefernEarn />} exact />
                <Route path={"/logout"} element={<Logout />} exact />


                {/* Protected Routes */}
                <Route element={<AuthProvider />}>
                  <Route path={"/accounts/profile"} element={<Profile />} exact />
                  <Route path={"/practice/question/:id"} element={<QuestionDetail />} exact />
                  <Route path={"/store"} element={<BoltCoinStore />} exact />
                  <Route path={"/test-series/:topic/:id"} element={<TestSeriesPage />} exact />
                  <Route path={"/quiz-detail/:id"} element={<QuizDetail />} exact />
                  <Route path={"/quiz/:id"} element={<QuizPage />} exact />
                  <Route path={"/quiz-leaderboard/:id"} element={<QuizLeaderboard />} exact />
                  <Route path={"/quiz-answer-key/:id"} element={<QuizAnswerKey />} exact />
                </Route>
              </Routes>
            </BrowserRouter>
          </div>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;