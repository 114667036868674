const isMobile = window.matchMedia("only screen and (max-width: 1096px)").matches;
const boltCoinReward = { referBonus: 250, signUpBonus: 100, questionBonus: 1 };
const redeemBoltCoin = { bronzeBoltPass: 2500, silverBoltPass: 4000, goldBoltPass: 6000, quizReddem: 400 };
const quizCoinReward = { one:400, two:300, three:200, participate:50};


const getRandomColor = () => {
  const hue = Math.floor(Math.random() * 360); // Full spectrum of hues
  const saturation = Math.floor(Math.random() * 30) + 70; // High saturation (70–100%)
  const lightness = Math.floor(Math.random() * 20) + 30; // Low lightness (30–50%)
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
const getLightRandomColor = () => {
  const hue = Math.floor(Math.random() * 360); // Full spectrum of hues
  const saturation = Math.floor(Math.random() * 40) + 60; // High saturation (60–100%)
  const lightness = Math.floor(Math.random() * 20) + 65; // Lightness constrained to 65–85%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

const TimeFormatter = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  return `${formattedHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${amPm}`;
};

const DateFormatter = (date) => {

  const validDate = date instanceof Date ? date : new Date(date);
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const [year, month, day] = date.split("-").map(Number); // Assuming input format is YYYY-MM-DD
  const formattedDay = validDate.getDate().toString().padStart(2, "0");
  const formattedMonth = months[validDate.getMonth()];
  return `${formattedDay} ${formattedMonth}, ${validDate.getFullYear()}`;
};

const getMonth = (date) => {
  const validDate = date instanceof Date ? date : new Date(date);
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return months[validDate.getMonth()];
}

const getDate = (date) => {
  const validDate = date instanceof Date ? date : new Date(date);
  return validDate.getDate().toString().padStart(2, "0");
}

const addMinutes = (time, minutesToAdd) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes + minutesToAdd, seconds);

  // Format the time back to HH:mm:ss
  const newTime = date
    .toLocaleTimeString("en-GB", { hour12: false, timeZone: "Asia/Kolkata" })
    .padStart(8, "0");
  return newTime;
};

const compareWithCurrentTime = (updatedTime) => {
  const now = new Date();
  const currentTime = now
    .toLocaleTimeString("en-GB", {
      hour12: false,
      timeZone: "Asia/Kolkata",
    })
    .padStart(8, "0");

  // Convert both times to Date objects for comparison
  const [updatedHours, updatedMinutes, updatedSeconds] = updatedTime
    .split(":")
    .map(Number);
  const [currentHours, currentMinutes, currentSeconds] = currentTime
    .split(":")
    .map(Number);

  const updatedDate = new Date();
  updatedDate.setHours(updatedHours, updatedMinutes, updatedSeconds);

  const currentDate = new Date();
  currentDate.setHours(currentHours, currentMinutes, currentSeconds);

  // Comparison
  if (updatedDate > currentDate) {
    return true;
  } else if (updatedDate < currentDate) {
    return false;
  }
};

const compareDateWithCurrent = (date) => {
  const currentDate = new Date();
  const compareDate = new Date(date);

  // Resetting time for accurate date comparison
  currentDate.setHours(0, 0, 0, 0);
  compareDate.setHours(0, 0, 0, 0);

  if (compareDate > currentDate) {
    return true;
  } else if (compareDate < currentDate) {
    return false;
  } else {
    return true;
  }
};

const compareDateTimeWithCurrent = (date, time) => {
  // Merge date and time into a single Date object
  const inputDateTime = new Date(`${date}T${time}`);
  const currentDateTime = new Date(); // Get current date and time

  if (inputDateTime > currentDateTime) {
    return true;
  } else if (inputDateTime < currentDateTime) {
    return false;
  } else {
    return true;
  }
};
const compareDateTimeWithCurrentToStart = (date, time) => {
  // Merge date and time into a single Date object
  const inputDateTime = new Date(`${date}T${time}`);
  const currentDateTime = new Date(); // Get current date and time

  if (inputDateTime > currentDateTime) {
    return false;
  } else if (inputDateTime < currentDateTime) {
    return true;
  } else {
    return true;
  }
};

export { isMobile, getRandomColor, getLightRandomColor, TimeFormatter, DateFormatter, getMonth, getDate, boltCoinReward, redeemBoltCoin, addMinutes, compareWithCurrentTime, compareDateWithCurrent, compareDateTimeWithCurrent,compareDateTimeWithCurrentToStart }