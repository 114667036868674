// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb-item button {
    color: #007bff; /* Bootstrap primary color */
    background: none;
    border: none;
    padding: 0;
    font-size: inherit;
    cursor: pointer;
  }
  
  .breadcrumb-item button:hover {
    text-decoration: underline;
  }
  
  .breadcrumb-item.active {
    color: #6c757d; /* Gray for active item */
    pointer-events: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/Breadcrumbs/Breadcrumbs.css"],"names":[],"mappings":"AAAA;IACI,cAAc,EAAE,4BAA4B;IAC5C,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,cAAc,EAAE,yBAAyB;IACzC,oBAAoB;EACtB","sourcesContent":[".breadcrumb-item button {\n    color: #007bff; /* Bootstrap primary color */\n    background: none;\n    border: none;\n    padding: 0;\n    font-size: inherit;\n    cursor: pointer;\n  }\n  \n  .breadcrumb-item button:hover {\n    text-decoration: underline;\n  }\n  \n  .breadcrumb-item.active {\n    color: #6c757d; /* Gray for active item */\n    pointer-events: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
