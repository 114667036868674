import React, { useEffect, useState } from 'react';

const FullScreenRequest = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const enterFullScreen = () => {
        const elem = document.documentElement; // Full screen for the entire document

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, Opera
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
            elem.msRequestFullscreen();
        }
        setIsFullScreen(true);
    };

    const handleFullScreenChange = () => {
        // Check if full-screen mode is active or not
        if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
            setIsFullScreen(true);
        } else {
            setIsFullScreen(false);
        }
    };

    useEffect(() => {
        // Listen for full-screen change events
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('MSFullscreenChange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
        };
    }, []);

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            {!isFullScreen && (
                <button onClick={enterFullScreen()}>
                    Enter Full Screen
                </button>
            )}
            {isFullScreen && <p>You are in full-screen mode. Press Esc to exit.</p>}
        </div>
    );
};

export default FullScreenRequest;
