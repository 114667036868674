import React from "react";
import styles from './styles.module.css';
import { bronzeRedeem, goldRedeem, silverRedeem } from "../../../_constants/images";
import { boltCoinReward, redeemBoltCoin } from "../../../_constants/constant";

const EarnBoltCoin = () => {
    return (
        <div className="container mt-4 pt-5 pb-5">
            <div className="d-flex flex-wrap justify-content-between">
                {/* Card 1 */}
                    <div className={`${styles.card} border light-box-shadow`}>
                        <div className="d-flex p-2 align-items-center">
                            <div className={`${styles.boltIcon}`}><p>⚡</p><p className="fs-18">+{boltCoinReward.questionBonus}</p></div>
                            <div className={`${styles['card-body']} d-flex flex-column justify-content-between`}>
                                <div>
                                <h5 className={`${styles["card-title"]} ${styles['earn-title']}`}>Practice Question</h5>
                                <p className={`${styles["card-text"]} mt-1`}>
                                    For every question you answered correctly
                                </p>
                                </div>
                                <button className={`mt-2 ${styles['earn-btn']} border fs-18 border-radius4 cursor-pointer`} onClick={()=>{window.location.href="/practice"}}>Start Practice</button>
                            </div>
                        </div>
                    </div>

                {/* Card 2 */}
                    <div className={`${styles.card} border light-box-shadow`}>
                        <div className="d-flex p-2 align-items-center">
                            <div className={`${styles.boltIcon}`}><p>⚡</p><p className="fs-18">+{boltCoinReward.referBonus}</p></div>
                            <div  className={`${styles['card-body']} d-flex flex-column justify-content-between h-100`}>
                                <div>
                                <h5 className={`${styles["card-title"]} ${styles['earn-title']}`}>Referral bonus</h5>
                                <p className={`${styles["card-text"]} mt-1`}>
                                    For every friend you refer you both get {boltCoinReward.referBonus}⚡ coins
                                </p>
                                </div>
                                <button className={`mt-2 ${styles['earn-btn']} border fs-18 border-radius4 cursor-pointer`} onClick={()=>{window.location.href="/refer-n-earn"}}>Refer</button>
                            </div>
                        </div>
                    </div>

                {/* Card 3 */}
                    <div className={`${styles.card} border light-box-shadow`}>
                        <div className="d-flex p-2 align-items-center">
                            <div className={`${styles.boltIcon}`}><p>⚡</p><p className="fs-18">+400</p></div>
                            <div className={`${styles['card-body']} d-flex flex-column justify-content-between`}>
                                <div>
                                <h5 className={`${styles["card-title"]} ${styles['earn-title']}`}>Winning Quiz</h5>
                                <p className={`${styles["card-text"]} mt-1`}>
                                    For every quiz you finish in top 3 you can win upto 400⚡ coins
                                </p>
                                </div>
                                <button className={`mt-2 ${styles['earn-btn']} border fs-18 border-radius4 cursor-pointer`} onClick={()=>{window.location.href="/quiz-listing"}}>Participate</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default EarnBoltCoin;