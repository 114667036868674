import React, { useContext, useEffect, useState } from "react";
import Header from "../../CommonComponents/Header/Header";
import Footer from "../../CommonComponents/Footer/Footer";
import styles from "./styles.module.css"
import { hideLoader, showLoader } from "../../../_actions/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../axios/axiosInstance";
import { ThemeContext } from "../../../_common/ThemeContext";
import { Helmet } from "react-helmet-async";

const TestSeries = () => {
    const [topics, setTopics] = useState([]);
    const dispatch = useDispatch();
    const [totalTestsTaken, setTotalTestsTaken] = useState(5);
    const [testAvailable, setTestAvailable] = useState(false);
    const user = JSON.parse(useSelector((state) => state.auth.user));
    const { language, toggleLanguage } = useContext(ThemeContext);

    useEffect(() => {
        // Fetch plan data from the API
        const fetchPlans = async () => {
            dispatch(showLoader());
            try {
                const data = await axiosInstance.get(`/topics?per_page=50`); // Replace with your API endpoint
                setTopics(data?.data?.data?.data);
                dispatch(hideLoader());
            } catch (error) {
                console.error("Failed to fetch plans:", error);
            }
        };
        if (user?.plan == null || user?.plan?.is_series == 0) {
            setTestAvailable(false);
        } else {
            setTestAvailable(true);
        }
        fetchPlans();
    }, []);

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "ExamBolt - ",
        "description": "Ace Indian government exams with ExamBolt! Access expertly crafted test series, practice questions, and monthly quizzes. Strengthen your knowledge in subjects like Mathematics, English, and General Knowledge (GK), tailored to your exam plans.",
        "keywords": ["Government exam test series","Indian competitive exams preparation","Monthly quizzes for exams","Mathematics","English","GK practice","Online test series platform","SSC","UPSC","IBPS practice tests","ExamBolt mock tests","Best online government exam preparation","Practice questions for government exams","ExamBolt exam plans","Comprehensive test series for exams."]
    };

    return (
        <>
            <Helmet>
                <title>ExamBolt - Test Series</title>
                <meta name="description" content="Ace Indian government exams with ExamBolt! Access expertly crafted test series, practice questions, and monthly quizzes. Strengthen your knowledge in subjects like Mathematics, English, and General Knowledge (GK), tailored to your exam plans." />
                <meta name="keywords" content="Government exam test series, Indian competitive exams preparation, Monthly quizzes for exams, Mathematics, English, GK practice, Online test series platform, SSC, UPSC, IBPS practice tests, ExamBolt mock tests, Best online government exam preparation, Practice questions for government exams, ExamBolt exam plans, Comprehensive test series for exams." />
                <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
            </Helmet>
            <div>
                <Header />
                <div className={`${styles.header} container`}>
                    <h1 className={styles.headerTitle}>Test Series</h1>
                    <p className={styles.headerSubtitle}>Select your desired topic and start practicing now!</p>
                    {!testAvailable && <p className={`${styles['headerSubtitle']} red cursor-pointer`} onClick={()=>{window.location.href="/pricing"}}>Upgrade your Bolt Pass to access the unlimited test series!!!</p>}
                    <div className={styles.headerStats}>
                        {/* <p className={styles.stats}>Total Tests: {totalTests}</p>| */}
                        {/* <p className={styles.stats}>Total Tests Taken: {totalTestsTaken}</p> */}
                        <select name="language" id="language" value={language} onChange={toggleLanguage}>
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                        </select>
                    </div>
                </div>
                <section className={`${styles.topics} container`}>
                    {topics?.length > 0 && topics?.map(topic => (
                        <div className={`${styles.topicCard}`}>
                            {/* <img
                            className={styles.topicCardImage}
                            src="https://via.placeholder.com/300x150"
                            alt="Quantitative Aptitude"
                        /> */}
                            <div className={styles.topicCardContent}>
                                <h3 className={styles.topicCardTitle}>{language == 'en' ? topic.name : topic.name_hindi}</h3>
                                <p className={styles.topicCardDescription}>{language == 'en' ? topic.description : topic.description_hindi}</p>
                                <button className={styles.startTestBtn} disabled={!testAvailable} onClick={() => { window.location.href = `/test-series/${topic.name}/${topic.id}` }}>{language == 'en' ? 'Start Test' : 'परीक्षा शुरू करे'}</button>
                            </div>
                        </div>
                    ))}
                </section>
                <Footer />
            </div>
        </>
    )
}

export default TestSeries;