// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.styles_stats-info__Cl6a8{
    width: 100%;
    background-color: #F8EDD7;
    min-height: 300px;
    margin-bottom: 40px;
}
.styles_stats-info__Cl6a8>div{
    width: 80%;
}
.styles_info__BmeV\\+{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/StatsInfo/styles.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,UAAU;AACd;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["\n.stats-info{\n    width: 100%;\n    background-color: #F8EDD7;\n    min-height: 300px;\n    margin-bottom: 40px;\n}\n.stats-info>div{\n    width: 80%;\n}\n.info{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stats-info": `styles_stats-info__Cl6a8`,
	"info": `styles_info__BmeV+`
};
export default ___CSS_LOADER_EXPORT___;
