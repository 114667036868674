// Login Action
export const loginSuccess = (user) => ({
  type: "LOGIN_SUCCESS",
  payload: user,
});

// Logout Action
export const logout = () => ({
  type: "LOGOUT",
});

// actions/authActions.js

export const updateUserDetails = (userData) => ({
  type: 'UPDATE_USER_DETAILS',
  payload: userData,
});
