// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_categories__SAKoD{
    display: flex;
    flex-direction: unset;
    flex-wrap: wrap;
}
.styles_categories__SAKoD>div{
    width: fit-content;
    padding: 5px 10px;
    margin: 5px;
    background-color: var(--dynamic-bg) !important;
    border-radius: 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/Categories/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,8CAA8C;IAC9C,6BAA6B;AACjC","sourcesContent":[".categories{\n    display: flex;\n    flex-direction: unset;\n    flex-wrap: wrap;\n}\n.categories>div{\n    width: fit-content;\n    padding: 5px 10px;\n    margin: 5px;\n    background-color: var(--dynamic-bg) !important;\n    border-radius: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categories": `styles_categories__SAKoD`
};
export default ___CSS_LOADER_EXPORT___;
