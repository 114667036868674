// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_practice__zvvtP {
    width: 100%;
    min-height: 50%;
}

.styles_practice__zvvtP>div {
    width: 80%;
    height: 100%;
}

.styles_practiceRight__tZnjz {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.styles_login__kWNc3 {
    border: 1px solid #adadad;
    width: 85%;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 10px;
}

.styles_login__kWNc3:hover {
    transform: scale(1.02);
}

.styles_login__kWNc3 h5 {
    font-weight: 700;
    color: #EE7C76;
}

.styles_boltCoins__NEUcw {
    border: 1px solid #adadad;
    width: 85%;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #EE7C76;
    font-weight: 700;
    font-size: 18px;
}

.styles_boltCoins__NEUcw:hover {
    transform: scale(1.02);
}

@media (max-width: 991px) {

    /* Adjustments for mobile and tablet view */
    .styles_practice__zvvtP>div {
        width: 95%;
    }

    .styles_login__kWNc3 {
        width: 100%;
    }

    .styles_boltCoins__NEUcw {
        width: 100%;
    }
    .styles_practiceRight__tZnjz img{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/Home/Practice/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;;IAEI,2CAA2C;IAC3C;QACI,UAAU;IACd;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".practice {\n    width: 100%;\n    min-height: 50%;\n}\n\n.practice>div {\n    width: 80%;\n    height: 100%;\n}\n\n.practiceRight {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.login {\n    border: 1px solid #adadad;\n    width: 85%;\n    border-radius: 8px;\n    padding: 10px 20px;\n    cursor: pointer;\n    margin-bottom: 10px;\n}\n\n.login:hover {\n    transform: scale(1.02);\n}\n\n.login h5 {\n    font-weight: 700;\n    color: #EE7C76;\n}\n\n.boltCoins {\n    border: 1px solid #adadad;\n    width: 85%;\n    border-radius: 8px;\n    padding: 10px 20px;\n    cursor: pointer;\n    margin-bottom: 10px;\n    margin-top: 10px;\n    color: #EE7C76;\n    font-weight: 700;\n    font-size: 18px;\n}\n\n.boltCoins:hover {\n    transform: scale(1.02);\n}\n\n@media (max-width: 991px) {\n\n    /* Adjustments for mobile and tablet view */\n    .practice>div {\n        width: 95%;\n    }\n\n    .login {\n        width: 100%;\n    }\n\n    .boltCoins {\n        width: 100%;\n    }\n    .practiceRight img{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"practice": `styles_practice__zvvtP`,
	"practiceRight": `styles_practiceRight__tZnjz`,
	"login": `styles_login__kWNc3`,
	"boltCoins": `styles_boltCoins__NEUcw`
};
export default ___CSS_LOADER_EXPORT___;
