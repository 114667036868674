import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideAlert } from '../../../_actions/alertActions';

const AlertMessage = () => {
  // Access the Redux state
  const alert = useSelector((state) => state.alert); // Access the `alert` slice from the store
  const dispatch = useDispatch(); // Initialize dispatch for dispatching actions

  const [show, setShow] = useState(false);

  // Show the alert when there's a new message
  useEffect(() => {
    if (alert?.message) {
      setShow(true);

      // Auto-hide the alert after 5 seconds
      const timer = setTimeout(() => {
        setShow(false);
        dispatch(hideAlert()); // Clear alert from the store
      }, 2000);

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [alert, dispatch]);

  // If there's no alert to show, don't render the component
  if (!show || !alert?.message) return null;

  // Determine the alert's styling based on its type (success or error)
  const alertTypeClass = alert.type === 'error' ? 'alert-danger' : 'alert-success';

  return (
    <div className={`alert ${alertTypeClass} alert-dismissible fade show`} role="alert">
      {alert.message}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={() => setShow(false)}
      ></button>
    </div>
  );
};

export default AlertMessage;
