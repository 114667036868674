// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_start__S-COM{
    height: 40px;
    width: 150px;
    background-color: #6A5ACD;
    color: #fff;
    border: none;
}
.styles_start__S-COM:disabled{
    background-color: rgb(176, 176, 176);
    color: #494949;
}

@media(max-width:991px){
    .styles_start__S-COM{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/QuizDetail/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,YAAY;AAChB;AACA;IACI,oCAAoC;IACpC,cAAc;AAClB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".start{\n    height: 40px;\n    width: 150px;\n    background-color: #6A5ACD;\n    color: #fff;\n    border: none;\n}\n.start:disabled{\n    background-color: rgb(176, 176, 176);\n    color: #494949;\n}\n\n@media(max-width:991px){\n    .start{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"start": `styles_start__S-COM`
};
export default ___CSS_LOADER_EXPORT___;
