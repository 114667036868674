// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_elogo__w3e\\+r{
    /* margin-left: 50%;
    transform: translateX(-50%); */
    margin-bottom: 30px;
    margin-top: 10px;
}
.styles_close-btn__Zc64n{
    position: absolute;
    right: 10px;
    top: 10px;
}
.styles_popup__q80CJ{
    width: 30%;
}

@media(max-width:991px){
    .styles_popup__q80CJ{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/LoginPopup/styles.module.css"],"names":[],"mappings":"AAAA;IACI;kCAC8B;IAC9B,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb;AACA;IACI,UAAU;AACd;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".elogo{\n    /* margin-left: 50%;\n    transform: translateX(-50%); */\n    margin-bottom: 30px;\n    margin-top: 10px;\n}\n.close-btn{\n    position: absolute;\n    right: 10px;\n    top: 10px;\n}\n.popup{\n    width: 30%;\n}\n\n@media(max-width:991px){\n    .popup{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"elogo": `styles_elogo__w3e+r`,
	"close-btn": `styles_close-btn__Zc64n`,
	"popup": `styles_popup__q80CJ`
};
export default ___CSS_LOADER_EXPORT___;
