import React, { useContext, useEffect, useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../../_actions/loaderActions';
import { getRandomColor, isMobile } from '../../../_constants/constant';
import styled from "styled-components";
import { ThemeContext } from '../../../_common/ThemeContext';
import { useNavigate } from 'react-router-dom';
import QuestionComponent from '../QuestionComponent/QuestionComponent';
import Pagination from '../Pagination/Pagination';
import axiosInstance from '../../../axios/axiosInstance';

function QuestionTable({ home, topicSelected='All', subTopicSelected='' }) {
  const navigate = useNavigate();

  const handleSend = (data) => {
    navigate(`/practice/question/${data?.id}`);
  };

  const handleHomePractice = () => {
    navigate(`/practice`);
  };



  const [questions, setQuestions] = useState([]);
  const { language, toggleLanguage } = useContext(ThemeContext);
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const user = JSON.parse(useSelector((state) => state.auth.user));
  const pass = user?.plan;
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchTopics = async () => {
      dispatch(showLoader());
      try {
        let baseEndpoint = user && pass?.is_active ? `/practice-questions` : `/public-questions`;
        let endpoint = `${baseEndpoint}?per_page=${home ? 5 : 10}&page=${page}`;
  
        if (topicSelected && topicSelected !== 'All') {
          endpoint += `&topic_names[]=${topicSelected}`;
          if (user && pass && subTopicSelected) {
            endpoint += `&sub_topic_names[]=${subTopicSelected}`;
          }
        }
  
        const response = await axiosInstance.get(endpoint);
        const data = response?.data;
  
        setQuestions(data?.data?.data);
        setPaginationData({
          currentPage: data?.data?.current_page,
          totalPages: data?.data?.last_page,
          resultsPerPage: data?.data?.per_page,
          totalResults: data?.data?.total,
          pagesLink: data?.data?.links,
        });
  
        dispatch(hideLoader());
      } catch (error) {
        console.error("Failed to fetch topics:", error);
        dispatch(hideLoader());
      }
    };
  
    fetchTopics();
  }, [page, topicSelected, subTopicSelected]);
  

  const handlePageChange = (p) => {
    setPage(p);
  }


  const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 21px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: #2196f3;
  }

  input:checked + span:before {
    transform: translateX(18px);
  }
`;
  return (
    <div className='w-100 fs-18'>
      <div className={`${styles['switch']} d-flex`}>
        <Switch>
          <input
            type="checkbox"
            checked={language === 'hi'} // Set switch ON if language is 'hi'
            onChange={toggleLanguage}
          />
          <span></span>
        </Switch>
        <p>Hindi</p>
      </div>
      {!home && questions?.map((q, index) => (

        <QuestionComponent page={page} question={q} language={language} topicSelected={topicSelected} subTopicSelected={subTopicSelected}/>
        // <div className='border mb-3 p-3 w-100 border-radius8 cursor-pointer light-box-shadow' onClick={()=>{handleSend(q)}}>
        //   <div className='d-flex w-100'>
        //     <div>Q. </div>
        //     <div className={`w-100 ${styles['question']}`}>
        //       <div className={`d-flex justify-content-between ${isMobile && 'flex-column'}`}>
        //         <p style={{textAlign:'justify'}}>{language === 'en' ? q?.question : q?.question_hindi}</p>
        //         <Badge bg="warning" className={`${styles['badge']} ${isMobile && 'mt-2'}`} style={{ '--dynamic-bg': getRandomColor(), width:isMobile &&'fit-content', marginLeft:!isMobile &&'40px'}}>{language === 'en' ? q?.topic?.name : q?.topic?.name_hindi}</Badge>
        //       </div>
        //       {/* <div className={`d-flex ${styles['questions-options']}`}>
        //           <p>A. {language==='en'?q?.option_a:q?.option_a_hindi}</p>
        //           <p>B. {language==='en'?q?.option_b:q?.option_b_hindi}</p>
        //           <p>C. {language==='en'?q?.option_c:q?.option_c_hindi}</p>
        //           <p>D. {language==='en'?q?.option_d:q?.option_d_hindi}</p>
        //         </div> */}
        //     </div>
        //   </div>
        // </div>
      ))}
      {home && questions?.map((q, index) => (<div className='border mb-3 p-3 w-100 border-radius8 cursor-pointer light-box-shadow mt-2' onClick={() => { window.location.href="/practice" }}>
        <div className='d-flex w-100'>
          <div>Q. </div>
          <div className={`w-100 ${styles['question']}`}>
            <div className={`d-flex justify-content-between ${isMobile && 'flex-column'}`}>
              <p style={{ textAlign: 'justify' }}>{language === 'en' ? q?.question : q?.question_hindi}</p>
              <Badge bg="warning" className={`${styles['badge']} ${isMobile && 'mt-2'}`} style={{ '--dynamic-bg': getRandomColor(), width: isMobile && 'fit-content', marginLeft: !isMobile && '40px' }}>{language === 'en' ? q?.topic?.name : q?.topic?.name_hindi}</Badge>
            </div>
          </div>
        </div>
      </div>))}
      {!home && <Pagination
        currentPage={paginationData?.currentPage}
        totalPages={paginationData?.totalPages}
        onPageChange={handlePageChange}
        resultsPerPage={paginationData?.resultsPerPage}
        totalResults={paginationData?.totalResults}
      />}
    </div>
  );
}

export default QuestionTable;
