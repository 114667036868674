import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../../CommonComponents/Header/Header';
import Footer from '../../CommonComponents/Footer/Footer';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../_actions/loaderActions';
import QuestionComponent from '../../CommonComponents/QuestionComponent/QuestionComponent';
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';
import styles from './styles.module.css';
import QuizCardAd from '../../CommonComponents/QuizCardAd/QuizCardAd';
import axiosInstance from '../../../axios/axiosInstance';

const QuestionDetail = () => {
    const location = useLocation();
    const { id } = useParams();
    const [question, setQuestion] = useState();
    const dispatch = useDispatch();
    useEffect(() => {

        // Fetch Questions from API using fetch
        const fetchQuestion = async () => {
            dispatch(showLoader());
            try {
                const data = await axiosInstance.get(`/public-questions/details/${id}`); // Replace with your API endpoint
                setQuestion(data?.data?.data[0]); // Assume response data is an array of topics
                dispatch(hideLoader());
            } catch (error) {
                console.error("Failed to fetch topics:", error);
            }
        };
        fetchQuestion();
    }, []);





    return (
        <div>
            <Header />
            <Breadcrumbs className={`${styles['breadcrumb']}`} />
            <div className={`${styles['question-detail']} d-flex`}>
                {question && <QuestionComponent question={question} />}
                <div className={`${styles['ads']}`}>
                    <QuizCardAd/>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default QuestionDetail;