// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_breadcrumb__z3GNo{
    margin-left: 8%;
}
.styles_question-detail__B44Uy{
    width: 100%;
    padding: 0% 8%;
}
.styles_question-detail__B44Uy>div:first-child{
    width: 70% !important;
}
.styles_ads__xMQ-\\+{
    width: 30% !important;
    padding-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/QuestionDetail.jsx/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,WAAW;IACX,cAAc;AAClB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,qBAAqB;IACrB,kBAAkB;AACtB","sourcesContent":[".breadcrumb{\n    margin-left: 8%;\n}\n.question-detail{\n    width: 100%;\n    padding: 0% 8%;\n}\n.question-detail>div:first-child{\n    width: 70% !important;\n}\n.ads{\n    width: 30% !important;\n    padding-left: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `styles_breadcrumb__z3GNo`,
	"question-detail": `styles_question-detail__B44Uy`,
	"ads": `styles_ads__xMQ-+`
};
export default ___CSS_LOADER_EXPORT___;
