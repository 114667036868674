import React, { useEffect, useState } from 'react';
import "./userProfile.css";
import { Container, Row, Col, Badge } from "react-bootstrap";
import Overview from './Overview';
import PaymentHistory from './PaymentHistory';
import { useParams } from 'react-router-dom';
import QuizHistory from './QuizHistory';

const UserProfileDesktop = ({ user, pass }) => {
    const [activeMenu, setActiveMenu] = useState("Overview");
    const query = new URLSearchParams(window.location.search);
    const selected = query?.get("selected");
    useEffect(() => {
        console.log(selected);
        if (selected) {
            setActiveMenu(selected);
        }
    }, [])
    // Function to handle menu click
    const handleMenuClick = (menu) => {
        setActiveMenu(menu);
    };

    // Dynamic content for the right bar based on the selected menu
    const renderContent = () => {
        switch (activeMenu) {
            case "Overview":
                return (
                    <Overview />
                );
            case "Quiz History":
                return (
                    <QuizHistory />
                );
            case "Payment History":
                return (
                    <PaymentHistory />
                );
            case "Support":
                return (
                    <div>
                        <h4>Support</h4>
                        <p>Need help? Reach out to our support team for assistance. <a href="/contact-us">Contact</a></p>
                    </div>
                );
            default:
                return <div>Select a menu option to view content.</div>;
        }
    };


    return (
        <Container fluid className="user-profile-page">
            <Row>
                {/* Left Sidebar */}
                <Col md={4} lg={3} className="left-bar py-4 border-radius8 light-box-shadow">
                    <div className="text-center">
                        {/* Profile Picture */}
                        <div
                            className={`profile-picture-ring ${pass == "Bronze" && "bronze-pass-ring"} ${pass == "Silver" && "silver-pass-ring"} ${pass == "Gold" && "gold-pass-ring"}`}
                        >
                            <div
                                className="profile-picture d-flex justify-content-center align-items-center"
                                style={{
                                    borderRadius: "50%",
                                    backgroundColor: "#6a5acd", // Purple background
                                    color: "white",
                                    cursor: "pointer",
                                }}
                            >
                                {user?.name.charAt(0)} {/* Display user's initials */}
                            </div>
                        </div>
                        {/* User Info */}
                        <h5 className="mt-3 text-capitalize mb-0">{user?.name.toLowerCase()}</h5>
                        {user?.is_verify &&
                            <div>
                                <i className="bi bi-patch-check-fill verify"></i>{" "}
                                <span className="text-muted">Verified</span>
                            </div>}
                        {user?.plan && <div>
                            <p>{user?.plan.title} Bolt Pass</p>
                        </div>}
                        <small className="text-muted">{user?.email}</small>
                    </div>

                    {/* Statistics */}


                    {/* Menu */}
                    <div className="menu mt-4">
                        <ul className="list-unstyled">
                            {["Overview", "Quiz History", "Payment History", "Support"].map((menu) => (
                                <li
                                    key={menu}
                                    className={`menu-item ${activeMenu === menu ? "active" : ""
                                        }`}
                                    onClick={() => handleMenuClick(menu)}
                                >
                                    {menu}
                                </li>
                            ))}
                        </ul>
                    </div>
                </Col>

                {/* Right Content Area */}
                <Col md={8} lg={9} className="p-4 pt-0">
                    <div className="right-bar">{renderContent()}</div>
                </Col>
            </Row>
        </Container>
    );
}

export default UserProfileDesktop;