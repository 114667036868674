import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../_actions/loginActions";
import { hideLoader, showLoader } from "../../../_actions/loaderActions";
import axiosInstance from "../../../axios/axiosInstance";
const Login = ({ isLogin, setIsLogin, handleClose = null }) => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [verifyData, setVerifyData] = useState({
    email: "",
    otp: '',
  });
  const [validated, setValidated] = useState(true);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleVerifyChange = (e) => {
    const { name, value } = e.target;
    setVerifyData({ ...verifyData, [name]: value });
  };
  const validateOtp = (otp) => {
    const otpRegex = /^\d{6}$/; // Regex to match exactly 6 digits
    return otpRegex.test(otp);
  };

  //Validate and Submit OTP 
  const verifyOtp = async (e) => {
    e.preventDefault();
    if (!validateOtp(verifyData.otp)) {
      setErrorMessage("Please enter 6 digit OTP");
      setSuccessMessage("");
      return;
    }
    // API request
    try {
      const data = await axiosInstance.post(`/verify-otp`, {
        email: formData.email,
        otp: verifyData.otp
      });

      if (data) {
        setSuccessMessage("Success! You have verified your account");
        setErrorMessage("");
        setValidated(1);
        // Additional logic for successful signup, if needed
      } else {
        setErrorMessage("OTP verification failed. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setSuccessMessage("");
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Example: Send data to API endpoint (adjust the URL as needed)
    try {
      dispatch(showLoader());
      let data = await axiosInstance.post(`/login`, formData);
      if (data?.data?.error == false) {
        console.log(data?.data?.data?.user_data[0]?.is_verify);
        if (data?.data?.data?.user_data[0]?.is_verify) {
          setSuccessMessage("Logged In Successfully!");
          setErrorMessage("");
          data = data?.data?.data;
          // Handle successful login (e.g., redirect or store token)
          // Assuming the API response contains a token and user information
          // Save the token and user details to localStorage
          localStorage.setItem("token", data.access_token);
          localStorage.setItem("isLogin", 1);
          localStorage.setItem("user", JSON.stringify(data?.user_data[0]));
          dispatch(loginSuccess(JSON.stringify({ ...data?.user_data[0], token: data?.access_token })));
          if (handleClose === null) {
            window.location.href = '/accounts/profile';
          } else {
            window.location.reload();
            handleClose();
          }
        } else {
          let resendOtp = await axiosInstance.post(`/resend-otp`, { email: formData?.email });
          if(resendOtp){
            setValidated(false);
          }
        }
        dispatch(hideLoader());
      } else {
        setErrorMessage("Login failed. Please check your credentials.");
        setSuccessMessage("");
        dispatch(hideLoader());
      }
    } catch (error) {
    }
  };
  return (<>
    {
      !validated ?
        <Form onSubmit={verifyOtp}>
          <Form.Group controlId="formUsername" className="mb-3">
            <Form.Control
              type="text"
              className="border border-radius2"
              placeholder="Verify OTP"
              name="otp"
              value={verifyData.otp}
              onChange={handleVerifyChange}
              required
            />
          </Form.Group>
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Button variant="dark" type="submit" className="w-100 mb-3">
            Verify OTP
          </Button>
        </Form>
        :
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formEmailOrUsername" className="mb-3">
            <Form.Control
              type="text"
              className="border border-radius2"
              placeholder="Username or E-mail"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formPassword" className="mb-3">
            <Form.Control
              type="password"
              className="border border-radius2"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Form.Group>

          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Button variant="dark" type="submit" className="w-100 mb-3">
            Sign In
          </Button>

          {/* Forgot Password / Sign Up Links */}
          <div className="d-flex justify-content-between">
            <a href="#" className={`text-muted`}>Forgot Password?</a>
            <a href="#" onClick={() => setIsLogin(!isLogin)} className={`text-muted`}>Sign Up</a>
          </div>
        </Form>}</>
  );
}
export default Login;