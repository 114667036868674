// LandingSection.js
import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./styles.module.css"

const StatsInfo = () => {
    return (<div className={`d-flex justify-content-center ${styles['stats-info']}`}>
        {/* Stats Section */}
        <Row className={`d-flex justify-content-center text-center my-5`}>
          <Col xs={6} sm={4} md={3} lg={3} className={`mb-3 ${styles['info']}`}>
            <div className={`p-3 border rounded shadow-sm ${styles['info']}`}>
              <h5 className="fw-bold">Registered Students</h5>
              <p className="text-success fs-5">1000+</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3} className={`mb-3 ${styles['info']}`}>
            <div className={`p-3 border rounded shadow-sm ${styles['info']}`}>
              <h5 className="fw-bold">Questions Attempted</h5>
              <p className="text-warning fs-5">10,000+</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3} className={`mb-3 ${styles['info']}`}>
            <div className={`p-3 border rounded shadow-sm ${styles['info']}`}>
              <h5 className="fw-bold">Test Series Attempted</h5>
              <p className="text-info fs-5">100+</p>
            </div>
          </Col>
          {/* <Col xs={6} sm={4} md={3} lg={2} className={`mb-3 ${styles['info']}`}>
            <div className="p-3 border rounded shadow-sm">
              <h5 className="fw-bold">Classes Attended</h5>
              <p className="text-danger fs-5">5.5+ Crore</p>
            </div>
          </Col> */}
        </Row>
        </div>
    );
};

export default StatsInfo;
