import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TwoLineComponent = ({ title, value, truncate=true }) => {
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };
    return (
        <p className="mb-0 d-flex flex-column justify-content-center" style={{ width: 'fit-content' }}>
            <span className="fw-bold" style={{ color: '#6c757d',fontSize:'14px' }}>{title}</span>
            <span style={{ color: '#000', fontSize:'18px' }}><OverlayTrigger
                placement="right"
                overlay={<Tooltip>{value}</Tooltip>}
            >
                <span>{truncate ? truncateText(value, 12):value}</span>
            </OverlayTrigger></span>
        </p>
    );
};

export default TwoLineComponent;
