// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_cardContainer__kIc8r {
    /* margin: 50px 0; */
    width: 100%;
    padding: 0 8%;
    margin: 0;
  }
  .styles_cardContainer__kIc8r>div{
    width: 100%;
  }
  .styles_cardColumn__szf5R {
    margin-bottom: 20px;
    width: 24%;
    padding: 0 !important;
  }
  
  .styles_card__GqqBc {
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    width: 100% !important;
    background-color: #f8f9fa;
  }
  
  .styles_iconWrapper__lVyXt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 50px;
  }
  
  .styles_cardIcon__kBHyL {
    width: 80px;
    height: auto;
  }
  
  .styles_cardTitle__RGOnL {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .styles_cardDescription__euDPN {
    font-size: 0.9rem;
    color: #555;
  }
  
  .styles_yellowCard__dV9e\\+ {
    background-color: #ffeaa7;
  }
  
  .styles_blueCard__vPaN0 {
    background-color: #74b9ff;
  }
  
  .styles_pinkCard__JA2b5 {
    background-color: #fab1a0;
  }
  .styles_pinkyCard__DySsG{
    background-color: #FAD4D4;
  }

  @media (max-width: 991px) {
    .styles_cardColumn__szf5R{
        width: 340px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/Home/WhyExamBolt/styles.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,WAAW;IACX,aAAa;IACb,SAAS;EACX;EACA;IACE,WAAW;EACb;EACA;IACE,mBAAmB;IACnB,UAAU;IACV,qBAAqB;EACvB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B;;EAEA;IACE;QACI,YAAY;IAChB;EACF","sourcesContent":[".cardContainer {\n    /* margin: 50px 0; */\n    width: 100%;\n    padding: 0 8%;\n    margin: 0;\n  }\n  .cardContainer>div{\n    width: 100%;\n  }\n  .cardColumn {\n    margin-bottom: 20px;\n    width: 24%;\n    padding: 0 !important;\n  }\n  \n  .card {\n    border-radius: 20px;\n    padding: 20px;\n    text-align: center;\n    width: 100% !important;\n    background-color: #f8f9fa;\n  }\n  \n  .iconWrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 20px;\n    font-size: 50px;\n  }\n  \n  .cardIcon {\n    width: 80px;\n    height: auto;\n  }\n  \n  .cardTitle {\n    font-size: 1.5rem;\n    font-weight: bold;\n    margin-bottom: 10px;\n    color: #333;\n  }\n  \n  .cardDescription {\n    font-size: 0.9rem;\n    color: #555;\n  }\n  \n  .yellowCard {\n    background-color: #ffeaa7;\n  }\n  \n  .blueCard {\n    background-color: #74b9ff;\n  }\n  \n  .pinkCard {\n    background-color: #fab1a0;\n  }\n  .pinkyCard{\n    background-color: #FAD4D4;\n  }\n\n  @media (max-width: 991px) {\n    .cardColumn{\n        width: 340px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `styles_cardContainer__kIc8r`,
	"cardColumn": `styles_cardColumn__szf5R`,
	"card": `styles_card__GqqBc`,
	"iconWrapper": `styles_iconWrapper__lVyXt`,
	"cardIcon": `styles_cardIcon__kBHyL`,
	"cardTitle": `styles_cardTitle__RGOnL`,
	"cardDescription": `styles_cardDescription__euDPN`,
	"yellowCard": `styles_yellowCard__dV9e+`,
	"blueCard": `styles_blueCard__vPaN0`,
	"pinkCard": `styles_pinkCard__JA2b5`,
	"pinkyCard": `styles_pinkyCard__DySsG`
};
export default ___CSS_LOADER_EXPORT___;
