import React, { useState } from "react";
import styles from "./styles.module.css"
import { referEarn } from "../../../../_constants/images";
import LoginPopup from "../../../CommonComponents/LoginPopup/LoginPopup";
import { useSelector } from "react-redux";
import LoginReferal from "../../../CommonComponents/LoginReferal/LoginReferal";
import { Button } from "react-bootstrap";

const ReferEarn = () => {
    const isLogin = JSON.parse(useSelector((state) => state.auth.isAuthenticated));
    const [showLogin, setShowLogin] = useState(false);
    const handleOpenLogin = () => setShowLogin(true);
    const handleCloseLogin = () => setShowLogin(false);
    return (<>
        <LoginPopup show={showLogin} handleClose={handleCloseLogin} />
        <div className={`d-flex flex-wrap ${styles['refer-earn-main']}`}>
            <div className={`${styles['content']} d-flex flex-column justify-content-center`}>
                <h1>Refer & Earn</h1>
                <p>Refer your friends & earn 250 bolt coins per refer</p>
                <div className="my-3">
                    {!isLogin?
                    <Button variant="success" className="me-2 mb-2" onClick={() => { handleOpenLogin() }}>Login / Register</Button>:
                    <Button variant="success" className="me-2 mb-2" onClick={() => { window.location.href='/refer-n-earn' }}>Refer Now</Button>}
                </div>
            </div>
            <div className={`${styles['image']}`}>
                <img src={referEarn} alt="" width="80%" height="100%" />
            </div>
        </div>
    </>
    )
}

export default ReferEarn;