import React from "react";
import styles from "./styles.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Card = () => {
  const cardsData = [
    {
      icon: <i class="bi bi-question-circle"></i>, // Replace with the actual path or use an <img> tag if images are in assets
      title: "Comprehensive Questions",
      description:
        "Access an extensive library of questions across subjects like Mathematics, English, General Knowledge, and more, tailored for Indian government exams.",
      bgColor: styles.yellowCard,
    },
    {
      icon: <i class="bi bi-grid"></i>,
      title: "Structured Practice",
      description:
        "With test series, quizzes, and practice sets, ExamBolt ensures you stay organized and focused in your preparation.",
      bgColor: styles.blueCard,
    },
    {
      icon: "⚡",
      title: "Flexible Plans",
      description:
        "Choose from multiple subscription options like Bronze, Silver, or Gold, designed to suit your preparation needs and budget.",
      bgColor: styles.pinkCard,
    },{
        icon: <i class="bi bi-person-workspace"></i>,
        title: "User-Friendly",
        description:
          "Enjoy a seamless experience with our intuitive and mobile-friendly interface, allowing you to prepare anytime, anywhere..",
        bgColor: styles.pinkyCard,
      },
  ];

  return (
    <div className={`${styles.cardContainer} d-flex justify-content-center`}>
      <div className="d-flex justify-content-between flex-wrap">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className={`d-flex justify-content-center ${styles.cardColumn}`}
          >
            <div className={`${styles.card} ${card.bgColor} shadow border`}>
              <div className={styles.iconWrapper}>
                {card.icon}
              </div>
              <h3 className={styles.cardTitle}>{card.title}</h3>
              <p className={styles.cardDescription}>{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
