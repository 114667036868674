// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .header{
    height: 5%;
} */
.styles_header-main__wj2AI{
    border-bottom: 1px solid #b0b0b0;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
}
.styles_logout-btn__yBkca{
    margin-left: 20px;
}
.styles_header-coins__tiPry{
    padding: 0px 8px;
    border-radius: 20px;
    background-color: #A9EFCB;
    margin-right: 20px;
}
.styles_account-info__jqMte>div:first-child{
    margin-right: 10px;
}
.styles_account-info__jqMte>div>p:first-child{
    text-transform: capitalize;
}


.styles_offer__0maCz{
    width: 100%;
    padding: 3px 0px;
    background-color: #E43976;
    color: #fff;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/Header/styles.module.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;IACI,gCAAgC;IAChC,sGAAsG;AAC1G;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,0BAA0B;AAC9B;;;AAGA;IACI,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":["/* .header{\n    height: 5%;\n} */\n.header-main{\n    border-bottom: 1px solid #b0b0b0;\n    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;\n}\n.logout-btn{\n    margin-left: 20px;\n}\n.header-coins{\n    padding: 0px 8px;\n    border-radius: 20px;\n    background-color: #A9EFCB;\n    margin-right: 20px;\n}\n.account-info>div:first-child{\n    margin-right: 10px;\n}\n.account-info>div>p:first-child{\n    text-transform: capitalize;\n}\n\n\n.offer{\n    width: 100%;\n    padding: 3px 0px;\n    background-color: #E43976;\n    color: #fff;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-main": `styles_header-main__wj2AI`,
	"logout-btn": `styles_logout-btn__yBkca`,
	"header-coins": `styles_header-coins__tiPry`,
	"account-info": `styles_account-info__jqMte`,
	"offer": `styles_offer__0maCz`
};
export default ___CSS_LOADER_EXPORT___;
