import React, { useState } from 'react';
import Header from '../../CommonComponents/Header/Header';
import Footer from '../../CommonComponents/Footer/Footer';
import { Container, Row, Col, Badge } from "react-bootstrap";
import { isMobile } from '../../../_constants/constant';
import UserProfileDesktop from './UserProfileDesktop/UserProfileDesktop';
import UserProfileMobile from './UserProfileMobile/UserProfileMobile';
import { useSelector } from 'react-redux';
const subscription = ['bronze','silver','gold'];
const Profile = (props) => {
    const user =  JSON.parse(useSelector((state) => state.auth.user));
    const pass = user?.plan?.title;
    return (
        <div>
            <Header />

          {isMobile?<UserProfileMobile user={user} pass={pass}/>:<UserProfileDesktop user={user} pass={pass}/>}

            <Footer />
        </div>
    );
}

export default Profile;