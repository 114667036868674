// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_question-table__skSGH th, .styles_question-table__skSGH td, .styles_question-table__skSGH tr{
    background-color: var(--background-color);
    color: var(--color);
    border: none;
}
.styles_question__PvArY{
    margin-left: 5px;
}
.styles_questions-options__KGBgD{
    width: 100%;
    flex-wrap: wrap;
}
.styles_questions-options__KGBgD>p{
    width: 50%;
}
@media (max-width: 768px) { /* For mobile screens */
    .styles_questions-options__KGBgD>p {
      width: 100%; /* One option per line on mobile */
    }
}

.styles_badge__Ectdt{
    height: fit-content;
    background-color: var(--dynamic-bg) !important;
}
.styles_switch__i59KV{
    justify-content: end;
    align-items: center;
}
.styles_switch__i59KV>p{
    margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/QuestionTable/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,UAAU;AACd;AACA,4BAA4B,uBAAuB;IAC/C;MACE,WAAW,EAAE,kCAAkC;IACjD;AACJ;;AAEA;IACI,mBAAmB;IACnB,8CAA8C;AAClD;AACA;IACI,oBAAoB;IACpB,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".question-table th, .question-table td, .question-table tr{\n    background-color: var(--background-color);\n    color: var(--color);\n    border: none;\n}\n.question{\n    margin-left: 5px;\n}\n.questions-options{\n    width: 100%;\n    flex-wrap: wrap;\n}\n.questions-options>p{\n    width: 50%;\n}\n@media (max-width: 768px) { /* For mobile screens */\n    .questions-options>p {\n      width: 100%; /* One option per line on mobile */\n    }\n}\n\n.badge{\n    height: fit-content;\n    background-color: var(--dynamic-bg) !important;\n}\n.switch{\n    justify-content: end;\n    align-items: center;\n}\n.switch>p{\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question-table": `styles_question-table__skSGH`,
	"question": `styles_question__PvArY`,
	"questions-options": `styles_questions-options__KGBgD`,
	"badge": `styles_badge__Ectdt`,
	"switch": `styles_switch__i59KV`
};
export default ___CSS_LOADER_EXPORT___;
