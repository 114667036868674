import React, { useRef, useState, useEffect, useContext } from 'react';
import { Button, Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import './FilterPanel.css'; // Import custom CSS
import { isMobile } from '../../../_constants/constant';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from "../../../_actions/loaderActions";
import { ThemeContext } from '../../../_common/ThemeContext';
import axiosInstance from '../../../axios/axiosInstance';
import Categories from '../Categories/Categories';

const FilterPanel = ({ setTopicSelected, topicSelected, setSubTopicSelected, subTopicSelected }) => {
  const [topics, setTopics] = useState([]);
  const { language, toggleLanguage } = useContext(ThemeContext);
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const user = JSON.parse(useSelector((state) => state.auth.user));

  useEffect(() => {
    // Fetch topics from API using fetch
    const fetchTopics = async () => {
      dispatch(showLoader());
      try {
        const data = await axiosInstance.get(`/topics?per_page=24`); // Replace with your API endpoint
        setTopics(data?.data?.data?.data); // Assume response data is an array of topics
        dispatch(hideLoader());
      } catch (error) {
        console.error("Failed to fetch topics:", error);
      }
    };
    fetchTopics();
  }, []);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };
  return (
    <div className="mb-3">
      {isMobile ?
        // Mobile view: Dropdown for topics
        <>
          <DropdownButton
            title={topicSelected || "Topics"} // Show the selected topic or default "Topics"
            variant="outline-secondary"
            className="topics-dropdown"
          >
            <Dropdown.Item
              onClick={() => setTopicSelected('All')}
            >
              {'All'}
            </Dropdown.Item>
            {topics?.map((topic, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => setTopicSelected(topic.name)}
              >
                {topic.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          {user?.plan?.is_active &&
            <Categories topicSelected={topicSelected} setSubTopicSelected={setSubTopicSelected} subTopicSelected={subTopicSelected} />}
        </>
        :
        <div className="d-flex align-items-center mb-2 topics">
          <Button variant="dark" className="me-3 all-topics border" onClick={() => { setTopicSelected('All') }}>All Topics</Button>

          {/* Arrow button for scrolling left */}
          <Button variant="" onClick={scrollLeft} className="me-2 arrow-button"><p>{"<<"}</p></Button>

          {/* Scrollable topic buttons */}
          <div className="scroll-container" ref={scrollRef}>
            {topics?.map((topic, index) => (
              <Button key={index} variant="" className={`me-2 topic-button border ${topicSelected == topic.name && 'topic-button-selected'}`} onClick={() => { setTopicSelected(topic.name) }}>{language === 'en' ? topic.name : topic.name_hindi}</Button>
            ))}
          </div>

          {/* Arrow button for scrolling right */}
          <Button variant="" onClick={scrollRight} className="ms-2 arrow-button">{">>"}</Button>
        </div>}

      {/* Filters and Search */}
      {/* <InputGroup className="mb-3">
        <DropdownButton title="Lists" variant="outline-secondary">
          <Dropdown.Item>All</Dropdown.Item>
          <Dropdown.Item>Favorite</Dropdown.Item>
        </DropdownButton>
        <DropdownButton title="Difficulty" variant="outline-secondary">
          <Dropdown.Item>Easy</Dropdown.Item>
          <Dropdown.Item>Medium</Dropdown.Item>
          <Dropdown.Item>Hard</Dropdown.Item>
        </DropdownButton>
        <DropdownButton title="Status" variant="outline-secondary">
          <Dropdown.Item>All</Dropdown.Item>
          <Dropdown.Item>Solved</Dropdown.Item>
          <Dropdown.Item>Unsolved</Dropdown.Item>
        </DropdownButton>
        <Form.Control placeholder="Search questions" />
        <Button variant="success">Pick One</Button>
      </InputGroup> */}
    </div>
  );
}

export default FilterPanel;
