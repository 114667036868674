const initialState = {
    type: null, // 'error' or 'success'
    message: null,
  };
  
  const alertReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SHOW_ALERT':
        return { ...action.payload };
      case 'HIDE_ALERT':
        return initialState;
      default:
        return state;
    }
  };
  
  export default alertReducer;
  