import React, { useContext, useState, useEffect, useRef } from 'react';
import { elogo, logo } from '../../../_constants/images';
import styles from "./styles.module.css";
import styled from "styled-components";
import { Navbar, Nav, NavDropdown, Button, Container } from 'react-bootstrap';
import "./style.css";
import { ThemeContext } from "../../../_common/ThemeContext";
import { isMobile } from '../../../_constants/constant';
import Logout from '../Logout/Logout';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


const Header = () => {
  const location = useLocation();
  // const { theme, toggleTheme } = useContext(ThemeContext);
  const isLogin = JSON.parse(useSelector((state) => state.auth.isAuthenticated));
  const user = JSON.parse(useSelector((state) => state.auth.user));
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 21px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: #2196f3;
  }

  input:checked + span:before {
    transform: translateX(18px);
  }
`;
  return (
    <>
    <Navbar expand="lg" className={`shadow-sm ${styles['header-main']}`} sticky="top" >
      <Container>
        {/* Left Logo */}
        <Navbar.Brand href="/">
          <img
            src={elogo}  // Replace with your logo path
            alt="ExamBolt Logo"
          // style={{ width: '100px' }}
          />
        </Navbar.Brand>

        {/* Toggler/collapsing button for mobile */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* Navbar items inside collapse */}
        <Navbar.Collapse id="basic-navbar-nav">
          {/* Navigation Links */}
          <Nav className="ms-auto fs-18">
            <Nav.Link href="/" style={{
              padding: "8px 15px",
              transition: "color 0.3s ease",
              color: location.pathname === "/" && '#fa7268'
            }}
              onMouseOver={(e) => (e.target.style.color = "#fa7268")}
              onMouseOut={(e) => (e.target.style.color = location.pathname !== "/" && "#000")}>Home</Nav.Link>
            <Nav.Link href="/practice" style={{
              padding: "8px 15px",
              transition: "color 0.3s ease",
              color: location.pathname === "/practice" && '#fa7268'
            }}
              onMouseOver={(e) => (e.target.style.color = "#fa7268")}
              onMouseOut={(e) => (e.target.style.color = location.pathname !== "/practice" && "#000")}>Practice</Nav.Link>
            <Nav.Link href="/test-series" style={{
              padding: "8px 15px",
              transition: "color 0.3s ease",
              color: location.pathname === "/test-series" && '#fa7268'
            }}
              onMouseOver={(e) => (e.target.style.color = "#fa7268")}
              onMouseOut={(e) => (e.target.style.color = location.pathname !== "/test-series" && "#000")}>Test Series</Nav.Link>
            <Nav.Link href="/quiz-listing" style={{
              padding: "8px 15px",
              transition: "color 0.3s ease",
              color: location.pathname === "/quiz-listing" && '#fa7268'
            }}
              onMouseOver={(e) => (e.target.style.color = "#fa7268")}
              onMouseOut={(e) => (e.target.style.color = location.pathname !== "/quiz-listing" && "#000")}>Quiz</Nav.Link>
            <Nav.Link href="/pricing" style={{
              padding: "8px 15px",
              transition: "color 0.3s ease",
              color: location.pathname === "/pricing" && '#fa7268'
            }}
              onMouseOver={(e) => (e.target.style.color = "#fa7268")}
              onMouseOut={(e) => (e.target.style.color = location.pathname !== "/pricing" && "#000")}>Pricing</Nav.Link>
            <Nav.Link href="/refer-n-earn" style={{
              padding: "8px 15px",
              transition: "color 0.3s ease",
              color: location.pathname === "/refer-n-earn" && '#fa7268'
            }}
              onMouseOver={(e) => (e.target.style.color = "#fa7268")}
              onMouseOut={(e) => (e.target.style.color = location.pathname !== "/refer-n-earn" && "#000")}>Referral</Nav.Link>
            {isMobile && isLogin && <Nav.Link href="/accounts/profile" className='fs-18' style={{
              transition: "color 0.3s ease",
              color: location.pathname === "/accounts/profile" && '#fa7268',
              paddingLeft:"15px",
            }}
              onMouseOver={(e) => (e.target.style.color = "#fa7268")}
              onMouseOut={(e) => (e.target.style.color = location.pathname !== "/accounts/profile" && "#000")}>Profile</Nav.Link>}
          </Nav>

          {/* Icons and Buttons */}
          <Nav className="ms-auto d-flex align-items-center">
            {/* <i className="bi bi-search me-3" style={{ fontSize: '1.2rem' }}></i> Bootstrap Icon */}
            {!isLogin ?
              <Button variant="warning" className="mb-2 mb-lg-0" style={{ marginRight: '15px' }}><a href='/accounts/login' className='login-btn'>Login / Register</a></Button>
              :
              <div className={`d-flex align-items-center`}>
                <div className={`${styles['header-coins']} border light-box-shadow cursor-pointer`} onClick={() => { window.location.href = '/store' }}>
                  {Math.floor(user?.bolt_coins)}
                  <span> ⚡</span>
                </div>
                {isMobile ? <Logout component={<Button variant='danger' className={`mb-lg-0 ${styles['logout-btn']}`}>Logout</Button>} />
                  : <>
                    <div
                      className="profile-circle d-flex justify-content-center align-items-center"
                      onClick={toggleDropdown}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        backgroundColor: "#6a5acd", // Purple background
                        color: "white",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {user?.name.charAt(0)} {/* Display user's initials */}
                    </div> {/* Dropdown Menu */}
                    {showDropdown && (
                      <div
                        ref={dropdownRef}
                        className="dropdown-menu-custom border light-box-shadow"
                        style={{
                          position: "absolute",
                          top: "70px", // Adjust as needed to align with your header
                          right: "20px",
                          width: "300px",
                          borderRadius: "8px",
                          backgroundColor: "#fff",
                          // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          zIndex: 1000,
                        }}
                      >
                        <div className="p-3">
                          <div className="account-section mb-3">
                            <h6 className="text-muted">Account</h6>
                            <div className={`d-flex align-items-center ${styles['account-info']}`}>
                              <div
                                className="profile-circle d-flex justify-content-center align-items-center"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  backgroundColor: "#6a5acd", // Purple background
                                  color: "white",
                                  position: "relative",
                                }}>GG</div>
                              <div>
                                <p className="mb-0">{user?.name.toLowerCase()}</p>
                                <p className="text-muted mb-1 small">{user?.email}</p>
                              </div>
                            </div>
                            <div className={`mt-2`}>
                              <a href="/accounts/profile?selected=Quiz History" className="fs-18 cursor-pointer">
                                Quiz History
                              </a>
                            </div>
                            <div className={`mt-2`}>
                              <a href="/accounts/profile?selected=Payment History" className="fs-18 cursor-pointer">
                                Payment History
                              </a>
                            </div>
                            <div className={`mt-2`}>
                              <a href="/accounts/profile" className="fs-18 cursor-pointer">
                                Profile
                              </a>
                            </div>
                          </div>

                          <div className="help-section mb-2 d-flex">
                            <h6 className="text-muted">Help us improve :- </h6>
                            <a href="/contact-us" className="small">
                              Send feedback
                            </a>
                          </div>

                          <div className="logout-section">
                            <Logout component={<a className="small text-danger cursor-pointer fs-16 fw-500">Log Out</a>} />
                          </div>
                        </div>
                      </div>
                    )}</>}
              </div>
            }
            {/* <Switch>
                            <input
                                type="checkbox"
                                checked={theme === "dark"}
                                onChange={toggleTheme}
                            />
                            <span></span>
                        </Switch>
                        <span>Dark Mode</span> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
      {/* Dropdown Menu */}
    </Navbar>
    <div class="marquee-container">
    <div class="marquee-text">EARLYBIRD offer: 50% off on Bolt Pass! Limited time only!</div>
  </div>
    {/* <div className={`${styles['offer']} ${!isMobile && 'mb-2'}`}><p>EARLYBIRD offer: 50% off on Bolt Pass! Limited time only!</p></div> */}
    </>
  );
}

export default Header;