import { useDispatch } from 'react-redux';
import { updateUserDetails } from '../_actions/loginActions';
import axiosInstance from '../axios/axiosInstance';
import { store } from '../_store/store';
// Rename the function to be a custom hook (it must start with "use")
const useFetchAndUpdateUserDetails = () => {
    const dispatch = useDispatch(); // Hook to dispatch Redux actions
    
    const state = store.getState();
    const user = JSON.parse(state.auth?.user);
    const token = user?.token;

    // The actual function to fetch and update user details
    const fetchAndUpdateUserDetails = async () => {
        try {
            const data = await axiosInstance.get('/user-details');
            if (data) {
                dispatch(updateUserDetails(JSON.stringify({...data?.data?.data[0],token:token}))); // Dispatch the action to update user details in Redux store
            } else {
                console.error('Failed to fetch user details');
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    // Return the fetch function so it can be used in a component
    return fetchAndUpdateUserDetails;
};

export default useFetchAndUpdateUserDetails;
