import React, { useEffect, useState } from "react";
import { isMobile } from "../../../_constants/constant";
import TwoLineComponent from "../../CommonComponents/TwoLineComponent/TwoLineComponent";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../_actions/loaderActions";
import styles from "./styles.module.css";
import axiosInstance from "../../../axios/axiosInstance";
import Pagination from "../../CommonComponents/Pagination/Pagination";

const CoinHistory = () => {
    const [coinHistory, setCoinHistory] = useState();
    const dispatch = useDispatch();
    const [paginationData, setPaginationData] = useState();
    const [page, setPage] = useState(1);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "short",
            day: "numeric",
        });
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        });
        return { formattedDate, formattedTime };
    };
    useEffect(() => {
        // Fetch plan data from the API
        const fetchPlans = async () => {
            dispatch(showLoader());
            try {
                const data = await axiosInstance.get(`/boltcoin-history?per_page=10&page=${page}`);
                setCoinHistory(data?.data?.data?.data);
                setPaginationData({
                    currentPage: data?.data?.data?.current_page,
                    totalPages: data?.data?.data?.last_page,
                    resultsPerPage: data?.data?.data?.per_page,
                    totalResults: data?.data?.data?.total,
                    pagesLink: data?.data?.data?.links,
                });
                dispatch(hideLoader());
            } catch (error) {
                console.error("Failed to fetch plans:", error);
                dispatch(hideLoader());
            }
        };
        fetchPlans();
    }, [page]);

    const handlePageChange = (p) => {
        setPage(p);
    }
    return (
        <div className={`${styles['coin-history']}`}>
            {isMobile ? coinHistory && coinHistory?.map((coin, index) => {
                const { formattedDate, formattedTime } = formatTimestamp(coin.created_at);
                return (
                    <div className='d-flex light-box-shadow'>
                        <TwoLineComponent title={'ID'} value={coin.id} />
                        <TwoLineComponent title={'Coins'} value={coin.coins} />
                        <TwoLineComponent title={'Description'} value={coin?.description} />
                        <TwoLineComponent title={'Date'} value={formattedDate} />
                        <TwoLineComponent title={'Time'} value={formattedTime} />
                        <TwoLineComponent title={'Status'} value={<span
                            className={`fw-bold text-capitalize`}
                        >
                            {coin.type}
                        </span>} />
                    </div>
                )
            }) : <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead className="table-light">
                        <tr>
                            {/* <th>ID</th> */}
                            <th>Coins</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coinHistory && coinHistory?.map((coin, index) => {
                            const { formattedDate, formattedTime } = formatTimestamp(coin.created_at);
                            return (
                                <tr key={index}>
                                    {/* <td>{coin.id}</td> */}
                                    <td>{coin.coins}</td>
                                    <td>{coin?.description}</td>
                                    <td>{formattedDate}</td>
                                    <td>{formattedTime}</td>
                                    <td>{coin?.type}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>}
            <Pagination
                currentPage={paginationData?.currentPage}
                totalPages={paginationData?.totalPages}
                onPageChange={handlePageChange}
                resultsPerPage={paginationData?.resultsPerPage}
                totalResults={paginationData?.totalResults}
            />
        </div>
    )
}

export default CoinHistory;