import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.css';
import { ThemeContext } from '../../../_common/ThemeContext';
import QuestionComponent from '../../CommonComponents/QuestionComponent/QuestionComponent';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../_actions/loaderActions';
import { jsPDF } from 'jspdf'; // Import jsPDF
import { elogo } from '../../../_constants/images';
import TestSecurity from '../../CommonComponents/TestSecurity/TestSecurity';
import { useLocation, useParams } from 'react-router-dom';
import { isMobile } from '../../../_constants/constant';
import axiosInstance from '../../../axios/axiosInstance';
import { Alert } from 'react-bootstrap';
import FullScreenSecurity from '../../CommonComponents/TestSecurity/FullScreenSecurity';

const QuizPage = () => {
    const [questions, setQuestions] = useState([]);
    const [isTestSecurityEnabled, setIsTestSecurityEnabled] = useState(true);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [timeRemaining, setTimeRemaining] = useState(3600);
    const { language, toggleLanguage } = useContext(ThemeContext);
    const [paginationData, setPaginationData] = useState();
    const [page, setPage] = useState(1);
    const [showResultsPopup, setShowResultsPopup] = useState(false); // New state for results popup
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [inCorrectAnswers, setInCorrectAnswers] = useState(0);
    const dispatch = useDispatch();
    const [quiz, setQuiz] = useState();
    const [fullScrrenPermission, setFullScrrenPermission] = useState(true);

    const token = localStorage.getItem("token");
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        const fetchTopics = async () => {
            dispatch(showLoader());
            try {
                const data = await axiosInstance.get(`/quiz-questions?quiz_id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const questionsWithAnswered = data?.data?.data?.map((question) => ({
                    id: question.id,
                    question: language === 'hindi' ? question.question_hindi : question.question,
                    options: [
                        { label: question.option_a, value: 'A' },
                        { label: question.option_b, value: 'B' },
                        { label: question.option_c, value: 'C' },
                        { label: question.option_d, value: 'D' },
                    ],
                    question_hindi: question?.question_hindi,
                    options_hindi: [
                        { label: question.option_a_hindi, value: 'A' },
                        { label: question.option_b_hindi, value: 'B' },
                        { label: question.option_c_hindi, value: 'C' },
                        { label: question.option_d_hindi, value: 'D' },
                    ],
                    correctAnswer: question.correct_answer,
                    answered: false,
                }));

                setQuestions(questionsWithAnswered);
                setAnswers(Array(questionsWithAnswered.length).fill(null));
                setPaginationData({
                    currentPage: data?.data?.current_page,
                    totalPages: data?.data?.last_page,
                    resultsPerPage: data?.data?.per_page,
                    totalResults: data?.data?.total,
                    pagesLink: data?.data?.links,
                });
                setTimeRemaining(quiz?.duration);

                dispatch(hideLoader());
            } catch (error) {
                console.error('Failed to fetch topics:', error);
                dispatch(hideLoader());
            }
        };

        fetchTopics();
    }, [page]);

    useEffect(() => {
        // Fetch quiz details and calculate remaining time
        dispatch(showLoader());
        axiosInstance.get(`/quiz-details?quiz_id=${id}`)
            .then((data) => {
                const quizData = data?.data?.data;
                setQuiz(quizData);
                dispatch(hideLoader());

                if (quizData?.date && quizData?.time) {
                    const quizStartTime = new Date(`${quizData.date}T${quizData.time}`);
                    const quizEndTime = new Date(quizStartTime.getTime() + 60 * 60 * 1000); // 1 hour duration
                    const timeNow = new Date().toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                    });

                    const currentTime = new Date(timeNow);
                    const remainingTime = Math.max((quizEndTime - currentTime) / 1000, 0); // Remaining time in seconds

                    setTimeRemaining(Math.floor(remainingTime)); // Set the initial time remaining
                    if (remainingTime === 0) {
                        handleFinishTest(); // Automatically finish test when time runs out
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching quiz details:", error);
                dispatch(hideLoader());
            });
    }, []);

    useEffect(() => {
        // Timer logic
        const timer = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer); // Clear interval on component unmount
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const handleOptionSelect = (index) => {
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestion] = index;
        setAnswers(updatedAnswers);

        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestion].answered = true;
        setQuestions(updatedQuestions);
    };

    const handleNext = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleQuestionSelect = (index) => {
        setCurrentQuestion(index);
    };

    const handleFinishTest = () => {
        // Disable TestSecurity
        setIsTestSecurityEnabled(false);
        const formattedData = {
            quiz_id: id, // Quiz ID from the route parameters
            answers: questions.map((question, index) => ({
                question_id: question.id,
                selected_answer: answers[index] !== null ? question.options[answers[index]].value : "E", // Get selected option value or null if unanswered
            })),
        };
        dispatch(showLoader());
        axiosInstance.post('/submit-quiz', formattedData)
            .then((response) => {
                window.location.href = "/quiz-listing";
                // setShowResultsPopup(true); // Show results popup on successful submission
            })
            .catch((error) => {
                window.location.href = "/quiz-listing";
                console.error('Error submitting quiz:', error);
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    };
    const finishTest = () => {
        alert('Test finished due to too many tab switches.');
        handleFinishTest();
    };

    const answeredCount = answers.filter((answer) => answer !== null).length;
    const unansweredCount = questions.length - answeredCount;

    return (fullScrrenPermission ? <>
        <div className={styles.resultsPopup}>
            <div className={`${styles['popupContent']} d-flex justify-content-center align-items-center`}>
                <div>
                    <FullScreenSecurity setFullScrrenPermission={setFullScrrenPermission} />
                </div>
            </div>
        </div>
    </> : <>
        {!isMobile ? <div className={styles.container}>
            {isTestSecurityEnabled && <TestSecurity finishTest={finishTest} />}
            <header className={styles.header}>
                <h1 className={styles.headerTitle}><img src={elogo} width="60px" alt="" />Quiz - {quiz?.title}</h1>
                <div className={styles.headerStats}>
                    <div className={styles.timeBar}>
                        <p>Time: {formatTime(timeRemaining)} / 60:00</p>
                        <div className={styles.progressBar}>
                            <span style={{ width: `${(1 - timeRemaining / quiz?.duration) * 100}%` }}></span>
                        </div>
                    </div>
                    <p>Total Questions: {questions.length}</p>
                    <p>Answered: {answeredCount}</p>
                    <p>Unanswered: {unansweredCount}</p>
                    <button className={styles.finishButton} onClick={() => handleFinishTest()} disabled={timeRemaining === 0}>Finish Test</button>
                </div>
            </header>
            {questions?.length > 0 && (
                <main className={styles.mainContent}>
                    <section className={styles.questionSection}>
                        <select name="language" id="language" value={language} onChange={toggleLanguage}>
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                        </select>
                        <h2 className={styles.questionTitle}>Question {currentQuestion + 1}</h2>
                        <p className={styles.questionText}>{language == 'en' ? questions[currentQuestion].question : questions[currentQuestion].question_hindi}</p>
                        <div className={styles.options}>
                            {language == 'en' ? questions[currentQuestion].options.map((option, index) => (
                                <label key={index} className={styles.option}>
                                    <input
                                        type="radio"
                                        name={`question-${currentQuestion}`}
                                        checked={answers[currentQuestion] === index}
                                        onChange={() => handleOptionSelect(index)}
                                    />
                                    {option.label}
                                </label>
                            )) : questions[currentQuestion].options_hindi.map((option, index) => (
                                <label key={index} className={styles.option}>
                                    <input
                                        type="radio"
                                        name={`question-${currentQuestion}`}
                                        checked={answers[currentQuestion] === index}
                                        onChange={() => handleOptionSelect(index)}
                                    />
                                    {option.label}
                                </label>
                            ))}
                        </div>
                        <div className={styles.navigationButtons}>
                            <button
                                className={styles.navButton}
                                onClick={handlePrevious}
                                disabled={currentQuestion === 0}
                            >
                                Previous
                            </button>
                            <button
                                className={styles.navButton}
                                onClick={handleNext}
                                disabled={currentQuestion === questions.length - 1}
                            >
                                Next
                            </button>
                        </div>
                    </section>

                    <aside className={styles.navigator}>
                        <h3 className={styles.navigatorHeader}>
                            <span>☰</span> Questions Navigator
                        </h3>
                        <div className='d-flex justify-content-evenly'>
                            <div className='d-flex align-items-center'><div className={styles.reportUnanswered}></div><p>Unanswered</p></div>
                            <div className='d-flex align-items-center'><div className={styles.reportAnswered}></div><p>Answered</p></div>
                        </div>
                        <div className={styles.questionNumbers}>
                            {questions.map((_, index) => (
                                <button
                                    key={index}
                                    className={`${styles.questionButton} ${answers[index] !== null ? styles.answered : styles.unanswered
                                        } ${index === currentQuestion ? styles.active : ''}`}
                                    onClick={() => handleQuestionSelect(index)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </aside>
                </main>)}
            {showResultsPopup && (
                <div className={styles.resultsPopup}>
                    <div className={styles.popupContent}>
                        <div>
                            <h5>Quiz has been submitted successfully.</h5>
                            <p>Result will be declared on the leaderboard after some time of the quiz time.</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
            :
            <div className={styles.container}>
                {isTestSecurityEnabled && <TestSecurity finishTest={finishTest} />}
                <div className={styles.header}>
                    <h1 className={styles.headerTitle}><img src={elogo} width="40px" alt="" />Test - { }</h1>
                    <div className={styles.hamburgerContainer}>
                        <div
                            className={`${styles.hamburger} ${isOpen ? styles.open : ''}`}
                            onClick={toggleMenu}
                        >
                            <div className={styles.line}></div>
                            <div className={styles.line}></div>
                            <div className={styles.line}></div>
                        </div>

                        <div className={`${styles.menu} ${isOpen ? styles.showMenu : ''}`}>
                            <aside className={styles.navigator}>
                                <h3 className={styles.navigatorHeader}>
                                    <span>☰</span> Questions Navigator
                                </h3>
                                <div className='d-flex justify-content-evenly'>
                                    <div className='d-flex align-items-center'><div className={styles.reportUnanswered}></div><p>Unanswered</p></div>
                                    <div className='d-flex align-items-center'><div className={styles.reportAnswered}></div><p>Answered</p></div>
                                </div>
                                <div className={styles.questionNumbers}>
                                    {questions.map((_, index) => (
                                        <button
                                            key={index}
                                            className={`${styles.questionButton} ${answers[index] !== null ? styles.answered : styles.unanswered
                                                } ${index === currentQuestion ? styles.active : ''}`}
                                            onClick={() => handleQuestionSelect(index)}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
                <div className={styles.headerStats}>
                    <div className={styles.timeBar}>
                        <p>Time: {formatTime(timeRemaining)} / 60:00</p>
                        <div className={styles.progressBar}>
                            <span style={{ width: `${(1 - timeRemaining / 1800) * 100}%` }}></span>
                        </div>
                    </div>
                    <div className={`d-flex text-center justify-content-between ${styles.mobilestat}`}>
                        <p>Total Questions<br />{questions.length}</p>
                        <p>Answered<br />{answeredCount}</p>
                        <p>Unanswered<br />{unansweredCount}</p>
                    </div>
                </div>
                {questions?.length > 0 && (
                    <main className={styles.mainContent}>
                        <section className={styles.questionSection}>
                            <select name="language" id="language" value={language} onChange={toggleLanguage}>
                                <option value="en">English</option>
                                <option value="hi">Hindi</option>
                            </select>
                            <h2 className={styles.questionTitle}>Question {currentQuestion + 1}</h2>
                            <p className={styles.questionText}>{questions[currentQuestion].question}</p>
                            <div className={styles.options}>
                                {language == 'en' ? questions[currentQuestion].options.map((option, index) => (
                                    <label key={index} className={styles.option}>
                                        <input
                                            type="radio"
                                            name={`question-${currentQuestion}`}
                                            checked={answers[currentQuestion] === index}
                                            onChange={() => handleOptionSelect(index)}
                                        />
                                        {option.label}
                                    </label>
                                )) : questions[currentQuestion].options_hindi.map((option, index) => (
                                    <label key={index} className={styles.option}>
                                        <input
                                            type="radio"
                                            name={`question-${currentQuestion}`}
                                            checked={answers[currentQuestion] === index}
                                            onChange={() => handleOptionSelect(index)}
                                        />
                                        {option.label}
                                    </label>
                                ))}
                            </div>
                            <div className={styles.navigationButtons}>
                                <button
                                    className={styles.navButton}
                                    onClick={handlePrevious}
                                    disabled={currentQuestion === 0}
                                >
                                    Previous
                                </button>
                                <button
                                    className={styles.navButton}
                                    onClick={handleNext}
                                    disabled={currentQuestion === questions.length - 1}
                                >
                                    Next
                                </button>
                            </div>
                        </section>
                    </main>
                )}
                <button className={styles.finishButton} onClick={() => handleFinishTest()} disabled={timeRemaining === 0}>Finish Test</button>

                {showResultsPopup && (
                    <div className={styles.resultsPopup}>
                        <div className={`${styles['popupContent']}`}>
                            <div>
                                <h5>Quiz has been submitted successfully.</h5>
                                <p>Result will be declared on the leaderboard after some time of the quiz time.</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        }
    </>
    );
};

export default QuizPage;
