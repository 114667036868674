import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axiosInstance from "../../../axios/axiosInstance";
const Signup = ({ isLogin, setIsLogin, refCode }) => {
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
    mobile: "",
    referralCode: refCode ? refCode : '',
  });
  const [verifyData, setVerifyData] = useState({
    email: "",
    otp: '',
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(0);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9]+$/; // Only letters and numbers
    return usernameRegex.test(username);
  };
  const validateName = (name) => {
    const nameRegex = /^[A-Za-z ]{2,}$/; // Regex to validate name with only alphabets, at least 3 characters
    return nameRegex.test(name);
  };
  const validateMobile = (mobile) => {
    const mobileRegex = /^\d{10}$/; // Regex to validate exactly 10 digits
    return mobileRegex.test(mobile);
  };
  const validateOtp = (otp) => {
    const otpRegex = /^\d{6}$/; // Regex to match exactly 6 digits
    return otpRegex.test(otp);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleVerifyChange = (e) => {
    const { name, value } = e.target;
    setVerifyData({ ...verifyData, [name]: value });
  };
  //Validate and Submit OTP 
  const verifyOtp = async (e) => {
    e.preventDefault();
    if (!validateOtp(verifyData.otp)) {
      setErrorMessage("Please enter 6 digit OTP");
      setSuccessMessage("");
      return;
    }
    // API request
    try {
      const data = await axiosInstance.post(`/verify-otp`, {
        email: formData.email,
        otp: verifyData.otp
      });

      if (!data?.error) {
        setSuccessMessage("Success! You have verified your account");
        setErrorMessage("");
        setValidated(1);
        window.location.href = '/accounts/profile';
        // Additional logic for successful signup, if needed
      } else {
        setErrorMessage("OTP verification failed. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setSuccessMessage("");
    }
  }
  // Validate and Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!validateUsername(formData.username)) {
      setErrorMessage("Username should only contain letters and numbers.");
      setSuccessMessage("");
      return;
    }
    if (!validateName(formData.name)) {
      setErrorMessage("Name should only contain letters and atleast 3");
      setSuccessMessage("");
      return;
    }
    if (!validateEmail(formData.email)) {
      setErrorMessage("Please enter a valid email address.");
      setSuccessMessage("");
      return;
    }
    if (!validateMobile(formData.mobile)) {
      setErrorMessage("Mobile Number should only contain 10 digits.");
      setSuccessMessage("");
      return;
    }
    if (!validatePassword(formData.password)) {
      setErrorMessage(
        "Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character."
      );
      setSuccessMessage("");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setSuccessMessage("");
      return;
    }
    // API request
    try {
      const data = await axiosInstance.post(`/register`, {
        username: formData.username,
        name: formData.name,
        password: formData.password,
        email: formData.email,
        mobile: formData.mobile,
        referralCode: formData.referralCode
      });

      if (!data?.error) {
        setSuccessMessage("Success! You have signed up.");
        // setErrorMessage("");
        setValidated(1);
        // Additional logic for successful signup, if needed
      } else {
        // setErrorMessage("Signup failed. Please try again.");
        // setSuccessMessage("");
      }
    } catch (error) {
      // setErrorMessage("An error occurred. Please try again.");
      // setSuccessMessage("");
    }
  };

  return (<div>{
    validated ?
      <Form onSubmit={verifyOtp}>
        <Form.Group controlId="formUsername" className="mb-3">
          <Form.Control
            type="text"
            className="border border-radius2"
            placeholder="Verify OTP"
            name="otp"
            value={verifyData.otp}
            onChange={handleVerifyChange}
            required
          />
        </Form.Group>
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Button variant="dark" type="submit" className="w-100 mb-3">
          Verify OTP
        </Button>
      </Form>
      :
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mb-3">
          <Form.Control
            type="text"
            className="border border-radius2"
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formUsername" className="mb-3">
          <Form.Control
            type="text"
            className="border border-radius2"
            placeholder="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Control
            type="email"
            className="border border-radius2"
            placeholder="E-mail address"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formMobile" className="mb-3">
          <Form.Control
            type="text"
            className="border border-radius2"
            placeholder="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPassword" className="mb-3">
          <Form.Control
            type="password"
            className="border border-radius2"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formConfirmPassword" className="mb-3">
          <Form.Control
            type="password"
            className="border border-radius2"
            placeholder="Confirm password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formReferralCode" className="mb-3">
          <Form.Control
            type="text"
            className="border border-radius2"
            placeholder="Referral Code"
            name="referralCode"
            value={formData.referralCode}
            onChange={handleChange}
          />
        </Form.Group>
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Button variant="dark" type="submit" className="w-100 mb-3">
          Sign Up
        </Button>

        {/* Forgot Password / Sign Up Links */}
        <div className="d-flex justify-content-center">
          <p className="text-muted">Have an account? </p>&nbsp;&nbsp;
          <a href="#" onClick={() => setIsLogin(!isLogin)} className={`text-muted`}>Sign In</a>
        </div>
      </Form>
  }</div>
  );
}
export default Signup;